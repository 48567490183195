import React from 'react';

import * as yup from 'yup';
import { Card, Row, Col, Button, Modal } from 'antd';

import Axios from '@components/Axios';
import FormSubmit from '@components/FormSubmit';
import useLazyAsset from '@hooks/useLazyAsset';
import Translate, { i18n } from '@components/Translate';
import { getDomainData } from '@services/DiscernDomain';
import { useUserContext, useMessageContext } from '@context/index';
import background from '@assets/images/background.png';

import FormComponent from './components/Form';

import './styles.scss';

const AccessControl = ({ match }) => {
  const { logout } = useUserContext();
  const { showMessage } = useMessageContext();

  const LOGIN_BG_IMAGE = useLazyAsset({ asset: getDomainData('LOGIN_BACKGROUND') });
  const LOGIN_LOGO_IMAGE = useLazyAsset({ asset: getDomainData('LOGO_BIG') });

  const getBackgroundStyle = {
    background: `url("${LOGIN_BG_IMAGE}") 7% center no-repeat, url("${background}") 0% 0% / cover`,
  };

  const validationSchema = yup.object().shape({
    password: yup.string().min(1).required(),
    confirmPassword: yup
      .string()
      .min(1)
      .required()
      .when(['password'], (password, schema) =>
        schema.test({
          name: 'passwordDontMatch',
          message: i18n.t('accessControl.form.validation.passwordDontMatch'),
          test: confirmPassword => confirmPassword === password,
        }),
      ),
  });

  const formikProps = {
    validationSchema,
    initialValues: {},
  };

  const onSuccess = () => {
    Modal.success({
      title: i18n.t('accessControl.form.successfulMessage.title'),
      onOk: () => {
        logout();
      },
    });
  };

  const submitForm = async item => {
    const {
      submit,
      setErrors,
      values: { password },
    } = item;
    try {
      const body = {
        password,
        token: match.params.token,
      };
      await submit({ body });
    } catch (error) {
      if (error?.unprocessableEntity) {
        setErrors(error.unprocessableEntity);
      } else if (error?.response?.data) {
        showMessage({
          type: 'error',
          message: error.response.data,
        });
      }
    }
  };

  const actions = ({ handleSubmit }) => {
    return (
      <>
        <Button className="ant-btn ant-btn-primary ant-btn-block" onClick={handleSubmit}>
          <Translate>accessControl.form.button.confirm</Translate>
        </Button>
      </>
    );
  };

  const getComponentForm = ({ submit }) => {
    return (
      <FormSubmit
        {...formikProps}
        onSubmit={(values, event) => submitForm({ ...event, values, submit })}
      >
        {({ handleSubmit, ...formikValues }) => (
          <>
            <FormComponent {...formikValues} />
            {actions({ handleSubmit })}
          </>
        )}
      </FormSubmit>
    );
  };

  return (
    <div className="login-container" style={getBackgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100 align-items-right">
        <Row align={'stretch'} justify={'end'}>
          <Col pull={4}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img width="250" className="img-fluid" src={LOGIN_LOGO_IMAGE} alt="" />
                </div>
                <hr />
                <Axios api="portalAccessControl" method="post" onSuccess={onSuccess}>
                  {getComponentForm}
                </Axios>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccessControl;
