import React, { useCallback, useMemo, useState } from 'react';

import { Card, Button, Tooltip } from 'antd';
import get from 'lodash/get';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { isAdmin, isTSR } from '@util/user.utils';
import Tag from '@components/Tag';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { formatCpf } from '@util/global';
import CustomSelect from '@containers/Manager/components/CustomSelect';
import ResetPassword from '@components/ResetPassword';

import './styles.scss';

const ListManager = ({ user, history }) => {
  const [selectedManagers, setSelectedManagers] = useState([]);

  const handleOnChangeManagers = useCallback(({ target: { value } }) => {
    setSelectedManagers(value);
  });
  const tableColumns = [
    {
      title: i18n.t('manager.list.table.header.status'),
      dataIndex: 'active',
      render: (_, { active }) => {
        const type = active ? 'checkedChildren' : 'unCheckedChildren';
        return (
          <Tag color={active ? 'success' : 'error'}>
            {`manager.list.table.header.status.${type}`}
          </Tag>
        );
      },
    },
    {
      title: i18n.t('manager.list.table.header.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { id, name }) => {
        return (
          <Tooltip title={i18n.t('manager.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/manager/${id}`)}>
              {name}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t('manager.list.table.header.documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { documentNumber }) => {
        return formatCpf(documentNumber);
      },
    },
    {
      title: i18n.t('manager.list.table.header.email'),
      dataIndex: 'email',
    },
    {
      title: i18n.t('manager.list.table.header.phone'),
      dataIndex: 'phone',
    },
    {
      title: i18n.t('manager.list.table.header.dealer'),
      render: (_, { dealer }) => {
        return get(dealer, 'name');
      },
    },
    {
      title: i18n.t('tableActions'),
      dataIndex: 'actions',
      align: 'right',
      render: (_, { id, name, email, portalAccessControl, userId }) => (
        <>
          <Tooltip title={i18n.t('manager.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/manager/${id}`)}>
              <EditOutlined />
            </Button>
          </Tooltip>

          <ResetPassword
            portalAccessControl={portalAccessControl}
            userId={userId}
            userName={name}
            userEmail={email}
            loggedUser={user}
          />
        </>
      ),
    },
  ];

  const axios = useMemo(
    () => ({
      run: selectedManagers.length + 1,
      api: 'managers',
      params: {
        include: 'dealer,portalAccessControl',
        ...(selectedManagers.length > 0 && {
          parentId: selectedManagers.map(item => item).join(','),
        }),
      },
    }),
    [selectedManagers.length],
  );

  const filters = useCallback(
    () => (
      <div className="list-manager-filters">
        {(isAdmin(user) || isTSR(user)) && (
          <CustomSelect value={selectedManagers} onChange={handleOnChangeManagers} />
        )}
      </div>
    ),
    [selectedManagers.length],
  );

  const actionProps = [
    {
      icon: <PlusOutlined />,
      title: 'manager.list.card.new',
      onClick: () => history.push('/manager/create'),
    },
    {
      icon: <PlusOutlined />,
      title: 'manager.list.card.batch',
      onClick: () => history.push('/manager/batch'),
    },
  ];

  return (
    <div className="ListManager">
      <Card className="ant-card gx-card ant-card-bordered">
        <TableSearch
          axios={axios}
          columns={tableColumns}
          actionProps={actionProps}
          component={filters}
          title="manager.list.table.title"
        />
      </Card>
    </div>
  );
};

export default ListManager;
