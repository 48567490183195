import React, { useContext, useEffect, useState } from 'react';

import { getHostId } from '@services/DiscernDomain';

const UserContext = React.createContext();

const USER_STORAGE_KEY = 'pcam-user';
const TOKEN_STORAGE_KEY = 'pcam-token';

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    async function retrieveUserFromStorage() {
      const user = localStorage.getItem(USER_STORAGE_KEY);
      const token = localStorage.getItem(TOKEN_STORAGE_KEY);
      setUser(JSON.parse(user));
      setToken(token);
    }
    retrieveUserFromStorage();
  }, []);

  useEffect(() => {
    async function updateUserStorage() {
      if (user) {
        localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(user));
      } else {
        localStorage.removeItem(USER_STORAGE_KEY);
      }
    }
    updateUserStorage();
  }, [user]);

  useEffect(() => {
    async function updateTokenStorage() {
      if (token) {
        localStorage.setItem(TOKEN_STORAGE_KEY, token);
      } else {
        localStorage.removeItem(TOKEN_STORAGE_KEY);
      }
    }
    updateTokenStorage();
  }, [token]);

  const login = user => {
    setUser(user);
    setToken(user.token);
  };

  const logout = () => {
    setUser(null);
    setToken(null);
    window.location.href = `/login?domain=${getHostId()}`;
  };

  const updateName = data => {
    const userUpdated = { ...user, name: data.name || data.memberName };
    localStorage.setItem(USER_STORAGE_KEY, JSON.stringify(userUpdated));
    setUser(userUpdated);
  };

  return (
    <UserContext.Provider value={{ user, setUser, token, login, logout, updateName }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
