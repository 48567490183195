import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { useUserContext } from '@context/UserContext';

const GuardedRoute = ({ component: Component, lgpd, permission, customValidate, ...rest }) => {
  const { user } = useUserContext();
  const verifyRule = props => {
    if (customValidate) {
      return permission.includes(user?.profile) && customValidate({ user, ...props });
    } else {
      return permission.includes(user?.profile);
    }
  };

  const render = props => {
    return lgpd && verifyRule(props) ? (
      <Component user={user} {...props} />
    ) : (
      <Redirect to="/lgpd" />
    );
  };

  return <Route {...rest} render={props => render(props)} />;
};

export default GuardedRoute;
