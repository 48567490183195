import React, { useContext } from 'react';
import { message } from 'antd';

const MessageContext = React.createContext();

export const MessageProvider = ({ children }) => {
  const [messageApi, contextHolder] = message.useMessage();

  const showMessage = ({ message, onClose, type = 'info', duration = 1 }) => {
    messageApi.open({
      type,
      duration,
      onClose,
      content: <MessageContext.Consumer>{() => message}</MessageContext.Consumer>,
    });
  };

  return (
    <MessageContext.Provider value={{ showMessage }}>
      {contextHolder}
      {children}
    </MessageContext.Provider>
  );
};

export const useMessageContext = () => useContext(MessageContext);
