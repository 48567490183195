import React, { useState, useEffect } from 'react';

import { Row, Col, Card, Typography, Button } from 'antd';

import { Form } from '@ant-design/compatible';

import Translate, { i18n } from '@components/Translate';
import Input from '@components/Input';
import Axios from '@components/Axios';

import AuditLookup from './AuditLookup';
import { auditOperations, auditModels } from './auditEnums';
import auditLookups from './auditLookups';

const AuditFilter = ({ params, setParams, onSearch }) => {
  const [operation, setOperation] = useState();
  const [model, setModel] = useState();
  const [identifier, setIdentifier] = useState();
  const [user, setUser] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [lookupValues, setLookupValues] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setParams({ ...params, operation, model, identifier, user, startDate, endDate });
  }, [operation, model, identifier, user, startDate, endDate]);

  const setValue = ({ target: { value } }, setter) => setter(value);

  const onModelChange = ({ target: { value } }) => {
    setIdentifier(null);
    setLookupValues({});
    setModel(value);
  };

  const search = async () => {
    if (await validateLookup()) {
      onSearch();
    }
  };

  const validateLookup = async () => {
    try {
      const validationSchema = auditLookups[model]?.validationSchema;
      await validationSchema?.validate(lookupValues, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      const errors =
        error.inner?.reduce((acc, { message, params }) => {
          acc[params.path] = i18n.t(message, params);
          return acc;
        }, {}) ?? {};
      setErrors(errors);
      return false;
    }
  };

  const clearFilters = () => {
    setOperation();
    setModel();
    setIdentifier();
    setUser();
    setStartDate();
    setEndDate();
    setLookupValues({});
    setErrors({});
  };

  return (
    <Card>
      <Typography.Title level={3}>
        <Translate>audit.list.filters</Translate>
      </Typography.Title>
      <Form layout="vertical">
        <Row gutter={20} type="flex">
          <Col md={6}>
            <Input
              translateOptions
              type="select"
              label="audit.list.filter.operation"
              value={operation}
              array={auditOperations}
              option={{ key: 'id', name: 'value' }}
              onChange={ev => setValue(ev, setOperation)}
            />
          </Col>
          <Col md={6}>
            <Axios api="users" run params={{ ignorePagination: true }}>
              {({ response }) => (
                <Input
                  type="select"
                  label="audit.list.filter.user"
                  value={user}
                  array={response}
                  option={{ key: 'id', name: 'login' }}
                  onChange={ev => setValue(ev, setUser)}
                />
              )}
            </Axios>
          </Col>
          <Col md={6}>
            <Input
              type="datetime"
              label="audit.list.filter.startDate"
              value={startDate}
              onChange={ev => setValue(ev, setStartDate)}
              returnFormat="YYYY-MM-DDTHH:mm:00Z"
            />
          </Col>
          <Col md={6}>
            <Input
              type="datetime"
              label="audit.list.filter.endDate"
              value={endDate}
              onChange={ev => setValue(ev, setEndDate)}
              returnFormat="YYYY-MM-DDTHH:mm:59Z"
            />
          </Col>
        </Row>
        <Row gutter={20} type="flex">
          <Col md={6}>
            <Input
              translateOptions
              type="select"
              label="audit.list.filter.model"
              value={model}
              array={auditModels}
              option={{ key: 'id', name: 'value' }}
              onChange={onModelChange}
            />
          </Col>
          <AuditLookup
            model={model}
            lookupValues={lookupValues}
            setLookupValues={setLookupValues}
            setIdentifier={setIdentifier}
            errors={errors}
          />
        </Row>
        <Row gutter={20} type="flex" justify="end">
          <Col>
            <Button onClick={clearFilters}>
              <Translate>audit.list.filter.clear</Translate>
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={search}>
              <Translate>audit.list.filter.search</Translate>
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default AuditFilter;
