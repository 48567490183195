import React, { useState } from 'react';

import Axios from '@components/Axios';
import ItemManager from '@components/ItemManager';

const ManagerTab = ({ goalId, bindFormProps, dealersSnapshot, productsSnapshot }) => {
  const [run, setRun] = useState(1);
  const [dealers, setDealers] = useState([]);
  const [manager, setManager] = useState([]);

  const [touched, setTouched] = useState({});

  const updateScreen = () => {
    setRun(run => run + 1);
  };

  const onSuccessAddTouched = () => {
    updateScreen();
    setTouched({});
  };

  const onSuccessDealer = response => {
    const dealers = response.map(({ dealer }) => ({ ...dealer }));
    setDealers(dealers);
  };

  const onSuccessManager = response => {
    setManager(response);
  };

  const submitForm = async submit => {
    await submit({ body: Object.values(touched) });
    return true;
  };

  const getComponentTable = ({ submit }) => {
    bindFormProps({ dirty: !!Object.keys(touched).length, submitForm: () => submitForm(submit) });
    return (
      <ItemManager
        selectDealer
        goalId={goalId}
        dealers={dealers}
        touched={touched}
        setTouched={setTouched}
        manager={manager}
        updateScreen={updateScreen}
        productsSnapshot={productsSnapshot}
      />
    );
  };

  return (
    <Axios
      api="goals"
      run={{ run, dealersSnapshot }}
      onSuccess={onSuccessDealer}
      params={[`${goalId}/dealers`, { ignorePagination: true, include: 'dealer' }]}
    >
      <Axios
        api="goals"
        run={{ run, productsSnapshot, dealersSnapshot }}
        onSuccess={onSuccessManager}
        params={[`${goalId}/managers`, { ignorePagination: true, include: 'manager,goalItem' }]}
      >
        <Axios
          api="goals"
          method="post"
          params={`${goalId}/managers`}
          onSuccess={onSuccessAddTouched}
        >
          {getComponentTable}
        </Axios>
      </Axios>
    </Axios>
  );
};

export default ManagerTab;
