import React, { useState } from 'react';

import { Switch, Row } from 'antd';

import CsvBatchImport from '@components/CsvBatchImport';
import Translate from '@components/Translate';

const columns = {
  name: 'NOME',
  email: 'EMAIL',
  documentNumber: 'CNPJ',
  phone: 'TELEFONE',
  cityName: 'CIDADE',
  stateAbbreviation: 'UF',
  parentMemberId: 'RTV - EMAIL',
  canAccessPortal: 'ACESSO AO PORTAL?',
};

const BatchImport = ({ history }) => {
  const [update, setUpdate] = useState(false);

  const axiosConfig = {
    api: 'dealers',
    method: 'post',
    params: ['/batch', { update }],
  };

  const successMessageConfig = {
    title: 'dealers.batchImport.successMessage.title',
    subtitle: 'dealers.batchImport.successMessage.subtitle',
    action: {
      onClick: () => history.push('/dealers/list'),
    },
  };

  return (
    <CsvBatchImport
      columns={columns}
      axiosConfig={axiosConfig}
      infoMessages={['dealers.batchImport.extraMessages.accessCampaign']}
      fileName="modelo_distribuidores"
      title="dealers.batchImport.header.sent.title"
      subtitle="dealers.batchImport.header.sent.subtitle"
      successMessageConfig={successMessageConfig}
    >
      <Row style={{ marginBottom: '10px' }}>
        <Switch checked={update} onChange={setUpdate} style={{ marginRight: '10px' }} />
        <Translate>dealers.batchImport.input.replaceExistingData</Translate>
      </Row>
    </CsvBatchImport>
  );
};

export default BatchImport;
