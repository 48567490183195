import clsx from 'clsx';
import { Switch } from 'antd';
import React, { memo } from 'react';

import { i18n } from '@components/Translate';

const SwitchComponet = ({
  value,
  error,
  name,
  defaultChecked,
  checkedChildren,
  unCheckedChildren,
  handleChange = () => {},
}) => {
  const onChange = value => {
    const event = {
      target: {
        name,
        value: value,
      },
    };
    handleChange(event);
  };

  const styles = clsx('Switch', { error });

  return (
    <Switch
      checked={value}
      className={styles}
      onChange={onChange}
      defaultChecked={defaultChecked}
      checkedChildren={i18n.t(checkedChildren)}
      unCheckedChildren={i18n.t(unCheckedChildren)}
    />
  );
};

export default memo(SwitchComponet);
