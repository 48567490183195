import React from 'react';

import { Card, Button, Tooltip } from 'antd';

import { EditOutlined } from '@ant-design/icons';

import Tag from '@components/Tag';
import TableSearch from '@components/TableSearch';
import Translate, { i18n } from '@components/Translate';
import ResetPassword from '@components/ResetPassword';

import './styles.scss';

const ListMember = ({ user, history }) => {
  const tableColumns = [
    {
      title: i18n.t('members.list.table.header.status'),
      dataIndex: 'status',
      render: (_, { active }) => {
        const type = active ? 'checkedChildren' : 'unCheckedChildren';
        return (
          <Tag color={active ? 'success' : 'error'}>
            {`members.list.table.header.status.${type}`}
          </Tag>
        );
      },
    },
    {
      title: i18n.t('members.list.table.header.name'),
      dataIndex: 'memberName',
      sorter: (a, b) => a.memberName.localeCompare(b.memberName),
      render: (_, { id, memberName }) => {
        return (
          <Tooltip title={i18n.t('members.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/members/${id}`)}>
              {memberName}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t('members.list.table.header.email'),
      dataIndex: 'emailAddress',
    },
    {
      title: i18n.t('members.list.table.header.function'),
      dataIndex: 'memberType',
      render: memberType => i18n.t(`members.create.form.memberTypes.${memberType}`),
    },
    {
      title: i18n.t('tableActions'),
      dataIndex: 'actions',
      align: 'right',
      render: (_, { id, memberName, emailAddress, portalAccessControl, userId }) => (
        <>
          <Tooltip title={i18n.t('members.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/members/${id}`)}>
              <EditOutlined />
            </Button>
          </Tooltip>

          <ResetPassword
            portalAccessControl={portalAccessControl}
            userId={userId}
            userName={memberName}
            userEmail={emailAddress}
            loggedUser={user}
          />
        </>
      ),
    },
  ];

  const action = () => {
    return (
      <Button
        type="primary"
        className="list-members-add"
        onClick={() => history.push('/members/create')}
      >
        <Translate>members.list.card.new</Translate>
      </Button>
    );
  };

  const axios = {
    run: true,
    api: 'members',
    params: { include: 'memberType,portalAccessControl' },
  };

  return (
    <div className="ListMember">
      {action()}
      <Card className="ant-card gx-card ant-card-bordered">
        <TableSearch axios={axios} columns={tableColumns} />
      </Card>
    </div>
  );
};

export default ListMember;
