// Actions
export const Types = {
  SUCCESS: 'auth/SUCCESS',
  FAILURE: 'auth/FAILURE',
  RESET: 'auth/RESET',
};

// initial state
const initial = {
  status: '',
  auth: {},
};

// Reducer
export default function reducer(state = initial, action = {}) {
  switch (action.type) {
    case Types.SUCCESS: {
      console.tron.log('success', action);
      return { ...state, status: 'success', auth: action.payload };
    }
    case Types.FAILURE: {
      return { ...state, status: 'failure' };
    }
    case Types.RESET: {
      return { ...initial };
    }
    default:
      return state;
  }
}

export const Creators = {
  success: payload => ({
    type: Types.SUCCESS,
    payload,
  }),
  reset: () => ({
    type: Types.RESET,
  }),
};
