import { hot } from 'react-hot-loader/root';
import React from 'react';
import './config/ReactotronConfig';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';

import { ConnectedRouter } from 'connected-react-router';
import { PersistGate } from 'redux-persist/integration/react';

import i18n from '@i18n';
import Alert from '@components/Alert';
import Loading from '@components/Loading/loading';
import store, { history, persistor } from '@redux/store';
import { UIStateContextProvider } from '@context/UIContext';
import { UserProvider } from '@context/UserContext';
import { MessageProvider } from '@context/MessageContext';

import Routes from './routes';

import './assets/vendors/style';
import './index.scss';

const App = () => (
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <UIStateContextProvider>
        <UserProvider>
          <MessageProvider>
            <PersistGate loading={null} persistor={persistor}>
              <ConnectedRouter history={history}>
                <div className="App">
                  <Routes />
                  <Loading />
                  <Alert />
                </div>
              </ConnectedRouter>
            </PersistGate>
          </MessageProvider>
        </UserProvider>
      </UIStateContextProvider>
    </Provider>
  </I18nextProvider>
);

export default hot(App);
