import React, { useState } from 'react';

import Axios from '@components/Axios';

import ListProduct from './components/ListProduct';
import GoalAnalytics from '../GeneralPanel/components/GoalAnalytics';

const Salespeople = ({ goalId, user, goalColor }) => {
  const { relationId } = user;

  const [items, setItems] = useState([]);
  const [products, setProducts] = useState([]);
  const [ranking, setRanking] = useState();

  const onSuccessItems = items => {
    setItems(items);
  };

  const onSuccess = data => {
    if (data) {
      const list = data.filter(item => item.goalItemId);
      setProducts(list);

      const ranking = data.find(item => !item.goalItemId)?.ranking;
      setRanking(ranking);
    }
  };

  const viewResultSalespeopleLabels = {
    total: `dashboard.salespeople.goalAnalytics.dealer.total`,
    goal: `dashboard.salespeople.goalAnalytics.dealer.goal`,
    actualValue: 'dashboard.salespeople.goalAnalytics.actualValue',
    ranking: 'dashboard.salespeople.goalAnalytics.ranking',
  };

  return (
    <div className="Salespeople">
      <Axios
        api="goals"
        run={goalId}
        onSuccess={onSuccessItems}
        params={[`${goalId}/items`, { ignorePagination: true }]}
      >
        <Axios
          api="goals"
          run={goalId}
          onSuccess={onSuccess}
          params={[
            `${goalId}/salespeople/${relationId}/values`,
            {
              ignorePagination: true,
              include: 'salesperson,goalItem,ranking',
            },
          ]}
        >
          <GoalAnalytics
            run
            goalId={goalId}
            api="salespeople"
            goalColor={goalColor}
            labels={viewResultSalespeopleLabels}
            ranking={ranking}
          />
          <ListProduct items={items} products={products} />
        </Axios>
      </Axios>
    </div>
  );
};

export default Salespeople;
