import { Button, Tag, Modal } from 'antd';
import React, { useState, useEffect } from 'react';

import { formatCnpj } from '@util/global';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';

import { isPhone } from '@util/isMobileScreen';
import useWindowResize from '@hooks/useWindowResize';

const AddDealer = ({ isVisible, dealers = [], onAdd, onCancel }) => {
  const [selectedDealers, setSelectedDealers] = useState({ data: [], keys: [] });

  const { width } = useWindowResize();
  const IS_PHONE_SCREEN = isPhone(width);

  useEffect(() => {
    const data = [];
    const keys = [];
    dealers.forEach(({ dealer }) => {
      data.push(dealer);
      keys.push(dealer.id);
    });
    setSelectedDealers({ data, keys });
  }, [dealers]);

  const columns = [
    {
      title: i18n.t('status'),
      dataIndex: 'active',
      render: active => {
        const [tagColor, tagLabel] = active ? ['green', 'active'] : ['volcano', 'inactive'];
        return <Tag color={tagColor}>{i18n.t(tagLabel)}</Tag>;
      },
    },
    {
      title: i18n.t('goalDealers.name'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { documentNumber }) => {
        return formatCnpj(documentNumber);
      },
    },
    {
      title: i18n.t('city'),
      dataIndex: 'cityState',
      render: (_, { cityState }) => {
        const { cityName, stateAbbreviation } = cityState || {};
        const city = cityName ? `${cityName}/${stateAbbreviation}` : '-';
        return city;
      },
    },
  ];

  const onAddClick = () => {
    onAdd(selectedDealers.data);
    setSelectedDealers({ data: [], keys: [] });
  };

  const footerComponent = () => {
    return (
      <Button type="primary" onClick={onAddClick}>
        {i18n.t('goalDealers.addSelected')}
      </Button>
    );
  };

  const axios = {
    run: true,
    api: 'dealers',
    params: { include: 'cityState' },
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: [...selectedDealers.keys],
    onChange: (keys, data) => setSelectedDealers({ data, keys }),
    getCheckboxProps: ({ id }) => ({
      disabled: !!dealers?.find(({ dealerId }) => dealerId === id),
    }),
  };

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={onCancel}
      footer={footerComponent()}
      title={i18n.t('goalDealers.addDealers')}
      width={!IS_PHONE_SCREEN ? '75vw' : null}
    >
      <TableSearch
        rowId="id"
        axios={axios}
        columns={columns}
        title="addDealers.title"
        extraTableProperties={{ rowSelection }}
      />
    </Modal>
  );
};

export default AddDealer;
