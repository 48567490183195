import React, { memo } from 'react';

import clsx from 'clsx';
import { Input } from 'antd';

import './styles.scss';

const SelectComponet = ({
  value,
  error,
  name,
  rows,
  disabled,
  placeholder,
  handleChange = () => {},
}) => {
  const onChange = ({ target: { value } }) => {
    if (!value) {
      value = null;
    }
    const event = {
      target: {
        name,
        value: value,
      },
    };
    handleChange(event);
  };

  const classNames = clsx('TextArea', { error });

  return (
    <Input.TextArea
      rows={rows}
      name={name}
      value={value}
      disabled={disabled}
      onChange={onChange}
      className={classNames}
      placeholder={placeholder}
    />
  );
};

export default memo(SelectComponet);
