import React from 'react';

import { Menu } from 'antd';

import UserInfo from './components/UserInfo';

import './index.scss';

const renderMenuItems = () => {
  const menuItems = [{ component: UserInfo }];

  return menuItems.map(({ component, id, itemClass }, i) => {
    const MenuComp = component;
    return (
      <Menu.Item key={id || i} className={itemClass || 'nav-item-short'}>
        <MenuComp />
      </Menu.Item>
    );
  });
};

const TopBarCompnent = () => {
  return <Menu mode="horizontal">{renderMenuItems()}</Menu>;
};

export default TopBarCompnent;
