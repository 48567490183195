import React, { memo } from 'react';

import clsx from 'clsx';
import moment from 'moment';
import 'moment/locale/pt-br';
import ptBR from 'antd/es/locale/pt_BR';
import { DatePicker, ConfigProvider } from 'antd';

const utcOffset = new Date().getTimezoneOffset() * -1;

const DatePickerComponet = ({
  error,
  name,
  value,
  showTime = false,
  format = 'DD/MM/YYYY',
  returnFormat = 'YYYY-MM-DD',
  handleChange = () => {},
}) => {
  const onChange = date => {
    const event = {
      target: {
        name,
        value: date?.format(returnFormat),
      },
    };
    handleChange(event);
  };

  const styles = clsx('DatePicker', { error });

  const getValue = () => {
    if (value) {
      const date = moment(value);
      if (showTime) {
        return date.utcOffset(utcOffset);
      } else {
        return date.utcOffset(3);
      }
    }
  };

  return (
    <ConfigProvider locale={ptBR}>
      <DatePicker
        showTime={showTime}
        format={format}
        className={styles}
        onChange={onChange}
        value={getValue()}
      />
    </ConfigProvider>
  );
};

export default memo(DatePickerComponet);
