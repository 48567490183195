import React, { useState, useEffect } from 'react';

import { Modal, Button, Tag } from 'antd';

import Input from '@components/Input';
import { formatCpf } from '@util/global';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { isPhone } from '@util/isMobileScreen';
import useWindowResize from '@hooks/useWindowResize';

const AddSalespeople = ({
  isVisible,
  listSalesperson,
  dealers,
  goalId,
  onAdd,
  onCancel,
  dealerIdField = 'id',
  dealerNameField = 'name',
}) => {
  const [parentId, setParentId] = useState();
  const [salespeople, setSalespeople] = useState({ data: [], keys: [] });

  const { width } = useWindowResize();
  const IS_PHONE_SCREEN = isPhone(width);

  useEffect(() => {
    const data = [];
    const keys = [];
    for (const { salesperson } of listSalesperson) {
      if (salesperson) {
        data.push(salesperson);
        keys.push(salesperson.id);
      }
    }
    setSalespeople({ data, keys });
  }, [listSalesperson]);

  const columns = [
    {
      title: i18n.t('status'),
      dataIndex: 'active',
      render: active => {
        const [tagColor, tagLabel] = active ? ['green', 'active'] : ['volcano', 'inactive'];
        return <Tag color={tagColor}>{i18n.t(tagLabel)}</Tag>;
      },
    },
    {
      title: i18n.t('name'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { documentNumber }) => {
        return formatCpf(documentNumber);
      },
    },
  ];

  const onAddClick = () => {
    const { data = [] } = salespeople;
    const list = data.filter(
      item => !listSalesperson?.find(({ salespersonId }) => salespersonId === item?.id),
    );
    onAdd(list);
  };

  const onChangeSelect = ({ target: { value } }) => {
    setParentId(value);
  };

  const footerComponent = () => {
    return (
      <Button type="primary" onClick={onAddClick}>
        {i18n.t('goalSalespeople.addSelected')}
      </Button>
    );
  };

  const axios = {
    run: { parentId },
    api: 'salespeople',
    params: { parentId, goalId },
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: [...salespeople.keys],
    onChange: (keys, data) => setSalespeople({ data, keys }),
    getCheckboxProps: ({ id }) => ({
      disabled: !!listSalesperson?.find(({ salespersonId }) => salespersonId === id),
    }),
  };

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={onCancel}
      footer={footerComponent()}
      width={!IS_PHONE_SCREEN ? '75vw' : null}
      title={i18n.t('goalSalespeople.addSalespeople')}
    >
      <Input
        type="select"
        array={dealers}
        value={parentId}
        style={{ width: 300 }}
        onChange={onChangeSelect}
        placeholder={'goalSalespeople.selectDealer'}
        option={{
          name: dealerNameField,
          key: dealerIdField,
        }}
      />
      <TableSearch
        rowId="id"
        axios={axios}
        columns={columns}
        title="addSalespeople.title"
        extraTableProperties={{ rowSelection }}
      />
    </Modal>
  );
};

export default AddSalespeople;
