/* eslint-disable import/no-unresolved */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Card, Button, Row, Col, message } from 'antd';
import * as yup from 'yup';

import { useUserContext } from '@context/UserContext';
import Axios from '@components/Axios';
import Translate from '@components/Translate';
import FormSubmit from '@components/FormSubmit';
import i18n from '@i18n';

import FormComponent from './components/FormComponent';
import './styles.scss';

const UpdateSalespeople = ({ id, api }) => {
  const history = useHistory();
  const { user, updateName } = useUserContext();

  const [object, setObject] = useState({});
  const [axios, setAxios] = useState({ method: 'put' });

  const getSalespeople = data => {
    const { dealerId, portalAccessControl } = data;
    data.dealerId = String(dealerId);
    data.canAccessPortal = portalAccessControl?.active;
    delete data.portalAccessControl;
    setObject(data);
    setAxios({ method: 'put', params: data.id });
  };

  const updateNameStorage = salespeople => updateName(salespeople);

  const onSuccess = salespeople => {
    message.success(i18n.t('profile.edit.success'), 5);
    if (salespeople.name !== user.name) {
      updateNameStorage(salespeople);
    }
  };

  const goback = () => history.push('/goal/list');

  const submitForm = async ({ values, submit, setErrors }) => {
    try {
      await submit({ body: values });
    } catch (error) {
      if (error?.unprocessableEntity) {
        setErrors(error.unprocessableEntity);
      } else if (error?.response?.data) {
        message.error(error.data.response, 5);
      }
    }
  };

  const formikProps = {
    initialValues: { active: true, ...object },
    validationSchema: yup.object().shape({
      phone: yup.string().max(20).nullable(),
      documentNumber: yup.string().max(20).nullable(),
      name: yup.string().max(60).required(),
    }),
  };

  const action = ({ handleSubmit }) => {
    return (
      <Row justify="end">
        <Col className="create_actions">
          <Button onClick={goback}>
            <Translate>salespeople.create.header.exit</Translate>
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            <Translate>salespeople.create.header.register</Translate>
          </Button>
        </Col>
      </Row>
    );
  };

  const getComponentForm = ({ submit }) => {
    return (
      <FormSubmit
        {...formikProps}
        onSubmit={(values, event) => submitForm({ ...event, values, submit })}
      >
        {({ handleSubmit, isValid, ...formikValues }) => (
          <>
            {action({ handleSubmit, isValid })}
            <Card>
              <FormComponent {...formikValues} />
            </Card>
          </>
        )}
      </FormSubmit>
    );
  };

  return (
    <div className="UpdateSalespeople">
      <Axios run={id} api={api} params={id} onSuccess={getSalespeople}>
        <Axios {...axios} api={api} onSuccess={onSuccess}>
          {getComponentForm}
        </Axios>
      </Axios>
    </div>
  );
};

export default UpdateSalespeople;
