export const Types = {
  OPEN_ALERT: 'alert/OPEN_ALERT',
  CLOSE_ALERT: 'alert/CLOSE_ALERT',
};

const initialState = {
  show: false,
  type: 'error',
  message: 'mensagem de erro',
  alertType: 'alert',
};

export default function alert(state = initialState, action) {
  switch (action.type) {
    case Types.OPEN_ALERT:
      return {
        ...state,
        show: true,
        message: action.payload.message,
        type: action.payload.type ? action.payload.type : 'error',
        status: action.payload.status ? action.payload.status : undefined,
        alertType: action.payload.alertType,
      };

    case Types.CLOSE_ALERT:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
}

export const Creators = {
  openAlert: (obj, type, alertType) => ({
    type: Types.OPEN_ALERT,
    payload: {
      message: obj.message || obj,
      type,
      status: obj.status,
      alertType: alertType ? 'modal' : 'alert',
    },
  }),
  closeAlert: () => ({
    type: Types.CLOSE_ALERT,
    payload: {},
  }),
};
