import React, { useState } from 'react';

import moment from 'moment';
import { Table, Button } from 'antd';

import { EyeOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';

import AuditFilter from './components/AuditFilter';
import OperationTag from './components/OperationTag';
import AuditDetailModal from './components/AuditDetailModal';

const Audit = () => {
  const [items, setItems] = useState([]);
  const [pagination, setPagination] = useState({});
  const [params, setParams] = useState();
  const [detailId, setDetailId] = useState();
  const [searchParams, setSearchParams] = useState({ include: 'user' });

  const onSearch = () => {
    const { pageSize } = pagination;
    setSearchParams({ ...searchParams, ...params, page: 0, pageSize });
  };

  const onSuccess = ({ items, pageSize, page, count }) => {
    setItems(items);
    setPagination({ pageSize, current: page + 1, total: count, page });
  };

  const onDetailClose = () => setDetailId();

  const onPageChange = ({ current, pageSize }) => {
    const page = current - 1;
    setPagination({ ...pagination, page, current, pageSize });
    setSearchParams({ ...searchParams, page, pageSize });
  };

  const tableColumns = [
    {
      title: i18n.t('audit.list.operation'),
      dataIndex: 'operation',
      render: operation => <OperationTag operation={operation} />,
    },
    {
      title: i18n.t('audit.list.model'),
      dataIndex: 'model',
      render: model => i18n.t(`audit.list.model.${model}`),
    },
    {
      title: i18n.t('audit.list.user'),
      dataIndex: ['user', 'login'],
    },
    {
      title: i18n.t('audit.list.performedAt'),
      dataIndex: 'performedAt',
      render: performedAt => moment(performedAt).format('DD/MM/YYYY HH:mm'),
    },
    {
      title: i18n.t('audit.list.detail'),
      dataIndex: 'detail',
      render: (_, { id }) => (
        <Button type="link" icon={<EyeOutlined />} onClick={() => setDetailId(id)} />
      ),
    },
  ];

  return (
    <>
      <AuditFilter params={params} setParams={setParams} onSearch={onSearch} />
      <Axios api="audit" run={searchParams} params={searchParams} onSuccess={onSuccess}>
        <Table
          columns={tableColumns}
          dataSource={items}
          pagination={pagination}
          onChange={onPageChange}
          locale={{ emptyText: i18n.t('tableSearch.emptyText') }}
        />
      </Axios>
      <AuditDetailModal id={detailId} onClose={onDetailClose} />
    </>
  );
};

export default Audit;
