import React from 'react';
import { CirclePicker } from 'react-color';

import { Row, Col } from 'antd';
import { uploadAttachment, retrieveSignedAttachment } from 'services/api';
import { UploadImage } from 'components/UploadImage';

import { Form } from '@ant-design/compatible';

import Input from '@components/Input';
import { goalStatus } from '@util/enums';
import Translate, { i18n } from '@components/Translate';

const goalStatusList = goalStatus.map(type => ({
  id: type,
  typeName: i18n.t(`goalStatuses.${type}`),
}));

const FormComponent = ({
  errors,
  values,
  handleSubmit,
  handleChange,
  setFieldValue,
  goalLogo,
  goalBanner,
  setGoalLogo,
  setGoalBanner,
}) => {
  const uploadImage = async (file, valueSetter, fieldName) => {
    const formData = new FormData();
    formData.append('file', file);
    const { id } = await uploadAttachment(formData);
    const attachment = await retrieveSignedAttachment(id);
    valueSetter(attachment);
    setFieldValue(fieldName, id);
  };

  const removeImage = (valueSetter, fieldName) => {
    valueSetter(null);
    setFieldValue(fieldName, null);
  };

  const onChangeColor = async goalColor => {
    setFieldValue('goalColor', goalColor);
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col md={12}>
          <Input
            name="goalName"
            onChange={handleChange}
            error={errors.goalName}
            value={values.goalName}
            label="goalGeneralInfo.name"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col md={6}>
          <Input
            type="date"
            name="startDate"
            onChange={handleChange}
            error={errors.startDate}
            value={values.startDate}
            label="goalGeneralInfo.startDate"
          />
        </Col>
        <Col md={6}>
          <Input
            type="date"
            name="endDate"
            onChange={handleChange}
            error={errors.endDate}
            value={values.endDate}
            label="goalGeneralInfo.endDate"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col md={12}>
          <Input
            type="select"
            name="goalStatus"
            array={goalStatusList}
            onChange={handleChange}
            error={errors.goalStatus}
            value={values.goalStatus}
            label="goalGeneralInfo.status"
            option={{ key: 'id', name: 'typeName' }}
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col md={12}>
          <Input
            type="switch"
            name="alwaysShow"
            onChange={handleChange}
            error={errors.alwaysShow}
            value={values.alwaysShow}
            label="goalGeneralInfo.alwaysShow"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col md={6}>
          <Form.Item label={i18n.t('goalGeneralInfo.cover')}>
            <p>
              <Translate>goal.createGoal.generalInfo.sizeCover</Translate>
            </p>
            <UploadImage
              imageUrl={goalLogo?.signedUrl}
              imageId={goalLogo?.id}
              action={({ file }) => uploadImage(file, setGoalLogo, 'goalLogoId')}
              onDelete={() => removeImage(setGoalLogo, 'goalLogoId')}
            />
          </Form.Item>
        </Col>
        <Col md={6}>
          <Form.Item label={i18n.t('goalGeneralInfo.banner')}>
            <p>
              <Translate>goal.createGoal.generalInfo.sizeBanner</Translate>
            </p>
            <UploadImage
              imageUrl={goalBanner?.signedUrl}
              imageId={goalBanner?.id}
              action={({ file }) => uploadImage(file, setGoalBanner, 'goalBannerId')}
              onDelete={() => removeImage(setGoalBanner, 'goalBannerId')}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col md={6}>
          <Form.Item label={i18n.t('goalGeneralInfo.color')}>
            <CirclePicker
              color={values.goalColor || undefined}
              onChangeComplete={({ hex }) => onChangeColor(hex)}
            />
          </Form.Item>
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
