import React, { useState } from 'react';

import { Row, Col, Progress } from 'antd';

import { RiseOutlined, TrophyOutlined, PieChartOutlined } from '@ant-design/icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMedal } from '@fortawesome/free-solid-svg-icons';

import Axios from '@components/Axios';
import BoxItem from '@components/BoxItem';
import { formatMoney, getPercent } from '@util/global';
import UpdateGoalByProfile from '@components/UpdateGoalByProfile';

import './styles.scss';

const GoalAnalytics = ({ run, api, itemGoalUpdate, user, labels, goalId, goalColor, ranking }) => {
  const [item, setItem] = useState({});

  const onSuccess = data => {
    setItem(data);
  };

  const { actualValue, goalValue } = item;
  const percent = getPercent(actualValue, goalValue);

  const getComponentUpdateItem = () => {
    if (itemGoalUpdate) {
      return (
        <UpdateGoalByProfile
          user={user}
          api={api}
          item={itemGoalUpdate}
          field="actualValue"
          updateScreen={data => setItem(data)}
        />
      );
    } else {
      return formatMoney(actualValue);
    }
  };

  const colSize = ranking ? 6 : 8;

  return (
    <div className="GoalAnalytics">
      <Axios
        api="goals"
        run={[run, goalId]}
        onSuccess={onSuccess}
        params={`${goalId}/${api}/analytics`}
      >
        <Row gutter={24} justify="space-around">
          <Col md={colSize} xs={24}>
            <div className="row-content">
              <BoxItem
                title={labels.goal}
                icon={() => <TrophyOutlined style={{ color: goalColor }} />}
              >
                <div className="row-content-number">{formatMoney(goalValue)}</div>
              </BoxItem>
            </div>
          </Col>
          <Col md={colSize} xs={24}>
            <div className="row-content">
              <BoxItem
                title={labels.total}
                icon={() => <RiseOutlined style={{ color: goalColor }} />}
              >
                <div className="row-content-number">{getComponentUpdateItem()}</div>
              </BoxItem>
            </div>
          </Col>
          <Col md={colSize} xs={24}>
            <div className="row-content">
              <BoxItem
                title={labels.actualValue}
                icon={() => <PieChartOutlined style={{ color: goalColor }} />}
              >
                <div className="row-content-percent">
                  <span className="percent">{percent}%</span>
                  <Progress strokeColor={goalColor} percent={percent} showInfo={false} />
                </div>
              </BoxItem>
            </div>
          </Col>
          {ranking && (
            <Col md={colSize} xs={24}>
              <div className="row-content">
                <BoxItem
                  title={labels.ranking}
                  icon={() => (
                    <FontAwesomeIcon
                      icon={faMedal}
                      color={goalColor ?? '#3e82f7'}
                      style={{
                        width: '40px',
                        height: '50px',
                        paddingBottom: '5px',
                      }}
                    />
                  )}
                >
                  <div className="row-content-number">{ranking}°</div>
                </BoxItem>
              </div>
            </Col>
          )}
        </Row>
      </Axios>
    </div>
  );
};

export default GoalAnalytics;
