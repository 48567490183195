import React, { useState } from 'react';

import { Input, Tabs } from 'antd';

import { PlusOutlined } from '@ant-design/icons';

import Button from '@components/Button';
import { goalStatusMap } from '@util/enums';
import { debounce } from '@util/debounce';
import { i18n } from '@components/Translate';
import { isAdmin, isDealer, isSales } from '@util/user.utils';

import TabList from './components/TabList';

import './styles.scss';

const GoalList = ({ user, history }) => {
  const [run, setRun] = useState(1);
  const [search, setSearch] = useState('');
  const [activeKey, setActiveKey] = useState(0);

  const goTo = path => {
    history.push(path);
  };

  const onTabChange = value => {
    setActiveKey(value);
  };

  const onSearch = value => {
    setSearch(value);
    setRun(run => run + 1);
  };

  const header = () => {
    if (isAdmin(user)) {
      return (
        <div className="list-goal-header">
          <div className="action">
            {isAdmin(user) && (
              <Button type="primary" onClick={() => goTo('/goal/create')} icon={<PlusOutlined />}>
                goal.list.card.new
              </Button>
            )}
          </div>
          <div className="search">
            <Input.Search
              placeholder={i18n.t('search')}
              className="default-header-search"
              onChange={debounce(onSearch, ({ target }) => target.value)}
            />
          </div>
        </div>
      );
    }
  };

  const menu = [
    {
      visible: true,
      title: 'listgoal.tabs.tabpane.active',
      component: () => (
        <TabList
          run={run}
          user={user}
          search={search}
          history={history}
          status={`${goalStatusMap.inProgress},${goalStatusMap.draft}`}
        />
      ),
    },
    {
      visible: true,
      title: 'listgoal.tabs.tabpane.past',
      component: () => (
        <TabList
          run={run}
          user={user}
          search={search}
          history={history}
          status={`${goalStatusMap.completed},${goalStatusMap.inVerification}`}
          params={isDealer(user) || isSales(user) ? { alwaysShow: true } : {}}
        />
      ),
    },
  ];

  const getListMenu = () => {
    return menu.filter(item => item.visible);
  };

  return (
    <div className="ListGoal">
      {header()}
      <Tabs defaultActiveKey="0" onChange={onTabChange} activeKey={activeKey.toString()}>
        {getListMenu().map((item, index) => (
          <Tabs.TabPane key={index.toString()} tab={i18n.t(item.title)}>
            {item.component()}
          </Tabs.TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default GoalList;
