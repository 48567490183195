import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Modal, Typography, Button, notification } from 'antd';

import { Creators as AlertActions } from '@redux/ducks/alert';

import i18n from '@i18n';

const Alert = function () {
  const dispatch = useDispatch();
  const show = useSelector(store => store.alert.show);
  const message = useSelector(store => store.alert.message);
  const status = useSelector(store => store.alert.status);
  const type = useSelector(store => store.alert.type);
  const alertType = useSelector(store => store.alert.alertType);
  useEffect(() => {
    if (show && alertType === 'alert') {
      notification[type]({
        message: type === 'success' ? i18n.t('sweetAlerts.success') : i18n.t('operation_not_found'),
        description: message,
        duration: 5,
      });
      dispatch(AlertActions.closeAlert());
    }
  }, [show]);
  return (
    <>
      {alertType === 'modal' && (
        <Modal visible={show} closable={false} footer={null} zIndex={1000}>
          {type === 'error' && (
            <div
              style={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex',
              }}
            >
              <Typography.Title level={4}>{i18n.t('operation_not_found')}</Typography.Title>
              <br />
            </div>
          )}
          <div
            style={{
              justifyContent: 'center',
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <Typography.Paragraph>{message}</Typography.Paragraph>
          </div>

          <div
            style={{
              justifyContent: 'space-between',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {status ? (
              <Typography.Paragraph>S.{status}</Typography.Paragraph>
            ) : (
              <Typography.Paragraph></Typography.Paragraph>
            )}
            <Button type="primary" onClick={() => dispatch(AlertActions.closeAlert())}>
              Fechar
            </Button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Alert;
