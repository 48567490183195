import React, { useState } from 'react';

import { Card } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import { formatCnpj } from '@util/global';
import Tag from '@components/Tag';
import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';
import TableSearch from '@components/TableSearch';
import AddDealerGoal from '@components/AddDealerGoal';
import UpdateGoalByProfile from '@components/UpdateGoalByProfile';
import { sortNumber } from '@util/columnSorter';

const ListDealers = ({ run, user, goalId, dealers, updateScreen }) => {
  const [deletingId, setDeletingId] = useState();
  const [addDealerModalVisible, setAddDealerModalVisible] = useState(false);

  const columns = [
    {
      title: i18n.t('status'),
      dataIndex: 'active',
      render: (_, { dealer }) => {
        const [type, color] = dealer?.active
          ? ['checkedChildren', 'success']
          : ['unCheckedChildren', 'error'];
        return <Tag color={color}>{`dashboard.dealers.list.table.header.status.${type}`}</Tag>;
      },
    },
    {
      title: i18n.t('dashboard.dealers.list.table.header.name'),
      dataIndex: ['dealer', 'name'],
    },
    {
      title: i18n.t('dashboard.dealers.list.table.header.documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { dealer }) => {
        return formatCnpj(dealer.documentNumber);
      },
    },
    {
      title: i18n.t('dashboard.dealers.list.table.header.city'),
      dataIndex: 'cityState',
      render: (_, { dealer = {} }) => {
        const { cityState } = dealer;
        return cityState ? `${cityState?.cityName}/${cityState?.stateAbbreviation}` : '-';
      },
    },
    {
      dataIndex: 'goalValue',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortNumber(a, b, 'goalValue'),
      title: i18n.t('dashboard.dealers.list.table.header.goal'),
      render: (_, { dealerId, ...item }) => {
        const object = { ...item, itemId: dealerId };
        return (
          <UpdateGoalByProfile
            user={user}
            api="dealers"
            item={object}
            field="goalValue"
            updateScreen={updateScreen}
          />
        );
      },
    },
    {
      dataIndex: 'actualValue',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortNumber(a, b, 'actualValue'),
      title: i18n.t('dashboard.dealers.list.table.header.actualValue'),
      render: (_, { dealerId, ...item }) => {
        const object = { ...item, itemId: dealerId };
        return (
          <UpdateGoalByProfile
            user={user}
            api="dealers"
            item={object}
            field="actualValue"
            updateScreen={updateScreen}
          />
        );
      },
    },
    {
      title: i18n.t('dashboard.dealers.list.table.header.actions'),
      render: (_, { dealer: { id } }) => {
        const onClick = () => setDeletingId(id);
        const onCancel = () => setDeletingId();
        const axios = {
          api: 'goals',
          method: 'delete',
          onError: onCancel,
          onSuccess: onSuccessDelete,
          params: `${goalId}/dealers/${id}/values`,
        };
        return (
          <Popconfirm
            axios={axios}
            onClick={onClick}
            onCancel={onCancel}
            title="confirmDelete"
            visible={deletingId === id}
          >
            <DeleteOutlined />
          </Popconfirm>
        );
      },
    },
  ];

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddDealers = () => {
    updateScreen();
    toggleAddDealer();
  };

  const toggleAddDealer = () => {
    setAddDealerModalVisible(!addDealerModalVisible);
  };

  const onAddDealers = ({ body, submit }) => {
    const data = body
      .filter(item => item && !dealers.find(({ dealer }) => item?.id === dealer.id))
      .map(item => {
        return {
          goalId,
          dealer: item,
          goalValue: 0,
          actualValue: 0,
          dealerId: item.id,
        };
      });
    submit({ body: data });
  };

  const axios = {
    run: run,
    api: 'goals',
    params: [`${goalId}/dealers`, { include: 'dealer', ignorePagination: true }],
  };

  const actionProps = {
    icon: <PlusOutlined />,
    onClick: toggleAddDealer,
    title: 'dashboard.dealers.list.table.action',
  };

  return (
    <Card className="ListDealers">
      <TableSearch
        axios={axios}
        ignorePagination
        columns={columns}
        actionProps={actionProps}
        title="dashboard.dealers.list.table.title"
        rowId="id"
      />
      <Axios api="goals" method="post" params={`${goalId}/dealers`} onSuccess={onSuccessAddDealers}>
        {({ submit }) => (
          <AddDealerGoal
            dealers={dealers}
            onCancel={toggleAddDealer}
            isVisible={addDealerModalVisible}
            onAdd={body => onAddDealers({ body, submit })}
          />
        )}
      </Axios>
    </Card>
  );
};

export default ListDealers;
