import { useState, useEffect } from 'react';

export default ({ asset }: { asset: () => Promise<any> }): any => {
  const [assetModule, setAssetModule] = useState(null);
  useEffect(() => {
    asset &&
      (async () => {
        const assetResult = typeof asset === 'function' ? await asset() : asset;
        setAssetModule(assetResult.default || null);
      })();
  }, [asset]);
  return assetModule;
};
