import React, { useState } from 'react';

import { Tabs } from 'antd';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import { isDealer } from '@util/user.utils';

import ListProduct from './components/ListProduct';
import ListDealers from './components/ListDealers';
import GoalAnalytics from './components/GoalAnalytics';
import ListSalespeople from './components/ListSalespeople';
import ListManager from './components/ListManagers';
import ListTelesales from './components/ListTelesales';

const { TabPane } = Tabs;

const GeneralPanel = ({ user, goalId, goal, goalColor, history }) => {
  const [dealers, setDealers] = useState();
  const [activeKey, setActiveKey] = useState(0);
  const [runDealers, setRunDealers] = useState(1);
  const [runSalespeople, setRunSalespeople] = useState(1);
  const [runManager, setRunManager] = useState(1);
  const [runTelesales, setRunTelesales] = useState(1);

  const onSuccessDealers = data => {
    setDealers(data);
  };

  const updateDealers = () => {
    setRunDealers(run => run + 1);
  };

  const updateSalespeople = () => {
    setRunSalespeople(run => run + 1);
  };

  const updateManager = () => {
    setRunManager(run => run + 1);
  };

  const updateTelesales = () => {
    setRunTelesales(run => run + 1);
  };

  const getItemUpdateGoalValue = () => {
    const item = dealers?.find(dealerItem => dealerItem.dealer.id === user.relationId) || {};
    return { ...item, itemId: item.dealerId };
  };

  const verifyLabelByProfile = () => {
    return isDealer(user) ? '.dealer' : '';
  };

  const viewResultDealersLabels = {
    total: `dashboard.dealers.goalAnalytics${verifyLabelByProfile()}.total`,
    goal: `dashboard.dealers.goalAnalytics${verifyLabelByProfile()}.goal`,
    actualValue: 'dashboard.dealers.goalAnalytics.actualValue',
  };

  const viewResultSalespeopleLabels = {
    total: `dashboard.salespeople.goalAnalytics${verifyLabelByProfile()}.total`,
    goal: `dashboard.salespeople.goalAnalytics${verifyLabelByProfile()}.goal`,
    actualValue: 'dashboard.salespeople.goalAnalytics.actualValue',
  };

  const viewResultManagerLabels = {
    total: `dashboard.manager.goalAnalytics${verifyLabelByProfile()}.total`,
    goal: `dashboard.manager.goalAnalytics${verifyLabelByProfile()}.goal`,
    actualValue: 'dashboard.manager.goalAnalytics.actualValue',
  };

  const viewResultTelesalesLabels = {
    total: `dashboard.telesales.goalAnalytics${verifyLabelByProfile()}.total`,
    goal: `dashboard.telesales.goalAnalytics${verifyLabelByProfile()}.goal`,
    actualValue: 'dashboard.telesales.goalAnalytics.actualValue',
  };

  const menu = [
    {
      visible: !isDealer(user),
      title: 'dashboard.tabs.tabpane.dealers',
      component: () => (
        <>
          <GoalAnalytics
            api="dealers"
            run={runDealers}
            goalId={goalId}
            goalColor={goalColor}
            labels={viewResultDealersLabels}
          />
          <ListDealers
            user={user}
            goalId={goalId}
            run={runDealers}
            dealers={dealers}
            updateScreen={updateDealers}
          />
        </>
      ),
    },
    {
      visible: true,
      title: `dashboard.tabs.tabpane.${isDealer(user) ? 'dashboard' : 'salespeople'}`,
      component: () => (
        <>
          {isDealer(user) && (
            <GoalAnalytics
              api="dealers"
              run={runDealers}
              goalId={goalId}
              goalColor={goalColor}
              updateScreen={updateSalespeople}
              labels={viewResultDealersLabels}
              itemGoalUpdate={getItemUpdateGoalValue()}
            />
          )}
          <GoalAnalytics
            goalId={goalId}
            api="salespeople"
            run={runSalespeople}
            goalColor={goalColor}
            labels={viewResultSalespeopleLabels}
          />
          <ListSalespeople
            user={user}
            goalId={goalId}
            goal={goal}
            dealers={dealers}
            run={runSalespeople}
            updateScreen={updateSalespeople}
            history={history}
          />
        </>
      ),
    },
    {
      visible: true,
      title: `manager.list.header.title`,
      component: () => (
        <>
          <GoalAnalytics
            goalId={goalId}
            api="managers"
            run={runManager}
            goalColor={goalColor}
            labels={viewResultManagerLabels}
          />
          <ListManager
            user={user}
            goalId={goalId}
            goal={goal}
            dealers={dealers}
            run={runManager}
            updateScreen={updateManager}
            history={history}
          />
        </>
      ),
    },
    {
      visible: true,
      title: `telesales.list.header.title`,
      component: () => (
        <>
          <GoalAnalytics
            goalId={goalId}
            api="telesales"
            run={runTelesales}
            goalColor={goalColor}
            labels={viewResultTelesalesLabels}
          />
          <ListTelesales
            user={user}
            goalId={goalId}
            goal={goal}
            dealers={dealers}
            run={runTelesales}
            updateScreen={updateTelesales}
            history={history}
          />
        </>
      ),
    },
    {
      visible: true,
      title: 'dashboard.tabs.tabpane.item',
      component: () => (
        <ListProduct
          user={user}
          goalId={goalId}
          goal={goal}
          dealers={dealers}
          run={runSalespeople}
          updateScreen={updateSalespeople}
        />
      ),
    },
  ];

  const onTabChange = value => {
    setActiveKey(value);
  };

  const getListMenu = () => {
    return menu.filter(item => item.visible);
  };

  return (
    <div className="GeneralPanel">
      <Axios
        api="goals"
        run={[runDealers, goalId]}
        onSuccess={onSuccessDealers}
        params={[`${goalId}/dealers`, { ignorePagination: true, include: 'dealer' }]}
      >
        <Tabs defaultActiveKey="0" onChange={onTabChange} activeKey={activeKey.toString()}>
          {getListMenu().map((item, index) => (
            <TabPane key={index.toString()} tab={i18n.t(item.title)}>
              {item.component()}
            </TabPane>
          ))}
        </Tabs>
      </Axios>
    </div>
  );
};

export default GeneralPanel;
