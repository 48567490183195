import React from 'react';

import { Tabs } from 'antd';

import ListProductSalespeople from './listSalespeople';
import ListProductTelesales from './listTelesales';
import './styles.scss';

const { TabPane } = Tabs;

const ListProduct = ({ run, updateScreen, goalId, goal, dealers, user }) => {
  return (
    <div className="ListProduct">
      <Tabs defaultActiveKey="1">
        <TabPane tab="Produtos-foco - Vendedores" key="1">
          <ListProductSalespeople
            run={run}
            updateScreen={updateScreen}
            goalId={goalId}
            goal={goal}
            dealers={dealers}
            user={user}
          />
        </TabPane>
        <TabPane tab="Produtos-foco - Televendas" key="2">
          <ListProductTelesales
            run={run}
            updateScreen={updateScreen}
            goalId={goalId}
            goal={goal}
            dealers={dealers}
            user={user}
          />
        </TabPane>
      </Tabs>
    </div>
  );
};

export default ListProduct;
