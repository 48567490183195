import React from 'react';

import { Row } from 'antd';

import { FilePdfOutlined, WhatsAppOutlined } from '@ant-design/icons';

import Button from '@components/Button';
import DoneGoalPermissionWrapper from '@containers/Dashboard/components/DoneGoalPermissionWrapper';
import { isAdmin, isSales, isManager } from '@util/user.utils';
import { useUserContext } from '@context/UserContext';
import Axios, { downloadFile } from '@components/Axios';

import './styles.scss';

const Header = ({ user, goal = {}, data = {}, history }) => {
  const { contactNumber = '', defaultMessage = '', guideAttachmentId } = data;
  const { id: goalId, goalName, goalStatus } = goal;

  const { token } = useUserContext();

  const onSuccessAttachments = ({ signedUrl }) => {
    openLink(signedUrl);
  };

  const openLink = link => {
    window.open(link, { target: 'blank' });
  };

  const goTo = path => {
    history.push(path);
  };

  const getLinkWhatsApp = () => {
    const number = contactNumber.replace(/\D/g, '');
    let link = `https://api.whatsapp.com/send?phone=+55${number}`;
    if (defaultMessage) {
      link = `${link}&text=${defaultMessage}`;
    }
    openLink(link);
  };

  const getNameGoal = () => {
    const { goalName } = goal;
    return (
      <Row justify="space-between" className="title-edit">
        {goalName}
      </Row>
    );
  };

  const downloadResult = () => {
    downloadFile({
      token,
      api: 'goals',
      params: `${goalId}/report`,
      filename: `${goalName}_dados_performance.csv`,
    });
  };

  const getContentByProfile = () => {
    if (!isSales(user) && !isManager(user)) {
      return (
        <DoneGoalPermissionWrapper goalStatus={goalStatus}>
          <Button type="primary" onClick={downloadResult}>
            dashboard.header.action.dowload
          </Button>
          {/* <Button type="primary" onClick={() => goTo(`/upload-batch/${goal.id}`)}>
            dashboard.header.action.updateBatch
          </Button> */}
        </DoneGoalPermissionWrapper>
      );
    }
  };

  return (
    <div className="Header">
      {getNameGoal()}
      <Row justify="space-between">
        <div className="action">
          {isAdmin(user) && (
            <Button type="primary" onClick={() => goTo(`/goal/${goal.id}`)}>
              dashboard.header.action.updateGoal
            </Button>
          )}
          {getContentByProfile()}
        </div>
        <div className="action">
          {guideAttachmentId && (
            <Axios
              api="attachments"
              onSuccess={onSuccessAttachments}
              params={`${guideAttachmentId}/signed`}
            >
              {({ submit }) => (
                <Button className="action-basic" onClick={submit} icon={<FilePdfOutlined />}>
                  dashboard.header.action.guide
                </Button>
              )}
            </Axios>
          )}
          {contactNumber && (
            <Button
              className="action-basic"
              icon={<WhatsAppOutlined />}
              onClick={() => openLink(getLinkWhatsApp())}
            >
              dashboard.header.action.whatsapp
            </Button>
          )}
        </div>
      </Row>
    </div>
  );
};

export default Header;
