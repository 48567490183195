import React, { useState } from 'react';
import { InboxOutlined, FileOutlined, LoadingOutlined } from '@ant-design/icons';

import Translate from '@components/Translate';

import './styles.scss';

const Update = ({ setFile, loading }) => {
  const [nameFile, setNameFile] = useState(null);

  const handleChange = ({ target: { files } }) => {
    const file = files[0];
    const fileName = file?.name;
    if (fileName && fileName !== nameFile) {
      setFile(file);
      setNameFile(file.name);
    }
  };

  const getIcon = () => {
    if (nameFile) {
      return <FileOutlined />;
    } else {
      return <InboxOutlined />;
    }
  };

  const getText = () => {
    if (nameFile) {
      return nameFile;
    } else {
      return <Translate>updateBatch.dragger.description</Translate>;
    }
  };

  const getComponent = () => {
    if (loading) {
      return (
        <p className="ant-upload-drag-icon">
          <LoadingOutlined />
        </p>
      );
    } else {
      return (
        <label className="update-label">
          <p className="ant-upload-drag-icon">{getIcon()}</p>
          <p className="ant-upload-text">{getText()}</p>
          <input className="input-file" onChange={handleChange} accept=".csv" type="file" />
        </label>
      );
    }
  };

  return <div className="Update">{getComponent()}</div>;
};

export default Update;
