import { APP_DOMAIN_PROFISSIONAL, APP_DOMAIN_VENDAS } from './config';

export default {
  WINDOW_TITLE: {
    [APP_DOMAIN_PROFISSIONAL]: 'Campanha Profissional',
    [APP_DOMAIN_VENDAS]: 'Campanha Venda Livre',
  },
  LOGIN_BACKGROUND: {
    [APP_DOMAIN_PROFISSIONAL]: () => import('@assets/images/pco.png'),
    [APP_DOMAIN_VENDAS]: () => import('@assets/images/psa.png'),
  },
  LOGO_BIG: {
    [APP_DOMAIN_PROFISSIONAL]: () => import('@assets/images/pco-logo-big.png'),
    [APP_DOMAIN_VENDAS]: () => import('@assets/images/psa-logo-big.png'),
  },
  LOGO_WHITE_BIG: {
    [APP_DOMAIN_PROFISSIONAL]: () => import('@assets/images/pco-logo-white-big.png'),
    [APP_DOMAIN_VENDAS]: () => import('@assets/images/psa-logo-white-big.png'),
  },
  LOGO_SMALL: {
    [APP_DOMAIN_PROFISSIONAL]: () => import('@assets/images/pco-logo-small.png'),
    [APP_DOMAIN_VENDAS]: () => import('@assets/images/psa-logo-small.png'),
  },
  HEADER_BG_COLOR: {
    [APP_DOMAIN_PROFISSIONAL]: '#120338',
    [APP_DOMAIN_VENDAS]: '#120338',
  },
};
