import React, { useState } from 'react';

import Axios from '@components/Axios';

import GoalAnalytics from '../GeneralPanel/components/GoalAnalytics';

const Manager = ({ goalId, user, goalColor }) => {
  const { relationId } = user;

  const [ranking, setRanking] = useState();

  const onSuccess = data => {
    if (data) {
      const ranking = data.find(item => !item.goalItemId)?.ranking;
      setRanking(ranking);
    }
  };

  const viewResultManagerLabels = {
    total: `dashboard.manager.goalAnalytics.dealer.total`,
    goal: `dashboard.manager.goalAnalytics.dealer.goal`,
    actualValue: 'dashboard.manager.goalAnalytics.actualValue',
    ranking: 'dashboard.manager.goalAnalytics.ranking',
  };

  return (
    <div className="Manager">
      <Axios
        api="goals"
        run={goalId}
        onSuccess={onSuccess}
        params={[
          `${goalId}/managers/${relationId}/values`,
          {
            ignorePagination: true,
            include: 'manager,goalItem,ranking',
          },
        ]}
      >
        <GoalAnalytics
          run
          goalId={goalId}
          api="managers"
          goalColor={goalColor}
          labels={viewResultManagerLabels}
          ranking={ranking}
        />
      </Axios>
    </div>
  );
};

export default Manager;
