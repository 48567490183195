import React, { useState } from 'react';

import { Card, Button, Row, Col } from 'antd';
import csv from 'csvtojson';

import Translate from '@components/Translate';
import { download } from '@util/global';

import Upload from './components/Upload';
import TableList from './components/TableList';

const DELIMITER = ';';

const CsvBatchImport = ({
  columns,
  axiosConfig,
  fileName,
  infoMessages,
  title,
  subtitle,
  successMessageConfig,
  children,
}) => {
  const [file, setFile] = useState();
  const [data, setData] = useState();

  const getData = () => {
    if (file) {
      const reader = new FileReader();
      reader.onload = ({ target: { result } }) => {
        csv({ delimiter: DELIMITER }).fromString(result).then(setData);
      };
      reader.readAsText(file, 'utf-8');
    }
  };

  const downloadModel = () => {
    const url =
      'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(Object.values(columns).join(DELIMITER));
    download(`${fileName}.csv`, url);
  };

  const tableListComponent = () => (
    <Card className="ant-card">
      <TableList
        file={file}
        data={data}
        setData={setData}
        columns={columns}
        title={title}
        subtitle={subtitle}
        successMessageConfig={successMessageConfig}
        axiosConfig={axiosConfig}
      />
    </Card>
  );

  const uploadComponent = () => (
    <Card className="ant-card update-batch-card">
      <Row justify="center">
        <Col>
          <Upload file={file} setFile={setFile} />
          <Row justify="center">
            <div className="dragger-message">
              <b>
                <Translate>component.csvbatchimport.message.title</Translate>
              </b>
              <ul>
                <li className="upload-text">
                  <Translate>component.csvbatchimport.message.onlyCsv</Translate>
                </li>
                {infoMessages?.map((msg, idx) => (
                  <li key={idx} className="upload-text">
                    <Translate>{msg}</Translate>
                  </li>
                ))}
              </ul>
            </div>
          </Row>
          {children ? <>{children}</> : null}
          <Row justify="space-between">
            <Button onClick={downloadModel} className="update-batch-action">
              <Translate>component.csvbatchimport.action.model</Translate>
            </Button>
            <Button
              disabled={!file}
              onClick={getData}
              className="update-batch-action"
              type="primary"
            >
              <Translate>component.csvbatchimport.action.send</Translate>
            </Button>
          </Row>
        </Col>
      </Row>
    </Card>
  );

  return data?.length ? tableListComponent() : uploadComponent();
};

export default CsvBatchImport;
