import React, { useContext } from 'react';

import { Layout } from 'antd';

import { UIStateContext } from '@context/UIContext';
import useWindowResize from '@hooks/useWindowResize';

import { isPhone } from '@util/isMobileScreen';

import Translate from '@components/Translate';
import { CONST_UI_SIDEBAR_WIDTH, CONST_UI_SIDEBAR_MIN_WIDTH } from '@constants/UI';

import Main from './components/Main';
import Sidebar from './components/Sidebar';
import PageHeader from './components/PageHeader';
import { HeaderNav } from './components/Header/index.js';

import pack from '@root/package.json';

const { Footer } = Layout;

const LayoutComponent = ({ match, history }) => {
  const { width } = useWindowResize();
  const IS_PHONE_SCREEN = isPhone(width);
  const { state: UIState } = useContext(UIStateContext);

  const { SIDEBAR_MENU_EXPANDED } = UIState;

  let MAIN_CONTENT_WIDTH;

  if (!SIDEBAR_MENU_EXPANDED || IS_PHONE_SCREEN) {
    if (IS_PHONE_SCREEN) {
      MAIN_CONTENT_WIDTH = 0;
    } else {
      MAIN_CONTENT_WIDTH = CONST_UI_SIDEBAR_MIN_WIDTH;
    }
  } else {
    MAIN_CONTENT_WIDTH = CONST_UI_SIDEBAR_WIDTH;
  }

  return (
    <Layout>
      <Sidebar />
      <Layout className="app-container">
        <HeaderNav isMobile={IS_PHONE_SCREEN} />
        <Layout className="app-layout main" style={{ paddingLeft: MAIN_CONTENT_WIDTH }}>
          <div className="app-content">
            <div className="app-content-main">
              <PageHeader match={match} history={history} />
              <Main match={match} />
            </div>
            <Footer className="footer">
              <Translate>siderbar.footer.version</Translate>
              <span>{pack.version}</span>
            </Footer>
          </div>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default LayoutComponent;
