import React, { useEffect, useState } from 'react';

import { Table, Empty, Card, Row, Button } from 'antd';
import get from 'lodash/get';

import { DeleteOutlined } from '@ant-design/icons';

import { sortText } from '@util/columnSorter';
import Axios from '@components/Axios';
import Input from '@components/Input';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';
import AddManagerGoal from '@components/AddManagerGoal';

import './styles.scss';

const ItemManager = ({
  goalId,
  manager,
  dealers,
  touched,
  component,
  setTouched,
  selectDealer,
  updateScreen,
  productsSnapshot,
}) => {
  const [list, setList] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [parentId, setParentId] = useState();
  const [products, setProducts] = useState([]);
  const [deletingId, setDeletingId] = useState();
  const [addManagerVisible, setAddManagerVisible] = useState(false);
  const [filterDataSetManager, setFilterDataSetManager] = useState([]);

  const columns = [
    {
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortText(a, b, 'name'),
      title: i18n.t('goalManager.managerName'),
    },
    {
      title: i18n.t('goalManager.goal'),
      render: (_, { id, managerId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, managerId, goalValue, actualValue };
        const value = getInputValue(`${key}.goalValue`, goalValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="goalValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('goalManager.done'),
      render: (_, { id, managerId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, managerId, goalValue, actualValue };
        const value = getInputValue(`${key}.actualValue`, actualValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="actualValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('tableActions'),
      render: (_, { managerId: id }) => (
        <Popconfirm
          title="confirmDelete"
          onSuccess={onSuccessDelete}
          visible={deletingId === id}
          onClick={() => setDeletingId(id)}
          onCancel={() => setDeletingId()}
          axios={{
            api: 'goals',
            method: 'delete',
            params: `${goalId}/managers/${id}/values`,
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const data = groupManager(manager);
    setList(data);
  }, [manager]);

  useEffect(() => {
    includeItemInManager();
  }, [list, products]);

  const groupManager = manager => {
    if (!Array.isArray(manager)) {
      return [];
    }

    const grouped = manager.reduce((acc, { id, goalValue, actualValue, manager, goalItem }) => {
      let entry = acc[manager.id] || { ...manager, managerId: manager.id };
      const object = {
        id,
        goalValue,
        actualValue,
        managerId: manager.id,
      };
      if (!goalItem) {
        entry = { ...entry, ...object };
      } else {
        const goalItems = entry.goalItems || [];
        entry.goalItems = [
          ...goalItems,
          {
            ...object,
            goalItemId: goalItem.id,
            itemName: goalItem.itemName,
          },
        ];
      }
      acc[manager.id] = entry;
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const includeItemInManager = () => {
    const dataset = list.map(manager => {
      const { goalItems = [] } = manager;
      const missingProducts =
        products
          ?.filter(({ id }) => !goalItems.find(({ goalItemId }) => goalItemId === id))
          ?.map(({ id, itemName }) => ({ goalItemId: id, itemName, actualValue: 0 })) ?? [];
      return {
        ...manager,
        goalItems: [...goalItems, ...missingProducts],
      };
    });
    setDataset(dataset);
    setFilterDataSetManager(dataset);
  };

  const onChangeValue = ({ target: { name, value, object = {} } }) => {
    if (!touched[object.key]) {
      object[name] = value;
      touched[object.key] = { ...object, goalId };
    } else {
      touched[object.key][name] = value;
    }
    setTouched({ ...touched });
  };

  const toggleAddManager = () => {
    setAddManagerVisible(!addManagerVisible);
  };

  const onSuccessItem = items => {
    setProducts(items);
  };

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddManager = () => {
    setAddManagerVisible(!addManagerVisible);
    updateScreen();
  };

  const getInputValue = (key, oldValue) => {
    const newValue = get(touched, key);
    if (newValue !== undefined) {
      return newValue;
    } else {
      return oldValue || 0;
    }
  };

  const onAddManager = ({ body, submit }) => {
    const data = body
      .filter(item => item && !manager.find(mnger => item?.id === mnger.managerId))
      .map(item => {
        return {
          goalId,
          goalValue: 0,
          actualValue: 0,
          managerId: item.id,
        };
      });
    submit({ body: data });
  };

  const onChangeSelect = ({ target: { value } }) => {
    if (value) {
      const list = dataset.filter(({ dealerId }) => dealerId === +value);
      setFilterDataSetManager(list);
    } else {
      setFilterDataSetManager(dataset);
    }
    setParentId(value);
  };

  const selectDealerComponent = () => {
    return (
      <Input
        type="select"
        array={dealers}
        value={parentId}
        style={{ width: 200 }}
        onChange={onChangeSelect}
        placeholder={'goalManager.selectDealer'}
        option={{
          key: 'id',
          name: 'name',
        }}
      />
    );
  };

  const getAction = () => {
    return <Button onClick={toggleAddManager}>{i18n.t('goalManager.addManager')}</Button>;
  };

  const getComponent = () => {
    if (!dataset.length) {
      return <Empty description={i18n.t('goalManager.noManagerYet')}>{getAction()}</Empty>;
    } else {
      return (
        <Card>
          <Row className="header" justify="space-between">
            <div>{selectDealer && selectDealerComponent()}</div>
            <div className="header-container">
              {component && component()}
              <div className="header-action-add">{getAction()}</div>
            </div>
          </Row>
          <Table
            columns={columns}
            pagination={false}
            rowKey={record => record.id}
            dataSource={filterDataSetManager}
          />
        </Card>
      );
    }
  };

  return (
    <div className="ItemManager">
      <Axios
        api="goals"
        onSuccess={onSuccessItem}
        run={[list, productsSnapshot]}
        params={[`${goalId}/items`, { ignorePagination: true }]}
      >
        {getComponent()}

        <Axios
          api="goals"
          method="post"
          params={`${goalId}/managers`}
          onSuccess={onSuccessAddManager}
        >
          {({ submit }) => (
            <AddManagerGoal
              goalId={goalId}
              dealers={dealers}
              listManager={manager}
              onCancel={toggleAddManager}
              isVisible={addManagerVisible}
              onAdd={body => onAddManager({ body, submit })}
            />
          )}
        </Axios>
      </Axios>
    </div>
  );
};

export default ItemManager;
