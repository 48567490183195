import React, { useState, useEffect } from 'react';

import * as yup from 'yup';
import moment from 'moment';
import { Modal } from 'antd';

import { goalStatus } from '@util/enums';
import FormSubmit from '@components/FormSubmit';
import { retrieveSignedAttachment } from '@services/api';
import Axios from '@components/Axios';
import { i18n } from '@components/Translate';

import FormInfo from './components/Form';
import '@ant-design/compatible/assets/index.css';

const GeneralInfoTab = ({ goal, setGoal, bindFormProps }) => {
  const defaultValues = { goalStatus: 'DRAFT', active: true, alwaysShow: false };

  const [items, setItems] = useState([]);
  const [goalLogo, setGoalLogo] = useState();
  const [goalBanner, setGoalBanner] = useState();
  const [initialValues, setInitialValues] = useState({});
  const [axios, setAxios] = useState({ method: 'post' });

  const api = 'goals';

  useEffect(() => {
    async function fetchData() {
      if (goal) {
        const { id, goalLogoId, goalBannerId, startDate, endDate } = goal;
        if (id) {
          setAxios({ method: 'put', params: id });
        }
        setInitialValues({
          ...goal,
          endDate: moment(endDate),
          startDate: moment(startDate),
        });
        if (goalLogoId) {
          const goalLogo = await retrieveSignedAttachment(goalLogoId);
          setGoalLogo(goalLogo);
        }
        if (goalBannerId) {
          const goalBanner = await retrieveSignedAttachment(goalBannerId);
          setGoalBanner(goalBanner);
        }
      }
    }
    fetchData();
  }, [goal]);

  const formikProps = {
    validateOnBlur: false,
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: { ...defaultValues, ...initialValues },
    validationSchema: yup.object().shape({
      goalName: yup.string().max(60).required(),
      startDate: yup.date().required(),
      endDate: yup
        .date()
        .required()
        .when(['startDate'], (startDate, schema) => startDate && schema.min(startDate)),
      goalStatus: yup.string().oneOf(goalStatus),
      alwaysShow: yup.bool().required(),
    }),
  };

  const confirmSaveWithItemOutOfDateRange = data => {
    Modal.confirm({
      title: i18n.t('goalGeneralInfo.itemsOutOfDataRange.title'),
      content: i18n.t('goalGeneralInfo.itemsOutOfDataRange.message'),
      okText: i18n.t('confirm'),
      cancelText: i18n.t('cancel'),
      onOk: () => submit(data),
    });
  };

  const hasItemsOutOfDateRange = ({ values }) => {
    let { startDate, endDate } = values;
    if (goal && (goal.startDate !== startDate || goal.endDate !== endDate)) {
      if (items.length && startDate && endDate) {
        startDate = moment(startDate);
        endDate = moment(endDate);
        const outOfRangeItems = items.filter(({ goalItemConfig: item }) => {
          const itemStartDate = moment(item.startDate);
          const itemEndDate = moment(item.endDate);
          return itemStartDate.isBefore(startDate) || itemEndDate.isAfter(endDate);
        });
        return outOfRangeItems.length > 0;
      }
    }
    return false;
  };

  const onSuccess = data => {
    setGoal(data);
    setInitialValues(data);
  };

  const onSuccessItem = items => {
    setItems(items);
  };

  const submit = async ({ values, submit }) => {
    await submit({ body: values });
  };

  const onSubmit = async ({ validateForm, ...object }) => {
    const validate = await validateForm();
    if (!Object.keys(validate).length) {
      if (hasItemsOutOfDateRange(object)) {
        confirmSaveWithItemOutOfDateRange(object);
      } else {
        await submit(object);
        return true;
      }
    }
  };

  const getComponentForm = ({ dirty, ...formikValues }) => {
    bindFormProps({ dirty, submitForm: () => onSubmit(formikValues) });
    return (
      <FormInfo
        {...formikValues}
        goalLogo={goalLogo}
        setGoalLogo={setGoalLogo}
        goalBanner={goalBanner}
        setGoalBanner={setGoalBanner}
      />
    );
  };

  const validationParamsTransform = {
    endDate: params => ({
      ...params,
      min: params.min?.toLocaleDateString(),
    }),
  };

  return (
    <div className="GeneralInfoTab">
      <Axios
        run={goal?.id}
        api={api}
        params={[`${goal?.id}/items`, { ignorePagination: true }]}
        onSuccess={onSuccessItem}
      >
        <Axios {...axios} api={api} onSuccess={onSuccess}>
          {({ submit }) => (
            <FormSubmit {...formikProps} validationParamsTransform={validationParamsTransform}>
              {formikValues => getComponentForm({ submit, ...formikValues })}
            </FormSubmit>
          )}
        </Axios>
      </Axios>
    </div>
  );
};
export default GeneralInfoTab;
