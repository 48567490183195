import React from 'react';

import { Modal, Typography, Button } from 'antd';

import Translate from '@components/Translate';

import './styles.scss';

const { Title } = Typography;

const Alert = ({ visible, onCancel, icon, title, subtitle, action }) => {
  return (
    <Modal footer={false} className="Alert" visible={visible} onCancel={onCancel} closable={false}>
      <div className="alert-header">{icon && icon()}</div>
      <div className="alert-message">
        <Title level={2}>
          <Translate>{title}</Translate>
        </Title>
        <Title level={3}>
          <Translate>{subtitle}</Translate>
        </Title>
      </div>
      <div className="alert-action">
        <Button onClick={action?.onClick} type="primary">
          {action?.text ? <Translate>{action.text}</Translate> : 'Ok'}
        </Button>
      </div>
    </Modal>
  );
};

export default Alert;
