import React from 'react';
import { Row, Col, Tooltip } from 'antd';
import { Form } from '@ant-design/compatible';

import * as icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Input from '@components/Input';
import { i18n } from '@components/Translate';

const FormComponent = ({ errors, values, handleChange }) => {
  const icon = name => {
    return <FontAwesomeIcon icon={name} size="sm" style={{ marginRight: 10 }} />;
  };

  return (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col span={24}>
          <Tooltip
            trigger={['focus']}
            placement="topLeft"
            title={i18n.t('accessControl.form.input.label.password.tooltip')}
          >
            <Input
              type="password"
              name="password"
              error={errors.password}
              value={values.password}
              onChange={handleChange}
              prefix={icon(icons.faLock)}
              label="accessControl.form.input.label.password"
              placeholder="accessControl.form.input.label.password.placeholder"
            />
          </Tooltip>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input
            type="password"
            name="confirmPassword"
            onChange={handleChange}
            error={errors.confirmPassword}
            value={values.confirmPassword}
            prefix={icon(icons.faLock)}
            label="accessControl.form.input.label.confirmPassword"
            placeholder="accessControl.form.input.label.confirmPassword.placeholder"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
