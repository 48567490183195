import React, { useState } from 'react';

import { Table, Tooltip, Typography, Row, Button } from 'antd';

import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';

import Translate from '@components/Translate';
import Axios from '@components/Axios';

import Alert from './components/Alert';

import './styles.scss';

const { Title } = Typography;

const TableList = ({
  file,
  data,
  setData,
  columns,
  title,
  subtitle,
  successMessageConfig,
  axiosConfig,
}) => {
  const [alert, setAlert] = useState();
  const [errors, setErrors] = useState();

  const verifyError = (field, index) => {
    return errors?.find(error => +error.index === index && error.field === field)?.message;
  };

  const getClassTooltip = value => {
    const className = 'batch-table-tooltip';
    if (value) {
      return `${className}-error`;
    } else {
      return className;
    }
  };

  const getColumns = () => {
    return Object.entries(columns).map(([field, title]) => ({
      title: title,
      dataIndex: title,
      className: 'batch-row-cell-table',
      render(content, _, index) {
        const message = verifyError(field, index);
        return {
          props: {
            className: message ? 'batch-cell-table' : '',
          },
          children: (
            <Tooltip className={getClassTooltip(message)} title={message}>
              {content}
            </Tooltip>
          ),
        };
      },
    }));
  };

  const onSubmit = ({ submit }) => {
    const formData = new FormData();
    formData.append('file', file);
    submit({
      body: formData,
      headers: { 'Content-type': 'multipart/form-data;' },
    });
  };

  const onSuccess = () => {
    setAlertByType('success');
  };

  const onError = ({ data, status }) => {
    if (status === 422) {
      setErrors(data);
      setAlertByType('warning');
    } else {
      setAlertByType('error');
    }
  };

  const getAlertError = () => {
    return {
      icon: () => <WarningOutlined />,
      title: 'component.csvbatchimport.alert.error.title',
      subtitle: 'component.csvbatchimport.alert.error.subtitle',
      action: {
        text: 'component.csvbatchimport.alert.error.action',
        onClick: () => setData([]),
      },
    };
  };

  const getAlertWarning = () => {
    return {
      icon: () => <WarningOutlined />,
      title: 'component.csvbatchimport.alert.warning.title',
      action: {
        text: 'component.csvbatchimport.alert.warning.action',
        onClick: () => setAlert(),
      },
    };
  };

  const getAlertSuccess = () => {
    const { title, subtitle, action } = successMessageConfig;
    return {
      icon: () => <CheckCircleOutlined />,
      title,
      subtitle,
      action,
    };
  };

  const setAlertByType = type => {
    switch (type) {
      case 'success':
        setAlert(getAlertSuccess());
        break;
      case 'warning':
        setAlert(getAlertWarning());
        break;
      case 'error':
        setAlert(getAlertError());
        break;
      default:
        setAlert();
    }
  };

  return (
    <div className="TableList">
      <Title level={3}>
        <Translate>{title}</Translate>
      </Title>
      <Title level={4}>
        <Translate>{subtitle}</Translate>
      </Title>
      <div className="table-list-body">
        <Table columns={getColumns()} dataSource={data} scroll={{ x: true }} pagination={false} />
      </div>
      <div className="table-list-footer">
        <Row justify="end">
          <Button onClick={() => setData([])}>
            <Translate>component.csvbatchimport.tablelist.action.cancel</Translate>
          </Button>
          <Axios {...axiosConfig} onError={onError} onSuccess={onSuccess}>
            {({ submit }) => (
              <Button onClick={() => onSubmit({ submit })} type="primary">
                <Translate>component.csvbatchimport.tablelist.action.confirm</Translate>
              </Button>
            )}
          </Axios>
        </Row>
      </div>
      <Alert visible={!!alert} onCancel={() => setAlert()} {...alert} />
    </div>
  );
};

export default TableList;
