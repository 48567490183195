import React, { useState } from 'react';

import PropTypes from 'prop-types';

import Input from '@components/Input';
import Axios from '@components/Axios';

const SelectDealers = ({ value, onChangeDealers }) => {
  const [dealers, setDealers] = useState([]);
  const onSuccessDealers = data => {
    setDealers(data);
  };

  return (
    <Axios api="dealers" onSuccess={onSuccessDealers} params={{ ignorePagination: true }} run>
      <Input
        type="select"
        mode="multiple"
        array={dealers}
        value={value}
        onChange={onChangeDealers}
        placeholder={'telesales.list.dealer.filter'}
        option={{
          name: 'name',
          key: 'id',
        }}
      />
    </Axios>
  );
};

SelectDealers.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChangeDealers: PropTypes.func.isRequired,
};

export default SelectDealers;
