import React from 'react';

import { Col } from 'antd';

import Input from '@components/Input';
import Axios from '@components/Axios';
import { arrayToObj } from '@util/objectUtils';

import auditLookups from './auditLookups';

const AuditLookup = ({ model, lookupValues, setLookupValues, setIdentifier, errors }) => {
  const modelLookup = auditLookups[model];
  const { lookups, getIdentifier } = modelLookup || {};

  const onChange = async (name, value, dependents) => {
    const clearDependents = arrayToObj(dependents?.map(dependent => [dependent, null]));
    const newValues = { ...lookupValues, ...clearDependents, [name]: value };
    setLookupValues(newValues);
    const identifier = await getIdentifier(newValues);
    setIdentifier(identifier);
  };

  const getRun = (model, dependsOn) => ({
    model,
    depends: arrayToObj(dependsOn?.map(depend => [depend, lookupValues[depend]])),
  });

  return !modelLookup
    ? null
    : lookups
        .filter(({ dependsOn }) => !dependsOn || dependsOn.every(depend => lookupValues[depend]))
        .map(({ lookupName, api, label, key, name, getParams, dependsOn, dependents }) => (
          <Col key={lookupName} md={6}>
            <Axios api={api} run={getRun(model, dependsOn)} params={getParams(lookupValues)}>
              {({ response }) => (
                <Input
                  type="select"
                  label={label}
                  value={lookupValues[lookupName]}
                  array={response}
                  option={{ key, name }}
                  error={errors[lookupName]}
                  onChange={({ target: { value } }) => onChange(lookupName, value, dependents)}
                />
              )}
            </Axios>
          </Col>
        ));
};

export default AuditLookup;
