import React, { useEffect, useState } from 'react';

import { Table, Empty, Card, Row, Button } from 'antd';
import get from 'lodash/get';

import { DeleteOutlined } from '@ant-design/icons';

import { sortText } from '@util/columnSorter';
import Axios from '@components/Axios';
import Input from '@components/Input';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';
import AddSalespeopleGoal from '@components/AddSalespeopleGoal';

import ExpandedRow from './components/ExpandedRow';

import './styles.scss';

const ItemSalesPeople = ({
  goalId,
  salespeople,
  dealers,
  touched,
  component,
  setTouched,
  selectDealer,
  updateScreen,
  productsSnapshot,
}) => {
  const [list, setList] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [parentId, setParentId] = useState();
  const [products, setProducts] = useState([]);
  const [deletingId, setDeletingId] = useState();
  const [addSalespeopleVisible, setAddSalespeopleVisible] = useState(false);
  const [filterDataSetSalespeople, setFilterDataSetSalespeople] = useState([]);

  const columns = [
    {
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortText(a, b, 'name'),
      title: i18n.t('goalSalespeople.salespersonName'),
    },
    {
      title: i18n.t('goalSalespeople.goal'),
      render: (_, { id, salespersonId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, salespersonId, goalValue, actualValue };
        const value = getInputValue(`${key}.goalValue`, goalValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="goalValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('goalSalespeople.done'),
      render: (_, { id, salespersonId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, salespersonId, goalValue, actualValue };
        const value = getInputValue(`${key}.actualValue`, actualValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="actualValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('tableActions'),
      render: (_, { salespersonId: id }) => (
        <Popconfirm
          title="confirmDelete"
          onSuccess={onSuccessDelete}
          visible={deletingId === id}
          onClick={() => setDeletingId(id)}
          onCancel={() => setDeletingId()}
          axios={{
            api: 'goals',
            method: 'delete',
            params: `${goalId}/salespeople/${id}/values`,
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const data = groupSalespeople(salespeople);
    setList(data);
  }, [salespeople]);

  useEffect(() => {
    includeItemInSalespeople();
  }, [list, products]);

  const groupSalespeople = salespeople => {
    const grouped = salespeople.reduce(
      (acc, { id, goalValue, actualValue, salesperson, goalItem }) => {
        let entry = acc[salesperson.id] || { ...salesperson, salespersonId: salesperson.id };
        const object = {
          id,
          goalValue,
          actualValue,
          salespersonId: salesperson.id,
        };
        if (!goalItem) {
          entry = { ...entry, ...object };
        } else {
          const goalItems = entry.goalItems || [];
          entry.goalItems = [
            ...goalItems,
            {
              ...object,
              goalItemId: goalItem.id,
              itemName: goalItem.itemName,
            },
          ];
        }
        acc[salesperson.id] = entry;
        return acc;
      },
      {},
    );
    return Object.values(grouped);
  };

  const includeItemInSalespeople = () => {
    const dataset = list.map(salesperson => {
      const { goalItems = [] } = salesperson;
      const missingProducts =
        products
          ?.filter(({ id }) => !goalItems.find(({ goalItemId }) => goalItemId === id))
          ?.map(({ id, itemName }) => ({ goalItemId: id, itemName, actualValue: 0 })) ?? [];
      return {
        ...salesperson,
        goalItems: [...goalItems, ...missingProducts],
      };
    });
    setDataset(dataset);
    setFilterDataSetSalespeople(dataset);
  };

  const onChangeValue = ({ target: { name, value, object = {} } }) => {
    if (!touched[object.key]) {
      object[name] = value;
      touched[object.key] = { ...object, goalId };
    } else {
      touched[object.key][name] = value;
    }
    setTouched({ ...touched });
  };

  const toggleAddSalespeople = () => {
    setAddSalespeopleVisible(!addSalespeopleVisible);
  };

  const onSuccessItem = items => {
    setProducts(items);
  };

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddSalespeople = () => {
    setAddSalespeopleVisible(!addSalespeopleVisible);
    updateScreen();
  };

  const getInputValue = (key, oldValue) => {
    const newValue = get(touched, key);
    if (newValue !== undefined) {
      return newValue;
    } else {
      return oldValue || 0;
    }
  };

  const onAddSalespeople = ({ body, submit }) => {
    const data = body
      .filter(item => item && !salespeople.find(sales => item?.id === sales.salespersonId))
      .map(item => {
        return {
          goalId,
          goalValue: 0,
          actualValue: 0,
          salespersonId: item.id,
        };
      });
    submit({ body: data });
  };

  const onChangeSelect = ({ target: { value } }) => {
    if (value) {
      const list = dataset.filter(({ dealerId }) => dealerId === +value);
      setFilterDataSetSalespeople(list);
    } else {
      setFilterDataSetSalespeople(dataset);
    }
    setParentId(value);
  };

  const selectDealerComponent = () => {
    return (
      <Input
        type="select"
        array={dealers}
        value={parentId}
        style={{ width: 200 }}
        onChange={onChangeSelect}
        placeholder={'goalSalespeople.selectDealer'}
        option={{
          key: 'id',
          name: 'name',
        }}
      />
    );
  };

  const getAction = () => {
    return (
      <Button onClick={toggleAddSalespeople}>{i18n.t('goalSalespeople.addSalespeople')}</Button>
    );
  };

  const getComponent = () => {
    if (!dataset.length) {
      return <Empty description={i18n.t('goalSalespeople.noSalespeopleYet')}>{getAction()}</Empty>;
    } else {
      return (
        <Card>
          <Row className="header" justify="space-between">
            <div>{selectDealer && selectDealerComponent()}</div>
            <div className="header-container">
              {component && component()}
              <div className="header-action-add">{getAction()}</div>
            </div>
          </Row>
          <Table
            columns={columns}
            pagination={false}
            rowKey={record => record.id}
            dataSource={filterDataSetSalespeople}
            expandedRowRender={items => (
              <ExpandedRow {...items} getInputValue={getInputValue} onChangeValue={onChangeValue} />
            )}
          />
        </Card>
      );
    }
  };

  return (
    <div className="ItemSalesPeople">
      <Axios
        api="goals"
        onSuccess={onSuccessItem}
        run={[list, productsSnapshot]}
        params={[`${goalId}/items`, { ignorePagination: true }]}
      >
        {getComponent()}

        <Axios
          api="goals"
          method="post"
          params={`${goalId}/salespeople`}
          onSuccess={onSuccessAddSalespeople}
        >
          {({ submit }) => (
            <AddSalespeopleGoal
              goalId={goalId}
              dealers={dealers}
              listSalesperson={salespeople}
              onCancel={toggleAddSalespeople}
              isVisible={addSalespeopleVisible}
              onAdd={body => onAddSalespeople({ body, submit })}
            />
          )}
        </Axios>
      </Axios>
    </div>
  );
};

export default ItemSalesPeople;
