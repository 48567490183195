import React, { useState } from 'react';

import { Button, Modal } from 'antd';

import Axios from '@components/Axios';
import Translate, { i18n } from '@components/Translate';
import useWindowResize from '@hooks/useWindowResize';
import { isPhone } from '@util/isMobileScreen';
import { acceptGoalTerms } from '@services/api';

import './styles.scss';

const TermsGoal = ({ open, goalId, callback, onCancel }) => {
  const [info, setInfo] = useState({ userHasAcceptedTerms: true });

  const { width } = useWindowResize();
  const IS_PHONE_SCREEN = isPhone(width);

  const onSuccess = data => {
    // If user has accept the terms, or the term is empty, calls the callback
    if (data.userHasAcceptedTerms || !data.legalTerms?.trim()) {
      callback();
    } else {
      setInfo(data);
    }
  };

  const onError = error => {
    // It's not required for a goal to have data on /info API
    if (error?.status === 404) {
      callback();
    }
  };

  const onOkModal = async () => {
    await acceptGoalTerms(goalId);
    if (callback) {
      callback();
    }
  };

  const onCancelModal = () => {
    onCancel();
  };

  const footer = () => {
    return (
      <>
        <Button onClick={onCancelModal}>
          <Translate>goal.listgoal.termsgoal.modal.action.cancel</Translate>
        </Button>
        <Button type="primary" onClick={onOkModal}>
          <Translate>goal.listgoal.termsgoal.modal.action.ok</Translate>
        </Button>
      </>
    );
  };

  return (
    <Axios
      api="goals"
      run={goalId}
      onSuccess={onSuccess}
      onError={onError}
      params={[`${goalId}/info`, { include: 'userAcceptance' }]}
    >
      <Modal
        visible={open && !info.userHasAcceptedTerms}
        zIndex={100}
        className="TermsGoal"
        footer={footer()}
        onCancel={onCancel}
        width={!IS_PHONE_SCREEN ? '35vw' : null}
        title={i18n.t('goal.listgoal.termsgoal.modal.title')}
      >
        <pre style={{ fontFamily: 'Roboto', whiteSpace: 'pre-wrap' }}>{info.legalTerms}</pre>
      </Modal>
    </Axios>
  );
};

export default TermsGoal;
