import React, { memo } from 'react';

import clsx from 'clsx';
import { Input } from 'antd';

import './styles.scss';

const InputNumberComponent = ({
  value,
  error,
  name,
  prefix,
  className,
  handleChange = () => {},
}) => {
  const onChange = ({ target: { value } }) => {
    const event = {
      target: {
        name,
        value: value || null,
      },
    };
    handleChange(event);
  };

  const onKeyDown = event => {
    if (event.key === '.') {
      event.preventDefault();
      return false;
    }
    return true;
  };

  const styles = clsx(['InputNumber', className], { error });

  return (
    <Input
      type="number"
      value={value}
      prefix={prefix}
      className={styles}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
  );
};

export default memo(InputNumberComponent);
