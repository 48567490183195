import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';

import routes from '@routes/routes';
import { i18n } from '@components/Translate';

import CustomBreadcrumb from './components/CustomBreadcrumb';

const { Title } = Typography;

export const PageHeader = ({ history }) => {
  useEffect(() => {
    verifyRouter({ ...history.location });
  }, [history.location]);

  const [title, setTitle] = useState('');
  const [paths, setPaths] = useState([]);

  const verifyRouter = ({ pathname }) => {
    const path = pathname.replace(/[0-9]+/g, ':id');
    const index = paths.findIndex(item => item.path === path);
    const labelPath = path.replace(/\//g, '');
    const { title, root, label = labelPath } = routes.find(item => item.path === path) || {};
    if (index < 0) {
      if (root) {
        setPaths([{ label, path, pathname }]);
      } else {
        setPaths([...paths, { label, path, pathname }]);
      }
    } else {
      if (paths.length !== 1) {
        setPaths(paths.slice(0, index + 1));
      }
    }
    setTitle(title);
  };

  return (
    <div className="app-page-header">
      <Title level={2} className="mb-0 mr-3">
        {i18n.t(title)}
      </Title>
      <CustomBreadcrumb paths={paths} history={history} />
    </div>
  );
};

export default PageHeader;
