import React from 'react';
import { Row, Col } from 'antd';

import BoxItem from '@components/BoxItem';
import { formatMoney } from '@util/global';

import './styles.scss';

const ListProduct = ({ items, products }) => {
  const getRanking = value => {
    return value && `${value}°`;
  };

  const getGoal = value => {
    return value && formatMoney(value);
  };

  const getComponent = item => {
    const object = products.find(product => product.goalItemId === item.id);
    const { actualValue, ranking } = object || {};
    return (
      <div className="ListProduct">
        <Row gutter={20} className="item-header">
          <Col md={24} className="text">
            {item.itemName}
          </Col>
        </Row>
        <Row gutter={20} className="item-listproduct">
          <Col md={12}>
            <div className="row-content">
              <BoxItem title="dashboard.telesales.product.list.realized">
                <div className="row-content-number">{getGoal(actualValue) || '-'}</div>
              </BoxItem>
            </div>
          </Col>
          <Col md={12}>
            <div className="row-content">
              <BoxItem title="dashboard.telesales.product.list.ranking">
                <div className="row-content-number">{getRanking(ranking) || '-'}</div>
              </BoxItem>
            </div>
          </Col>
        </Row>
      </div>
    );
  };

  return <div className="ListProduct">{items.map(item => getComponent(item))}</div>;
};

export default ListProduct;
