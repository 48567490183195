import React from 'react';

import { Formik } from 'formik';

import { i18n } from '@components/Translate';

export default ({
  validationSchema,
  validationParamsTransform,
  initialValues,
  onSubmit,
  children,
}) => {
  const validate = async values => {
    try {
      await validationSchema?.validate(values, { abortEarly: false });
      return {};
    } catch (error) {
      return error.inner.reduce((acc, { message, params }) => {
        const transformedParams = validationParamsTransform?.[params.path]
          ? validationParamsTransform[params.path](params)
          : params;
        acc[params.path] = i18n.t(message, transformedParams);
        return acc;
      }, {});
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      validate={validate}
      initialValues={initialValues}
      onSubmit={onSubmit}
    >
      {children}
    </Formik>
  );
};
