import React, { memo } from 'react';

import clsx from 'clsx';
import { Select } from 'antd';

import { i18n } from '@components/Translate';
import { get } from '@util/objectUtils';

import './styles.scss';

const { Option } = Select;

const SelectComponet = ({
  value,
  error,
  name,
  style,
  mode,
  loading,
  disabled,
  array = [],
  option = {},
  defaultValue,
  placeholder,
  translateOptions = false,
  handleChange = () => {},
}) => {
  const onChange = value => {
    if (!value) {
      value = null;
    }
    const event = {
      target: {
        name,
        value: value,
      },
    };
    handleChange(event);
  };

  const styles = clsx('Select', { error });
  array = Array.isArray(array) ? array : [];

  const options = array.map(item => (
    <Option key={get(item, option.key)}>
      {translateOptions ? i18n.t(get(item, option.name)) : get(item, option.name)}
    </Option>
  ));

  return (
    <Select
      allowClear
      style={style}
      error={error}
      mode={mode}
      loading={loading}
      className={styles}
      disabled={disabled}
      placeholder={placeholder}
      value={value || undefined}
      defaultValue={defaultValue}
      onChange={onChange}
    >
      {options}
    </Select>
  );
};

export default memo(SelectComponet);
