import React, { useState } from 'react';

import { Typography, Table, Input, Button } from 'antd';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import { debounce } from '@util/debounce';

import './styles.scss';

const TableSearch = ({
  axios,
  rowId,
  title,
  columns,
  bordered,
  getSearch,
  component,
  actionProps,
  dataSource,
  filterResponse,
  ignorePagination,
  extraTableProperties,
}) => {
  const [run, setRun] = useState(0);
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState({});

  const onSuccess = data => {
    let list = data?.items;
    if (!ignorePagination) {
      const { pageSize, page, count } = data;
      setPagination({ pageSize, current: page + 1, total: count, page });
    } else {
      list = data;
    }
    if (!filterResponse) {
      setItems(list);
    } else {
      setItems(filterResponse(list));
    }
  };

  const onChangePage = ({ current, pageSize }) => {
    pagination.page = current - 1;
    setPagination({ ...pagination, current, pageSize });
    setRun(run => run + 1);
  };

  const onSearch = value => {
    setSearch(value);
    pagination.current = 1;
    setRun(run => run + 1);
    setPagination({ ...pagination });
    if (getSearch) {
      getSearch(value);
    }
  };

  const getCustomParams = (params = {}) => {
    if (!ignorePagination) {
      const { pageSize, current } = pagination;
      const page = current - 1;
      return { ...params, search, pageSize, page };
    } else {
      return { ...params, search };
    }
  };

  const getParans = () => {
    if (axios) {
      const { params } = axios;
      if (Array.isArray(params)) {
        const [id, others] = params;
        return [id, getCustomParams(others)];
      } else {
        return getCustomParams(params);
      }
    }
  };

  const getRun = () => {
    if (axios) {
      if (run) {
        return { run, axios: axios.run };
      } else {
        return axios.run;
      }
    }
  };

  const getActions = () => {
    const actions = [];
    if (actionProps && !Array.isArray(actionProps)) {
      actions.push(actionProps);
    } else if (Array.isArray(actionProps)) {
      actions.push(...actionProps);
    }
    return actions.map((props, index) => (
      <Button key={index} type="primary" {...props} title={i18n.t(props.title)}>
        {i18n.t(props.title)}
      </Button>
    ));
  };

  return (
    <div className="TableSearch">
      <Axios {...axios} run={getRun()} params={getParans()} onSuccess={onSuccess}>
        <div className="table-header">
          <div className="title">
            {title && <Typography.Title level={4}>{i18n.t(title)}</Typography.Title>}
          </div>
          <div className="table-header-body">
            <div className="component">{component && component()}</div>
            <div className="search">
              <Input.Search
                onSearch={onSearch}
                placeholder={i18n.t('search')}
                className="default-header-search"
                onChange={debounce(onSearch, ({ target }) => target.value)}
              />
            </div>
            <div className="action">{getActions()}</div>
          </div>
        </div>
        <Table
          columns={columns}
          bordered={bordered}
          onChange={onChangePage}
          rowKey={record => record[rowId]}
          dataSource={dataSource || items}
          locale={{ emptyText: i18n.t('tableSearch.emptyText') }}
          pagination={!ignorePagination ? { ...pagination } : false}
          {...extraTableProperties}
        />
      </Axios>
    </div>
  );
};

export default TableSearch;
