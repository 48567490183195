import React, { useState } from 'react';

import { Empty } from 'antd';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import { isAdmin, isTSR } from '@util/user.utils';

import CardGoal from './components/CardGoal';
import TermsGoal from './components/TermsGoal';

import './styles.scss';

const TabList = ({ run, user, status, search, history, params = {} }) => {
  const [array, setArray] = useState([]);
  const [terms, setTerms] = useState({ open: false });

  const goTo = path => {
    history.push(path);
  };

  const toggleOpenTerms = () => {
    const { open } = terms;
    terms.open = !open;
    setTerms({ ...terms });
  };

  const termsByRule = (goalId, callback) => () => {
    if (!isAdmin(user) && !isTSR(user)) {
      terms.open = true;
      terms.goalId = goalId;
      terms.callback = callback;
      setTerms({ ...terms });
    } else {
      callback();
    }
  };

  const onSuccess = data => {
    setArray(data);
  };

  const getComponetByList = () => {
    if (array.length) {
      return (
        <div className="list-goals-main">
          {array.map(item => (
            <CardGoal key={item.id} item={item} goTo={goTo} termsByRule={termsByRule} />
          ))}
        </div>
      );
    } else {
      return (
        <div className="list-goals-empty">
          <Empty description={<span>{i18n.t('tabList.emptyText')}</span>} />
        </div>
      );
    }
  };

  return (
    <div className="TabList">
      <Axios
        run={run}
        api="goals"
        onSuccess={onSuccess}
        params={{ ...params, search, status, ignorePagination: true }}
      >
        {getComponetByList()}
      </Axios>
      <TermsGoal {...terms} onCancel={toggleOpenTerms} />
    </div>
  );
};

export default TabList;
