import React from 'react';

import { InboxOutlined, FileOutlined } from '@ant-design/icons';

import Translate from '@components/Translate';

import './styles.scss';

const Update = ({ file, setFile }) => {
  const handleChange = ({ target: { files } }) => {
    setFile(files[0]);
  };

  const getIcon = () => {
    if (file) {
      return <FileOutlined />;
    } else {
      return <InboxOutlined />;
    }
  };

  const getText = () => {
    return file ? (
      file.name
    ) : (
      <Translate>component.csvbatchimport.upload.dragger.description</Translate>
    );
  };

  return (
    <div className="Update">
      <label className="update-label">
        <p className="ant-upload-drag-icon">{getIcon()}</p>
        <p className="ant-upload-text">{getText()}</p>
        <input className="input-file" onChange={handleChange} accept=".csv" type="file" />
      </label>
    </div>
  );
};

export default Update;
