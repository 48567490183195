import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import { Upload, Button, Col } from 'antd';
import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';

import { uploadAttachment, retrieveSignedAttachment } from 'services/api';

const FILENAME_MAX_LENGTH = 60;

export const GuideUpload = ({
  attachment,
  setAttachment,
  setFieldValue,
  fieldName,
  label,
  rowSpan,
}) => {
  const [t] = useTranslation();
  const [errors, setErrors] = useState({});
  const [isUploading, setIsUploading] = useState(false);

  const getAttachmentAsFileProps = attachment => ({
    uid: attachment.id,
    name: attachment.caption,
    status: 'done',
    url: attachment.signedUrl,
  });

  const uploadFile = async ({ file, onSuccess }) => {
    try {
      setIsUploading(true);
      const formData = new FormData();
      formData.append('file', file);
      const { id } = await uploadAttachment(formData);
      const attachment = await retrieveSignedAttachment(id);
      setFieldValue(fieldName, id);
      setAttachment(attachment);
      onSuccess(getAttachmentAsFileProps(attachment));
    } finally {
      setIsUploading(false);
    }
  };

  const deleteAttachment = async () => {
    // await removeAttachment(attachment.id);
    setFieldValue(fieldName, null);
    setAttachment(null);
    return true;
  };

  const beforeUpload = file => {
    if (file.name.length > FILENAME_MAX_LENGTH) {
      setErrors({ [fieldName]: t('uploadFile.fileNameMaxLenght', { max: FILENAME_MAX_LENGTH }) });
      return false;
    }
    setErrors({});
    return true;
  };

  const getFileList = () => (attachment ? [getAttachmentAsFileProps(attachment)] : []);

  return (
    <Form.Item
      label={label}
      validateStatus={errors[fieldName] ? 'error' : 'validating'}
      help={errors[fieldName]}
    >
      <Col className="gutter-row" span={rowSpan}>
        <Upload
          fileList={getFileList()}
          accept="application/pdf"
          multiple={false}
          customRequest={uploadFile}
          beforeUpload={beforeUpload}
          onRemove={deleteAttachment}
        >
          <Button
            disabled={attachment}
            icon={isUploading ? <LoadingOutlined /> : <UploadOutlined />}
          >
            Upload
          </Button>
        </Upload>
      </Col>
    </Form.Item>
  );
};
