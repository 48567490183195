import React from 'react';
import { Row, Col, Typography } from 'antd';

import Translate from '@components/Translate';

import './styles.scss';

const { Title } = Typography;

const Footer = () => {
  const invalidMessage = [
    'updateBatch.tableList.message.invalid.one',
    'updateBatch.tableList.message.invalid.thow',
    'updateBatch.tableList.message.invalid.tree',
  ];

  const suggestionMessage = [
    'updateBatch.tableList.message.suggestion.one',
    'updateBatch.tableList.message.suggestion.thow',
    'updateBatch.tableList.message.suggestion.tree',
    'updateBatch.tableList.message.suggestion.four',
    'updateBatch.tableList.message.suggestion.five',
  ];

  return (
    <div className="Footer">
      <Row>
        <Col span={12}>
          <Title level={3}>
            <Translate>updateBatch.tableList.message.invalid.title</Translate>
          </Title>
          <ul>
            {invalidMessage.map(item => (
              <li key={item}>
                <Translate>{item}</Translate>
              </li>
            ))}
          </ul>
        </Col>
        <Col span={12}>
          <Title level={3}>
            <Translate>updateBatch.tableList.message.suggestion.title</Translate>
          </Title>
          <ul>
            {suggestionMessage.map(item => (
              <li key={item}>
                <Translate>{item}</Translate>
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
};

export default Footer;
