import React from 'react';
import { Tag } from 'antd';

import clsx from 'clsx';

import Translate from '@components/Translate';

import './styles.scss';

const TagComponent = props => {
  const { color, className } = props;
  let styles = clsx(['TagComponent', className]);
  return (
    <Tag color={color} className={styles}>
      <Translate>{props.children}</Translate>
    </Tag>
  );
};

export default TagComponent;
