export const documentTypes = [
  { value: 'CPF', description: 'CPF', mask: '111.111.111-11' },
  { value: 'CNPJ', description: 'CNPJ', mask: '11.111.111/1111-11' },
];

export const goalStatusMap = {
  draft: 'DRAFT',
  inProgress: 'IN_PROGRESS',
  inVerification: 'IN_VERIFICATION',
  completed: 'COMPLETED',
};

export const goalStatus = Object.values(goalStatusMap);

export const memberTypes = ['ADM', 'TSR'];

export const parameters = {
  whatsapp: 'whatsapp_help',
};
