import moment from 'moment';

import { i18n } from '@components/Translate';
import { formatCnpj, formatCpf } from '@util/global';

export const auditOperations = [
  {
    id: 'I',
    value: 'audit.list.operation.insert',
    color: 'green',
  },
  {
    id: 'U',
    value: 'audit.list.operation.update',
    color: 'blue',
  },
  {
    id: 'D',
    value: 'audit.list.operation.delete',
    color: 'red',
  },
];

export const auditModels = [
  {
    id: 'Member',
    value: 'audit.list.model.Member',
  },
  {
    id: 'Dealer',
    value: 'audit.list.model.Dealer',
  },
  {
    id: 'Salesperson',
    value: 'audit.list.model.Salesperson',
  },
  {
    id: 'Goal',
    value: 'audit.list.model.Goal',
  },
  {
    id: 'GoalDealerValue',
    value: 'audit.list.model.GoalDealerValue',
  },
  {
    id: 'GoalSalespersonValue',
    value: 'audit.list.model.GoalSalespersonValue',
  },
];

const booleanTransform = value => {
  if (typeof value === 'boolean') {
    return value ? i18n.t('yes') : i18n.t('not');
  }
};

const dateTransform = value => (value ? moment(value).format('DD/MM/YYYY') : value);

export const detailFields = {
  Member: [
    { name: 'memberName' },
    { name: 'emailAddress' },
    { name: 'active', transformation: booleanTransform },
    { name: 'memberType' },
  ],
  Dealer: [
    { name: 'name' },
    { name: 'email' },
    { name: 'documentNumber', transformation: value => (value ? formatCnpj(value) : value) },
    { name: 'phone' },
    { name: 'cityStateId' },
    { name: 'parentMemberId' },
    { name: 'active', transformation: booleanTransform },
  ],
  Salesperson: [
    { name: 'name' },
    { name: 'email' },
    { name: 'documentNumber', transformation: value => (value ? formatCpf(value) : value) },
    { name: 'phone' },
    { name: 'dealerId' },
    { name: 'active', transformation: booleanTransform },
  ],
  Goal: [
    { name: 'goalName' },
    { name: 'goalColor' },
    { name: 'startDate', transformation: dateTransform },
    { name: 'endDate', transformation: dateTransform },
    {
      name: 'goalStatus',
      transformation: value => (value ? i18n.t(`goalStatuses.${value}`) : value),
    },
  ],
  GoalDealerValue: [
    { name: 'goalId' },
    { name: 'dealerId' },
    { name: 'goalValue' },
    { name: 'actualValue' },
  ],
  GoalSalespersonValue: [
    { name: 'goalId' },
    { name: 'salespersonId' },
    { name: 'goalValue' },
    { name: 'actualValue' },
    { name: 'goalItemId' },
  ],
};
