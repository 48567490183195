import axios from 'axios';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 90000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache, no-store, must-revalidate',
    Pragma: 'no-cache',
  },
});

const apiDownload = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'arraybuffer',
  timeout: 900000,
});

export function getApiUrl() {
  return process.env.REACT_APP_API_URL;
}

export function getToken() {
  //TODO temp solution before refactor to use Axios component
  return localStorage.getItem('pcam-token');
}

export function getLanguage() {
  return localStorage.getItem('i18nextLng');
}

api.interceptors.request.use(async config => {
  try {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const language = getLanguage();
    config.params = { ...config.params, lng: language || 'pt' };
    return config;
  } catch (err) {
    console.tron.log(err);
  }
});

apiDownload.interceptors.request.use(async config => {
  try {
    const token = getToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  } catch (err) {
    console.tron.log(err);
  }
});

export function auth(data) {
  return api.post('/auth/login', data).then(result => result.data);
}

export function listCustomers(data) {
  return api
    .get('/customers', {
      params: {
        ...data,
      },
    })
    .then(result => result.data);
}

export async function retrieveSignedAttachment(id) {
  return api.get(`attachments/${id}/signed`).then(({ data }) => data);
}

export async function uploadAttachment(file) {
  return api.post('/attachments', file).then(result => result.data);
}

export function acceptLgpd(id) {
  return api.post(`/users/${id}/accept-lgpd`).then(result => result.data);
}

export function getNotifications(id, prop) {
  return api
    .get(
      `/notifications${id ? `/${id}` : ''}?device=desktop&include=member${
        prop !== null ? `&page=${prop.page}` : ''
      }`,
    )
    .then(result => result.data);
}

export async function listGoals(params) {
  return api.get('/goals', { params }).then(res => res.data);
}

export async function listGoalDealers(goalId, params) {
  return api.get(`/goals/${goalId}/dealers`, { params }).then(res => res.data);
}

export async function saveGoalDealers(goalId, data) {
  return api.post(`/goals/${goalId}/dealers`, data).then(res => res.data);
}

export async function deleteGoalDealer(goalId, dealerId) {
  return api.delete(`/goals/${goalId}/dealers/${dealerId}/values`).then(res => res.data);
}

export async function updateGoalDealerValue(goalId, memberId, goalMemberId, data) {
  return api
    .put(`/goals/${goalId}/dealers/${memberId}/values/${goalMemberId}`, data)
    .then(res => res.data);
}

export async function listGoalSalespeople(goalId, params) {
  return api.get(`/goals/${goalId}/salespeople`, { params }).then(res => res.data);
}

export async function saveGoalSalespeople(goalId, data) {
  return api.post(`/goals/${goalId}/salespeople`, data).then(res => res.data);
}

export async function deleteGoalSalesperson(goalId, salespersonId) {
  return api.delete(`/goals/${goalId}/salespeople/${salespersonId}/values`);
}

export async function updateGoalSalespersonValue(goalId, salespersonId, id, data) {
  return api
    .put(`/goals/${goalId}/salespeople/${salespersonId}/values/${id}`, data)
    .then(res => res.data);
}

export async function getGoalSalespersonValues(goalId, salespersonId, params) {
  return api
    .get(`/goals/${goalId}/salespeople/${salespersonId}/values`, { params })
    .then(res => res.data);
}

export async function getGoal(goalId) {
  return api.get(`/goals/${goalId}`).then(res => res.data);
}

export async function createGoal(data) {
  return api.post('/goals/', data).then(res => res.data);
}

export async function updateGoal(goalId, data) {
  return api.put(`/goals/${goalId}`, data).then(res => res.data);
}

export async function listGoalItems(goalId, params) {
  return api.get(`/goals/${goalId}/items`, { params }).then(res => res.data);
}

export async function getGoalItem(goalId, goalItemId) {
  return api.get(`/goals/${goalId}/items/${goalItemId}`).then(res => res.data);
}

export async function removeGoalItem(goalId, goalItemId) {
  return api.delete(`/goals/${goalId}/items/${goalItemId}`).then(res => res.data);
}

export async function createGoalItem(goalId, goalItem) {
  return api.post(`/goals/${goalId}/items`, goalItem).then(res => res.data);
}

export async function updateGoalItem(goalId, goalItemId, data) {
  return api.put(`/goals/${goalId}/items/${goalItemId}`, data).then(res => res.data);
}

export async function getGoalInfo(goalId) {
  return api.get(`/goals/${goalId}/info`).then(res => res.data);
}

export async function createGoalInfo(goalId, data) {
  return api.post(`/goals/${goalId}/info`, data).then(res => res.data);
}

export async function updateGoalInfo(goalId, data) {
  return api.put(`/goals/${goalId}/info`, data).then(res => res.data);
}

export async function getItemsName() {
  return api.get('/goal-items?select=id,itemName&ignorePagination=true').then(res => res.data);
}

export async function listDealers(params) {
  return api.get('/dealers', { params }).then(res => res.data);
}

export async function listSalespeople(params) {
  return api.get('/salespeople', { params }).then(res => res.data);
}

export async function passwordRecovery({ emailAddress, emailType, domain, portalAccessControl }) {
  return api
    .post('/auth/password-recovery', {
      userLogin: emailAddress,
      emailType,
      domain,
      portalAccessControl,
    })
    .then(res => res.data);
}

export async function resetPassword(values) {
  return api.post('/auth/reset-password', values).then(res => res.data);
}

export async function getParameter(parameterName) {
  return api.get(`/parameters/${parameterName}`).then(res => res.data);
}

export async function acceptGoalTerms(goalId) {
  return api.post(`/goals/${goalId}/accept-terms`);
}
