import React from 'react';

import { useUserContext } from '@context/UserContext';
import { TSR, SALES, ADMIN, DEALER } from '@constants/profiles';

import UpdateDealer from './components/UpdateDealer';
import UpdateMember from './components/UpdateMember';
import UpdateSalespeople from './components/UpdateSalespeople';

const EditProfile = () => {
  const { user } = useUserContext();
  const { profile, relationId } = user;

  return (
    <>
      {(profile === TSR || profile === ADMIN) && <UpdateMember id={relationId} api="members" />}
      {profile === DEALER && <UpdateDealer id={relationId} api="dealers" />}
      {profile === SALES && <UpdateSalespeople id={relationId} api="salespeople" />}
    </>
  );
};

export default EditProfile;
