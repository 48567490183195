import { loadingBarReducer } from 'react-redux-loading-bar';

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Settings from './Settings';
import loading from '../ducks/loading';
import alert from '../ducks/alert';
import auth from '../ducks/auth/auth';
import lng from '../ducks/languageSettings/lng';
import passwordRecovery from '../ducks/auth/passwordRecovery';
import resetPassword from '../ducks/auth/resetPassword';

const createRootReducer = history =>
  combineReducers({
    lng,
    auth,
    alert,
    loading,
    resetPassword,
    passwordRecovery,
    settings: Settings,
    router: connectRouter(history),
    loadingBar: loadingBarReducer,
  });
export default createRootReducer;
