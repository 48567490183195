import React, { useState, useEffect } from 'react';

import { Tabs, Card, Button, Row, Col, message } from 'antd';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';

import {
  GeneralInfoTab,
  DealerTab,
  ProductTab,
  InfoTab,
  SalespeopleTab,
  ManagerTab,
  TelesalesTab,
} from './components/Tabs';

import './styles.scss';

const { TabPane } = Tabs;

const GoalForm = ({ match, history }) => {
  const [goal, setGoal] = useState();
  const [goalId, setGoalId] = useState();
  const [activeKey, setActiveKey] = useState(0);
  const [tabSnapshot, setTabSnapshot] = useState({});

  useEffect(() => {
    setGoalId(match.params.id);
  }, [match.params.id]);

  const keys = {
    generalInfo: 0,
    products: 1,
    dealers: 2,
    salespeople: 3,
    info: 4,
    manager: 5,
    telesales: 6,
  };

  const FIRST_TAB = keys.generalInfo;
  const LAST_TAB = keys.info;

  const formProps = {};

  const bindFormProps = (key, props) => (formProps[key] = props);

  const incrementDealerTabSnapshot = () => {
    const currentSnapshot = tabSnapshot[keys.dealers] || 0;
    setTabSnapshot({ ...tabSnapshot, [keys.dealers]: currentSnapshot + 1 });
  };

  const submit = async (showMessageOnCleanForm = false) => {
    const { dirty, submitForm } = formProps[activeKey];
    if (goal && !dirty) {
      if (showMessageOnCleanForm) {
        showSuccessMessage();
      }
      return true;
    }
    try {
      const submitted = !!(await submitForm());
      if (submitted) {
        showSuccessMessage();
        const snapshot = (tabSnapshot[activeKey] || 0) + 1;
        setTabSnapshot({ ...tabSnapshot, [activeKey]: snapshot });
      }
      return submitted;
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const showSuccessMessage = () => message.info(i18n.t('goalTabs.successMessage'));

  const backToList = () => history.push('/goal/list');

  const onTabChange = async currentActiveKey => {
    if (await submit()) {
      setActiveKey(+currentActiveKey);
    }
  };

  const nextTab = async () => {
    if (activeKey < LAST_TAB) {
      onTabChange(activeKey + 1);
    } else if (await submit(true)) {
      backToList();
    }
  };

  const previousTab = () => {
    onTabChange(activeKey - 1);
  };

  const onSaveClick = async () => await submit(true);

  const tabs = [
    {
      title: 'goalTabs.generalInfo',
      component: index => (
        <GeneralInfoTab
          goal={goal}
          setGoal={setGoal}
          bindFormProps={props => bindFormProps(index, props)}
        />
      ),
    },
    {
      title: 'goalTabs.focusProduct',
      component: index => (
        <ProductTab goal={goal} bindFormProps={props => bindFormProps(index, props)} />
      ),
    },
    {
      title: 'goalTabs.dealers',
      component: index => (
        <DealerTab
          goalId={goal?.id}
          bindFormProps={props => bindFormProps(index, props)}
          incrementDealerTabSnapshot={incrementDealerTabSnapshot}
        />
      ),
    },
    {
      title: 'goalTabs.salespeople',
      component: index => (
        <SalespeopleTab
          goalId={goal?.id}
          dealersSnapshot={tabSnapshot[keys.dealers]}
          productsSnapshot={tabSnapshot[keys.products]}
          bindFormProps={props => bindFormProps(index, props)}
        />
      ),
    },
    {
      title: 'goalTabs.manager',
      component: index => (
        <ManagerTab
          goalId={goal?.id}
          dealersSnapshot={tabSnapshot[keys.manager]}
          productsSnapshot={tabSnapshot[keys.products]}
          bindFormProps={props => bindFormProps(index, props)}
        />
      ),
    },
    {
      title: 'goalTabs.telesales',
      component: index => (
        <TelesalesTab
          goalId={goal?.id}
          dealersSnapshot={tabSnapshot[keys.telesales]}
          productsSnapshot={tabSnapshot[keys.products]}
          bindFormProps={props => bindFormProps(index, props)}
        />
      ),
    },
    {
      title: 'goalTabs.otherInfo',
      component: index => (
        <InfoTab goalId={goal?.id} bindFormProps={props => bindFormProps(index, props)} />
      ),
    },
  ];

  return (
    <div className="GoalForm">
      <Axios api="goals" run={goalId} params={goalId} onSuccess={setGoal}>
        <Card>
          <Row justify="end">
            <Col>
              <Button className="actions" onClick={backToList}>
                {i18n.t('exit')}
              </Button>
              <Button className="actions" onClick={onSaveClick} type="primary">
                {i18n.t('register')}
              </Button>
            </Col>
          </Row>
          <Tabs defaultActiveKey="0" onChange={onTabChange} activeKey={activeKey.toString()}>
            {tabs.map((item, index) => (
              <TabPane tab={i18n.t(item.title)} key={index}>
                {activeKey.toString() === index.toString() && item.component(index)}
              </TabPane>
            ))}
          </Tabs>
        </Card>
        <Row justify="end">
          <Col>
            <Button className="tabs" onClick={previousTab} disabled={activeKey === FIRST_TAB}>
              {i18n.t('goalTabs.prev')}
            </Button>
            <Button
              className="tabs"
              onClick={nextTab}
              type={activeKey === LAST_TAB ? 'primary' : 'default'}
            >
              {activeKey === LAST_TAB ? i18n.t('goalTabs.finalize') : i18n.t('goalTabs.next')}
            </Button>
          </Col>
        </Row>
      </Axios>
    </div>
  );
};

export default GoalForm;
