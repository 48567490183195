import React, { useState, useEffect } from 'react';

import { Modal, Button, Tag } from 'antd';

import Input from '@components/Input';
import { formatCpf } from '@util/global';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { isPhone } from '@util/isMobileScreen';
import useWindowResize from '@hooks/useWindowResize';

const AddManager = ({
  isVisible,
  listManager,
  dealers,
  goalId,
  onAdd,
  onCancel,
  dealerIdField = 'id',
  dealerNameField = 'name',
}) => {
  const [parentId, setParentId] = useState();
  const [manager, setManager] = useState({ data: [], keys: [] });

  const { width } = useWindowResize();
  const IS_PHONE_SCREEN = isPhone(width);

  useEffect(() => {
    const data = [];
    const keys = [];
    for (const { manager } of listManager) {
      if (manager) {
        data.push(manager);
        keys.push(manager.id);
      }
    }
    setManager({ data, keys });
  }, [listManager]);

  const columns = [
    {
      title: i18n.t('status'),
      dataIndex: 'active',
      render: active => {
        const [tagColor, tagLabel] = active ? ['green', 'active'] : ['volcano', 'inactive'];
        return <Tag color={tagColor}>{i18n.t(tagLabel)}</Tag>;
      },
    },
    {
      title: i18n.t('name'),
      dataIndex: 'name',
    },
    {
      title: i18n.t('documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { documentNumber }) => {
        return formatCpf(documentNumber);
      },
    },
  ];

  const onAddClick = () => {
    const { data = [] } = manager;
    const list = data.filter(item => !listManager?.find(({ managerId }) => managerId === item?.id));
    onAdd(list);
  };

  const onChangeSelect = ({ target: { value } }) => {
    setParentId(value);
  };

  const footerComponent = () => {
    return (
      <Button type="primary" onClick={onAddClick}>
        {i18n.t('goalManager.addSelected')}
      </Button>
    );
  };

  const axios = {
    run: { parentId },
    api: 'managers',
    params: { parentId, goalId },
  };

  const rowSelection = {
    preserveSelectedRowKeys: true,
    selectedRowKeys: [...manager.keys],
    onChange: (keys, data) => setManager({ data, keys }),
    getCheckboxProps: ({ id }) => ({
      disabled: !!listManager?.find(({ managerId }) => managerId === id),
    }),
  };

  return (
    <Modal
      destroyOnClose
      visible={isVisible}
      onCancel={onCancel}
      footer={footerComponent()}
      width={!IS_PHONE_SCREEN ? '75vw' : null}
      title={i18n.t('goalManager.addManager')}
    >
      <Input
        type="select"
        array={dealers}
        value={parentId}
        style={{ width: 300 }}
        onChange={onChangeSelect}
        placeholder={'goalManager.selectDealer'}
        option={{
          name: dealerNameField,
          key: dealerIdField,
        }}
      />
      <TableSearch
        rowId="id"
        axios={axios}
        columns={columns}
        title="addManager.title"
        extraTableProperties={{ rowSelection }}
      />
    </Modal>
  );
};

export default AddManager;
