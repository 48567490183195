import React, { useState } from 'react';
import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';

import Axios from '@components/Axios';
import { useUserContext } from '@context/UserContext';
import CustomModal from '@components/CustomModal/CustomModal';

import './styles.scss';

const Lgpd = function ({ history }) {
  const { t } = useTranslation();
  const { user, setUser, logout } = useUserContext();

  const [accept, setAccept] = useState(false);

  function rejectLgpd() {
    if (!user?.lgpd) {
      logout();
    }
  }

  function onAcceptedSuccessfully() {
    setUser({ ...user, lgpd: true });
    history.push('/goal/list');
  }

  function lgpdModal({ submit }) {
    return (
      <CustomModal
        onSubmit={submit}
        visible={!user?.lgpd}
        onCancel={rejectLgpd}
        disableOkButton={!accept}
        okButtonTitle={t('lgpdAccept')}
        cancelButtonTitle={t('lgpdNotAccept')}
        title="Termo de uso e Consentimento para Tratamento de Dados Pessoais"
        useBottomButtons={true}
      >
        <p>
          Aceitando este documento, o CONTROLADOR visa registrar a manifestação livre, informada e
          inequívoca pela qual concorda com o tratamento de seus dados pessoais para finalidade
          específica, em conformidade com a Lei nº 13.709 – Lei Geral de Proteção de Dados Pessoais
          (LGPD), consentindo que a empresa MAXXIDATA INFORMATICA LTDA, pessoa jurídica de direito
          privado, inscrita no CNPJ nº 28.336.820/0001-78, com sede na AVENIDA PAULISTA, 2202, ANDAR
          6 CONJ A1 - A9, Bela Vista, São Paulo - SP e a empresa Bayer, pessoa jurídica de direito
          privado, inscrita no CNPJ nº 18.459.628-0043-74, com sede na Rua Domingos Jorge 1100,
          Socorro, São Paulo - SP, tome decisões referentes ao tratamento de seus dados pessoais,
          bem como realize o tratamento de seus dados pessoais, envolvendo operações como as que se
          referem a coleta, produção, recepção, classificação, utilização, acesso, reprodução,
          transmissão, distribuição, processamento, arquivamento, armazenamento, eliminação,
          avaliação ou controle da informação, modificação, comunicação, transferência, difusão ou
          extração.
        </p>

        <h4>
          Caso não concorde com as cláusulas deste termo de aceite ou com as notas desta versão, não
          preencha seus dados no formulário ou dê prosseguimento neste site.
        </h4>

        <h4>Dados Pessoais</h4>

        <p>
          O Controlador fica autorizado a tomar decisões referentes ao tratamento e a realizar o
          tratamento dos seguintes dados pessoais do Titular:
        </p>

        <ul>
          <li>Nome completo.</li>
          <li>Data de nascimento.</li>
          <li>Número e imagem da Carteira de Identidade (RG).</li>
          <li>Número e imagem do Cadastro de Pessoas Físicas (CPF).</li>
          <li>Número e imagem da Carteira Nacional de Habilitação (CNH).</li>
          <li>Fotografia 3x4.</li>
          <li>Estado civil.</li>
          <li>Nível de instrução ou escolaridade.</li>
          <li>Endereço completo.</li>
          <li>Números de telefone, WhatsApp e endereços de e-mail.</li>
          <li>Nome de usuário e senha específicos para uso dos serviços do Controlador.</li>
          <li>Comunicação, verbal e escrita, mantida entre o Titular e o Controlador.</li>
          <li>Dados profissionais, como nome da empresa, CNPJ, cargo, e-mail profissional.</li>
        </ul>

        <h4>Finalidades do Tratamento dos Dados</h4>
        <p>O tratamento dos dados pessoais listados neste termo tem as seguintes finalidades:</p>

        <ul>
          <li>
            Possibilitar que o Controlador identifique e entre em contato com o Titular para fins de
            relacionamento comercial.
          </li>
          <li>
            Possibilitar que o Controlador elabore contratos comerciais e emita cobranças contra o
            Titular.
          </li>
          <li>
            Possibilitar que o Controlador envie ou forneça ao Titular seus produtos e serviços, de
            forma remunerada ou gratuita.
          </li>
          <li>
            Possibilitar que o Controlador estruture, teste, promova e faça propaganda de produtos e
            serviços, personalizados ou não ao perfil do Titular.
          </li>
        </ul>

        <h4>Obrigações de registro</h4>
        <ul>
          <li>
            O usuário deverá se registrar na MaxxiData e na Bayer para acessar e usar recursos do
            serviço. Se o usuário optar por se registrar no Serviço, o usuário concorda em fornecer
            e manter informações verdadeiras, precisas, atuais e completas sobre o usuário, conforme
            solicitado pelo formulário de registro do usuário. Dados de registro e outras
            informações sobre o usuário são regidos pela Política de Privacidade.
          </li>
        </ul>

        <h4>Compartilhamento de Dados</h4>
        <ul>
          <li>
            A empresa MaxxiData e a empresa Bayer informa que não compartilhará seus dados pessoais,
            ficando armazenamos na empresa apenas para os fins acima.
          </li>
        </ul>

        <h4>Política de privacidade e dados do usuário</h4>
        <ul>
          <li>
            Sua privacidade é importante para a empresa MaxxiData e a empresa Bayer. O preenchimento
            de formulários ou acesso aos serviços do website pode exigir o processamento de dados
            pessoais incluindo, mas não limitado a nome do contato, endereço pessoal, telefone, ou
            e-mail. Esses dados serão utilizados pela empresa MaxxiData e pela empresa Bayer para
            fins indicados acima, mais precisamente para cadastro, controle e relatório de
            participação em campanhas comerciais. Com o objetivo de direcionar o desenvolvimento e
            melhoria da ferramenta ou site, poderão ser coletados dados de navegação, incluindo, mas
            não limitados ao sistema operacional/browser, número de acessos, ações na ferramenta,
            árvore de navegação, tempo na ferramenta, origem da conexão. A empresa MaxxiData e a
            empresa Bayer só usa esses Dados Pessoais apenas para fornecer os Serviços para o
            usuário e para melhorar seus algoritmos e tudo em conformidade com a Lei Geral de
            Proteção de Dados (Lei nº 13.709/2018).
          </li>
          <li>
            Para fins deste Termo de uso, dados pessoais significa quaisquer informações fornecidas
            e/ou coletadas pela empresa MaxxiData e pela empresa Bayer e/ou suas afiliadas, por
            qualquer meio, ainda que públicos, que: (I) identifiquem, ou que, quando usadas em
            combinação com outras informações tratadas pela MaxxiData e pela empresa Bayer
            identifiquem um indivíduo; ou (II) por meio das quais a identificação ou informações de
            contato de uma pessoa física possam ser derivadas. Os Dados Pessoais podem estar em
            qualquer mídia ou formato, inclusive registros eletrônicos ou computadorizados, bem como
            em arquivos baseados em papel. Os Dados Pessoais, no entanto, não incluem telefone
            comercial, número de celular comercial, endereço comercial, e-mail comercial.
          </li>
          <li>
            O usuário concorda com as nossas práticas relativas a dados, inclusive com a coleta, o
            uso e o processamento de suas informações, inclusive dados pessoais sensíveis fornecidos
            pelo usuário quando do cadastro e uso dos serviços da empresa MaxxiData e da empresa
            Bayer.
          </li>
        </ul>

        <h4>Segurança dos Dados</h4>
        <ul>
          <li>
            O Controlador responsabiliza-se pela manutenção de medidas de segurança, técnicas e
            administrativas aptas a proteger os dados pessoais de acessos não autorizados e de
            situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou
            qualquer forma de tratamento inadequado ou ilícito.
          </li>
          <li>
            Em conformidade ao art. 48 da Lei nº 13.709, o Controlador comunicará ao Titular e à
            Autoridade Nacional de Proteção de Dados (ANPD) a ocorrência de incidente de segurança
            que possa acarretar risco ou dano relevante ao Titular.
          </li>
          <li>
            A empresa MaxxiData e a empresa Bayer possui várias camadas de segurança, incluindo
            firewalls, sistemas de proteção contra intrusão e segregação de rede. Os serviços de
            segurança da empresa MaxxiData e da empresa Bayer são configurados, monitorados e
            mantidos de acordo com as melhores práticas de campo. A empresa MaxxiData e a empresa
            Bayer toma medidas para proteger os Dados Pessoais fornecidos através dos Serviços
            contra perda, uso indevido e acesso não autorizado, divulgação, alteração ou destruição.
            No entanto, nenhuma transmissão de Internet ou e-mail é totalmente segura ou livre de
            erros. Em particular, os e-mails enviados para ou a partir dos Serviços podem não ser
            seguros. Portanto, o usuário deve tomar um cuidado especial ao decidir quais informações
            nos envia por e-mail ou conteúdos que faz upload no servidor da empresa MaxxiData e da
            empresa Bayer.
          </li>
        </ul>

        <h4>Término do Tratamento dos Dados</h4>
        <ul>
          <li>
            O Controlador poderá manter e tratar os dados pessoais do Titular durante todo o período
            em que os mesmos forem pertinentes ao alcance das finalidades listadas neste termo.
            Dados pessoais anonimizados, sem possibilidade de associação ao indivíduo, poderão ser
            mantidos por período indefinido.
          </li>
          <li>
            O Titular poderá solicitar via e-mail ou correspondência ao Controlador, a qualquer
            momento, que sejam eliminados os dados pessoais não anonimizados do Titular. O Titular
            fica ciente de que poderá ser inviável ao Controlador continuar o fornecimento de
            produtos ou serviços ao Titular a partir da eliminação dos dados pessoais.
          </li>
          <li>
            A empresa MaxxiData e a empresa Bayer reterá os Dados Pessoais e manterá os dados
            armazenados até eventual procedimento de exclusão. A empresa MaxxiData e a empresa Bayer
            poderá vir a manter seus Dados Pessoais após receber seu pedido de exclusão caso seja
            necessário para cumprimento de obrigações legais, resolver disputas, manter a segurança,
            evitar fraudes e abuso e garantir o cumprimento de contratos.
          </li>
        </ul>

        <h4>Direitos do Titular</h4>
        <ul>
          <li>
            O Titular tem direito a obter do Controlador, em relação aos dados por ele tratados, a
            qualquer momento e mediante requisição: I - confirmação da existência de tratamento; II
            - acesso aos dados; III - correção de dados incompletos, inexatos ou desatualizados; IV
            - anonimização, bloqueio ou eliminação de dados desnecessários, excessivos ou tratados
            em desconformidade com o disposto na Lei nº 13.709; V - portabilidade dos dados a outro
            fornecedor de serviço ou produto, mediante requisição expressa e observados os segredos
            comercial e industrial, de acordo com a regulamentação do órgão controlador; V -
            portabilidade dos dados a outro fornecedor de serviço ou produto, mediante requisição
            expressa, de acordo com a regulamentação da autoridade nacional, observados os segredos
            comercial e industrial; VI - eliminação dos dados pessoais tratados com o consentimento
            do titular, exceto nas hipóteses previstas no art. 16 da Lei nº 13.709; VII - informação
            das entidades públicas e privadas com as quais o controlador realizou uso compartilhado
            de dados; VIII - informação sobre a possibilidade de não fornecer consentimento e sobre
            as consequências da negativa; IX - revogação do consentimento, nos termos do § 5º do
            art. 8º da Lei nº 13.709.
          </li>
        </ul>

        <h4>Direito de Revogação do Consentimento</h4>
        <ul>
          <li>
            Este consentimento poderá ser revogado pelo Titular, a qualquer momento, mediante
            solicitação via e-mail ou correspondência ao Controlador.
          </li>
        </ul>

        <h4>Segurança dos Dados</h4>
        <ul>
          <li>
            A empresa MaxxiData e a empresa Bayer é proprietária de todos os direitos autorais,
            marcas, domínios, logotipos, aparência do produto, segredos de empresa, patentes e
            outros direitos de propriedade intelectual associados aos nossos Serviços. É proibido o
            uso dos nossos direitos autorais, marcas, domínios, logotipos, aparência do produto,
            segredos de empresa, patentes e outros direitos de propriedade intelectual sem
            autorização expressa.
          </li>
          <li>
            Para cumprir a legislação brasileira ou por solicitação legal a empresa MaxxiData e a
            empresa Bayer se reserva no direito de compartilhar informações pessoais dos usuários,
            inclusive para cumprir investigações de possíveis violações, detectar, impedir ou lidar
            com alguma forma com fraudes, problemas técnicos ou de segurança, proteger de prejuízos
            aos direitos, à propriedade ou à segurança da empresa MaxxiData e da empresa Bayer , dos
            nossos usuários ou de terceiros, conforme solicitado ou permitido em lei, bem manter os
            dados dos usuários para cumprir com a legislação anticorrupção, compliance e segurança
            da Empresa.
          </li>
        </ul>

        <h4>Por motivos Legais</h4>
        <ul>
          <li>
            Para cumprir a legislação brasileira ou por solicitação legal a empresa MaxxiData e a
            empresa Bayer se reserva no direito de compartilhar informações pessoais dos usuários,
            inclusive para cumprir investigações de possíveis violações, detectar, impedir ou lidar
            com alguma forma com fraudes, problemas técnicos ou de segurança, proteger de prejuízos
            aos direitos, à propriedade ou à segurança da empresa MaxxiData e da empresa Bayer, dos
            nossos usuários ou de terceiros, conforme solicitado ou permitido em lei, bem manter os
            dados dos usuários para cumprir com a legislação anticorrupção, compliance e segurança
            da Empresa.
          </li>
        </ul>
        <hr />
        <Checkbox checked={accept} onChange={e => setAccept(e.target.checked)}>
          Eu aceito termo de uso e consentimento para tratamento dos meus dados
        </Checkbox>
        <hr />
      </CustomModal>
    );
  }

  return (
    <div style={{ marginTop: '15%' }}>
      <Axios api="users" method="post" params="accept-lgpd" onSuccess={onAcceptedSuccessfully}>
        {lgpdModal}
      </Axios>
    </div>
  );
};

export default Lgpd;
