import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import './styles.css';
import { Button, Row, Col, Modal } from 'antd';

const CustomModal = function (props) {
  const { t } = useTranslation();

  return (
    <Modal
      bodyStyle={{ padding: 0, margin: 0 }}
      title={null}
      visible={props.visible}
      onOk={props.onOk}
      className="containerModal"
      closable={false}
      footer={null}
      afterClose={props.onCancel}
      onSubmit={props.onSubmit}
      onCancel={props.onCancel}
      zIndex={999}
    >
      {props.title && !props.customHeader && (
        <div>
          <div className="headerModal">
            <div className="titleModal">{props.title}</div>
          </div>
          <hr className="hr" />
        </div>
      )}
      {props.customHeader && <>{props.customHeader}</>}
      <div className="contentModal">
        {props.children}
        {props.useBottomButtons && (
          <Row type="flex" justify="start">
            <Col span={24}>
              <div className="containerBottom">
                <Button className="buttonBottom" onClick={() => props.onCancel()}>
                  {props.cancelButtonTitle || t('close')}
                </Button>
                <Button
                  className="buttonBottom"
                  type="primary"
                  onClick={() => props.onSubmit()}
                  disabled={props.disableOkButton}
                >
                  {props.okButtonTitle || t('save')}
                </Button>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </Modal>
  );
};

const mapStateToProps = lang => {
  const { lng } = lang;
  return {
    lng,
  };
};

export default connect(mapStateToProps)(CustomModal);
