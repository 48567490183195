import React from 'react';

import { Row, Col } from 'antd';

import { Form } from '@ant-design/compatible';

import Input from '@components/Input';

const FormComponent = ({ errors, values, handleSubmit, handleChange }) => {
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="memberName"
            onChange={handleChange}
            error={errors.memberName}
            value={values.memberName}
            label="members.create.form.label.name"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
