import React from 'react';

import { Row, Col } from 'antd';

import { Form } from '@ant-design/compatible';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import Input from '@components/Input';

const composeIcon = IconComponent => (
  <IconComponent style={{ fontSize: '17px', color: 'dodgerblue' }} />
);

const FormComponent = ({ errors, values, handleChange }) => {
  const UserIcon = composeIcon(UserOutlined);
  const PasswordIcon = composeIcon(LockOutlined);
  return (
    <Form layout="vertical">
      <Row gutter={20}>
        <Col span={24}>
          <Input
            name="userLogin"
            error={errors.userLogin}
            value={values.userLogin}
            onChange={handleChange}
            prefix={UserIcon}
            placeholder="login.form.input.label.email.placeholder"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Input
            type="password"
            name="userPassword"
            onChange={handleChange}
            error={errors.userPassword}
            value={values.userPassword}
            prefix={PasswordIcon}
            placeholder="login.form.input.label.password.placeholder"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
