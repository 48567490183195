import React, { useState } from 'react';

import Axios from '@components/Axios';
import ItemDealers from '@components/ItemDealers';

const DealerTab = ({ goalId, bindFormProps, incrementDealerTabSnapshot }) => {
  const [run, setRun] = useState(1);
  const [dealers, setDealers] = useState([]);
  const [touched, setTouched] = useState({});

  const updateScreen = () => {
    setRun(run => run + 1);
    incrementDealerTabSnapshot();
  };

  const onSuccess = data => {
    setDealers(data);
  };

  const onSuccessTouched = () => {
    setTouched({});
    updateScreen();
  };

  const submitForm = async submit => {
    await submit({ body: Object.values(touched) });
    return true;
  };

  const getComponentTable = ({ submit }) => {
    bindFormProps({ dirty: !!Object.keys(touched).length, submitForm: () => submitForm(submit) });
    return (
      <ItemDealers
        goalId={goalId}
        touched={touched}
        dealers={dealers}
        setTouched={setTouched}
        updateScreen={updateScreen}
      />
    );
  };

  return (
    <div>
      <Axios
        api="goals"
        onSuccess={onSuccess}
        run={{ run, goalId }}
        params={[`${goalId}/dealers`, { ignorePagination: true, include: 'dealer' }]}
      >
        <Axios api="goals" method="post" onSuccess={onSuccessTouched} params={`${goalId}/dealers`}>
          {getComponentTable}
        </Axios>
      </Axios>
    </div>
  );
};

export default DealerTab;
