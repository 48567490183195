import React, { useState } from 'react';
import { LoadingOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';

import './styles.scss';

const Banner = ({ goal = {} }) => {
  const [banner, setBanner] = useState();
  const { goalBannerId } = goal;

  const onSuccessBanner = data => {
    setBanner(data);
  };

  const getImageComponent = () => {
    if (banner?.signedUrl) {
      return <div className="banner-image"></div>;
    } else {
      return <LoadingOutlined size="large" />;
    }
  };

  return goalBannerId ? (
    <Axios
      run={goalBannerId}
      api="attachments"
      onSuccess={onSuccessBanner}
      params={`/${goalBannerId}/signed`}
    >
      <div className="Banner" style={{ backgroundImage: `url(${banner?.signedUrl})` }}>
        {getImageComponent()}
      </div>
    </Axios>
  ) : null;
};

export default Banner;
