import React, { useState } from 'react';
import { Popconfirm, Button } from 'antd';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';

const PopconfirmComponent = ({
  title,
  axios,
  onClick,
  onCancel,
  visible,
  onConfirm,
  onSuccess,
  children,
  disabled = false,
}) => {
  const [open, setOpen] = useState(visible);

  const handleVisibleChange = visible => {
    setOpen(visible);
  };

  return (
    <Axios onSuccess={onSuccess} {...axios}>
      {({ submit, loading }) => (
        <Popconfirm
          visible={open}
          placement="left"
          onCancel={onCancel}
          okText={i18n.t('yes')}
          title={i18n.t(title)}
          cancelText={i18n.t('not')}
          onConfirm={onConfirm || submit}
          onVisibleChange={handleVisibleChange}
        >
          <Button loading={loading} type="link" onClick={onClick} disabled={disabled}>
            {children}
          </Button>
        </Popconfirm>
      )}
    </Axios>
  );
};

export default PopconfirmComponent;
