import React from 'react';

import { Row, Col } from 'antd';

import { Form } from '@ant-design/compatible';

import Axios from '@components/Axios';
import Input from '@components/Input';

const FormComponent = ({ errors, values, handleSubmit, handleChange }) => {
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="name"
            onChange={handleChange}
            error={errors.name}
            value={values.name}
            label="manager.create.form.label.name"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="documentNumber"
            onChange={handleChange}
            mask="999.999.999-99"
            error={errors.documentNumber}
            value={values.documentNumber}
            label="manager.create.form.label.cpf"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="email"
            onChange={handleChange}
            error={errors.email}
            value={values.email}
            label="manager.create.form.label.email"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="phone"
            mask="(99) 9 9999-9999"
            onChange={handleChange}
            error={errors.phone}
            value={values.phone}
            label="manager.create.form.label.phone"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Axios run api="dealers" params={{ ignorePagination: true }}>
            {({ loading, response }) => (
              <Input
                type="select"
                name="dealerId"
                array={response}
                loading={loading}
                onChange={handleChange}
                error={errors.dealerId}
                value={values.dealerId}
                label="manager.create.form.label.dealer"
                option={{
                  key: 'id',
                  name: 'name',
                }}
              />
            )}
          </Axios>
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="switch"
            name="active"
            onChange={handleChange}
            error={errors.active}
            value={values.active}
            label="manager.create.form.label.status"
            checkedChildren="manager.create.form.label.status.checkedChildren"
            unCheckedChildren="manager.create.form.label.status.unCheckedChildren"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="switch"
            name="canAccessPortal"
            onChange={handleChange}
            error={errors.canAccessPortal}
            value={values.canAccessPortal}
            label="manager.create.form.label.portal"
            checkedChildren="manager.create.form.label.portal.checkedChildren"
            unCheckedChildren="manager.create.form.label.portal.unCheckedChildren"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
