import React, { useState, memo, useMemo, useCallback } from 'react';

import { Button, Card } from 'antd';

import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';
import Input from '@components/Input';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';
import TableSearch from '@components/TableSearch';
import AddManagerGoal from '@components/AddManagerGoal';
import UpdateGoalByProfile from '@components/UpdateGoalByProfile';
import { sortNumber, sortText } from '@util/columnSorter';
import { isDealer } from '@util/user.utils';
import DoneGoalPermissionWrapper from '@containers/Dashboard/components/DoneGoalPermissionWrapper';

import './styles.scss';

const ListManager = ({ run, user, goalId, goal, dealers = [], updateScreen, history }) => {
  const [parentId, setParentId] = useState();
  const [deletingId, setDeletingId] = useState();
  const [manager, setManager] = useState([]);
  const [openAddManager, setOpenAddManager] = useState(false);
  const [selectedManagerFilter, setSelectedManagerFilter] = useState([]);
  const managerFilterData = useMemo(() => {
    const data = parentId
      ? manager.filter(({ manager }) => String(manager?.dealerId) === parentId)
      : manager;

    const set = new Set();

    return data
      .map(({ manager }) => manager)
      .filter(item => {
        const duplicate = set.has(item.id);
        set.add(item.id);
        return !duplicate;
      });
  }, [manager.length, parentId]);

  const columns = [
    {
      dataIndex: ['manager', 'name'],
      title: i18n.t('dashboard.manager.list.table.header.name'),
      sorter: {
        compare: (a, b) => sortText(a, b, ['manager', 'name']),
        multiple: 2,
      },
    },
    {
      dataIndex: ['manager', 'dealer', 'name'],
      title: i18n.t('dashboard.manager.list.table.header.dealer'),
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortText(a, b, ['manager', 'dealer', 'name']),
        multiple: 2,
      },
      className: isDealer(user) ? 'hidden' : '',
    },
    {
      dataIndex: 'goalValue',
      title: i18n.t('dashboard.dealers.list.table.header.goal'),
      sorter: (a, b) => sortNumber(a, b, 'goalValue'),
      render: (_, { managerId, ...item }) => {
        const object = { ...item, itemId: managerId };
        return (
          <UpdateGoalByProfile
            enable
            user={user}
            item={object}
            api="managers"
            field="goalValue"
            updateScreen={updateScreen}
            goalStatus={goal.goalStatus}
          />
        );
      },
    },
    {
      dataIndex: 'actualValue',
      title: i18n.t('dashboard.dealers.list.table.header.actualValue'),
      sorter: (a, b) => sortNumber(a, b, 'actualValue'),
      render: (_, { managerId, ...item }) => {
        const object = { ...item, itemId: managerId };
        return (
          <UpdateGoalByProfile
            user={user}
            item={object}
            api="managers"
            field="actualValue"
            updateScreen={updateScreen}
            goalStatus={goal.goalStatus}
          />
        );
      },
    },
    {
      dataIndex: 'ranking',
      title: i18n.t('dashboard.manager.list.table.header.ranking'),
      render: ranking => `${ranking}°`,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortNumber(a, b, 'ranking'),
        multiple: 1,
      },
    },
    {
      title: i18n.t('dashboard.manager.list.table.header.actions'),
      render: (_, { manager: { id } }) => {
        const onCancel = () => setDeletingId();
        const onClick = () => setDeletingId(id);
        const axios = {
          api: 'goals',
          method: 'delete',
          onError: onCancel,
          onSuccess: onSuccessDelete,
          params: `${goalId}/managers/${id}/values`,
        };
        return (
          <DoneGoalPermissionWrapper goalStatus={goal.goalStatus}>
            <Popconfirm
              axios={axios}
              onClick={onClick}
              onCancel={onCancel}
              title="confirmDelete"
              visible={deletingId === id}
            >
              <DeleteOutlined />
            </Popconfirm>
          </DoneGoalPermissionWrapper>
        );
      },
    },
  ];

  const toggleAddManager = () => {
    setOpenAddManager(value => !value);
  };

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddManager = () => {
    updateScreen();
    toggleAddManager();
  };

  const onSuccessManager = response => {
    setManager(response);
  };

  const onChangeDealers = ({ target: { value } }) => {
    setParentId(value);
    setSelectedManagerFilter([]);
  };

  const onChangeManagerFilter = useCallback(({ target: { value } }) => {
    setSelectedManagerFilter(value);
  });

  const onAddManager = ({ body, submit }) => {
    const data = body
      .filter(item => item && !manager.find(mnger => item?.id === mnger.managerId))
      .map(item => {
        return {
          goalId,
          goalValue: 0,
          actualValue: 0,
          managerId: item.id,
        };
      });
    submit({ body: data });
  };

  const getComponentAddManager = () => {
    return (
      <Axios
        api="goals"
        method="post"
        params={`${goalId}/managers`}
        onSuccess={onSuccessAddManager}
      >
        {({ submit }) => (
          <AddManagerGoal
            goalId={goalId}
            dealers={dealers}
            listManager={manager}
            isVisible={openAddManager}
            dealerIdField="dealer.id"
            dealerNameField="dealer.name"
            onCancel={toggleAddManager}
            onAdd={body => onAddManager({ body, submit })}
          />
        )}
      </Axios>
    );
  };

  const components = () => {
    return (
      <div className="filter">
        <Input
          type="select"
          mode="multiple"
          array={managerFilterData}
          value={selectedManagerFilter}
          style={{ minWidth: 300, paddingLeft: '10px' }}
          onChange={onChangeManagerFilter}
          placeholder={'dashboard.manager.list.selectManager'}
          option={{
            name: 'name',
            key: 'id',
          }}
        />
        <Input
          type="select"
          array={dealers}
          value={parentId}
          onChange={onChangeDealers}
          placeholder={'dashboard.product.list.selectDealer'}
          option={{
            name: 'dealer.name',
            key: 'dealer.id',
          }}
        />
      </div>
    );
  };

  const axios = {
    api: 'goals',
    run: [run, parentId, selectedManagerFilter.length + 1],
    params: [
      `${goalId}/managers`,
      {
        include: 'manager,ranking',
        hasItem: false,
        ignorePagination: true,
        parentId,
        ...(selectedManagerFilter.length > 0 && {
          managerId: selectedManagerFilter.join(','),
        }),
      },
    ],
  };

  // const actionProps = {
  //   icon: <PlusOutlined />,
  //   onClick: toggleAddManager,
  //   title: 'dashboard.manager.list.table.action',
  // };

  const goTo = path => {
    history.push(path);
  };

  const renderActions = () => {
    return (
      <div className="contentSpace">
        <Button
          className="button"
          type="primary"
          onClick={toggleAddManager}
          icon={<PlusOutlined />}
        >
          {i18n.t('dashboard.manager.list.table.action')}
        </Button>
        <Button
          className="button"
          type="primary"
          onClick={() => goTo(`/upload-batch/${goal.id}?managers`)}
        >
          {i18n.t('dashboard.header.action.updateBatch')}
        </Button>
      </div>
    );
  };

  return (
    <Card className="ListManager">
      <Axios
        api="goals"
        run={[run, goalId]}
        onSuccess={onSuccessManager}
        params={[
          `${goalId}/managers`,
          { ignorePagination: true, include: 'manager', hasItem: false },
        ]}
      >
        {renderActions()}
        <TableSearch
          axios={axios}
          ignorePagination
          columns={columns}
          component={components}
          // actionProps={actionProps}
          title="dashboard.manager.list.table.title"
          rowId="id"
        />
        {getComponentAddManager()}
      </Axios>
    </Card>
  );
};

export default memo(ListManager);
