/* eslint-disable import-helpers/order-imports */
// Goal
import ListGoal from '@containers/Goal/ListGoal';
import CreateGoal from '@containers/Goal/CreateGoal';

// Dealers
import ListDealer from '@containers/Dealers/ListDealer';
import CreateDealer from '@containers/Dealers/CreateDealer';
import BatchImportDealer from '@containers/Dealers/BatchImport';

// Salespeople
import ListSalespeople from '@containers/Salespeople/ListSalespeople';
import CreateSalespeople from '@containers/Salespeople/CreateSalespeople';
import BatchImportSalesperson from '@containers/Salespeople/BatchImport';

// Members
import ListMember from '@containers/Members/ListMember';
import CreateMember from '@containers/Members/CreateMember';

// Users
import ListUser from '@containers/Users/ListUsers';

// Dashboard
import Dashboard from '@containers/Dashboard';

// UpdateBatch
import UpdateBatch from '@containers/UpdateBatch';

// Profile
import EditProfile from '@containers/Profile/EditProfile';

import { isAdmin, isTSR } from '@util/user.utils';

//Audit
import AuditList from '@containers/Audit/AuditList';

//Manager
import ListManager from '@containers/Manager/ListManager';
import CreateManager from '@containers/Manager/CreateManager';
import BatchImportManager from '@containers/Manager/BatchImport';

//Telesales
import ListTelesales from '@containers/Telesales/ListTelesales';
import CreateTelesales from '@containers/Telesales/CreateTelesales';
import BatchImportTelesales from '@containers/Telesales/BatchImport';

// Routes Menu
const menu = [
  {
    icon: 'trophy',
    path: '/goal/list',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER', 'SALES', 'MANAGER', 'TELESALES'],
    name: 'sidebar.label.goals',
  },
  {
    icon: 'user',
    path: '/users/list',
    permission: ['SYSADMIN', 'ADMIN'],
    name: 'sidebar.label.users',
  },
  {
    icon: 'usergroup-add',
    path: '/members/list',
    permission: ['SYSADMIN', 'ADMIN'],
    name: 'sidebar.label.members',
  },
  {
    icon: 'compass',
    path: '/dealers/list',
    permission: ['SYSADMIN', 'ADMIN', 'TSR'],
    name: 'sidebar.label.dealers',
  },
  {
    icon: 'dollar',
    path: '/salespeople/list',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
    name: 'sidebar.label.salespeople',
  },
  {
    icon: 'fund',
    path: '/manager/list',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
    name: 'sidebar.label.manager',
  },
  {
    icon: 'laptop',
    path: '/telesales/list',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
    name: 'sidebar.label.telesales',
  },
  {
    icon: 'profile',
    path: '/logs/list',
    permission: ['SYSADMIN', 'ADMIN'],
    name: 'sidebar.label.logs',
  },
];

// Routes Screens
const components = [
  {
    root: true,
    path: '/goal/list',
    component: ListGoal,
    label: 'goalsList',
    title: 'goal.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER', 'SALES', 'MANAGER', 'TELESALES'],
  },
  {
    path: '/goal/create',
    component: CreateGoal,
    label: 'goalsCreate',
    title: 'goal.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
  {
    path: '/goal/:id',
    component: CreateGoal,
    label: 'goalsEdit',
    title: 'goal.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
    customValidate: ({ user, match }) => {
      const { params } = match;
      const { terms = {} } = user;
      if (!isAdmin(user) && !isTSR(user)) {
        return terms[params.id];
      }
      return true;
    },
  },
  {
    root: true,
    path: '/dealers/list',
    component: ListDealer,
    label: 'dealersList',
    title: 'dealers.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR'],
  },
  {
    path: '/dealers/create',
    component: CreateDealer,
    label: 'dealersCreate',
    title: 'dealers.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR'],
  },
  {
    path: '/dealers/batch',
    component: BatchImportDealer,
    label: 'dealersBatch',
    title: 'dealers.batchImport.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR'],
  },
  {
    path: '/dealers/:id',
    component: CreateDealer,
    label: 'dealersEdit',
    title: 'dealers.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR'],
  },
  {
    root: true,
    path: '/manager/list',
    component: ListManager,
    label: 'managerList',
    title: 'manager.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/manager/create',
    component: CreateManager,
    label: 'managerCreate',
    title: 'manager.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/manager/batch',
    component: BatchImportManager,
    label: 'managerBatch',
    title: 'manager.batchImport.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/manager/:id',
    component: CreateManager,
    label: 'managerEdit',
    title: 'manager.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    root: true,
    path: '/telesales/list',
    component: ListTelesales,
    label: 'telesalesList',
    title: 'telesales.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/telesales/create',
    component: CreateTelesales,
    label: 'telesalesCreate',
    title: 'telesales.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/telesales/batch',
    component: BatchImportTelesales,
    label: 'telesalesBatch',
    title: 'telesales.batchImport.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/telesales/:id',
    component: CreateTelesales,
    label: 'telesalesEdit',
    title: 'telesales.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    root: true,
    path: '/salespeople/list',
    component: ListSalespeople,
    label: 'salespeopleList',
    title: 'salespeople.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/salespeople/create',
    component: CreateSalespeople,
    label: 'salespeopleCreate',
    title: 'salespeople.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/salespeople/batch',
    component: BatchImportSalesperson,
    label: 'salespeopleBatch',
    title: 'salespeople.batchImport.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    path: '/salespeople/:id',
    component: CreateSalespeople,
    label: 'salespeopleEdit',
    title: 'salespeople.list.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    root: true,
    path: '/members/list',
    component: ListMember,
    label: 'membersList',
    title: 'members.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
  {
    root: true,
    path: '/users/list',
    component: ListUser,
    label: 'usersList',
    title: 'users.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
  {
    path: '/members/create',
    component: CreateMember,
    label: 'membersCreate',
    title: 'members.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
  {
    path: '/members/:id',
    component: CreateMember,
    label: 'membersEdit',
    title: 'members.list.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
  {
    component: Dashboard,
    path: '/dashboard/:id',
    label: 'dashboard',
    title: 'dashboard.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER', 'SALES', 'MANAGER', 'TELESALES'],
  },
  {
    component: UpdateBatch,
    path: '/upload-batch/:id',
    label: 'updateBatch',
    title: 'updateBatch.header.title',
    permission: ['SYSADMIN', 'ADMIN', 'TSR', 'DEALER'],
  },
  {
    component: EditProfile,
    path: '/profile/edit',
    label: 'profile.edit',
    title: 'profile',
    permission: ['ADMIN', 'TSR', 'DEALER', 'SALES', 'MANAGER', 'TELESALES'],
  },
  {
    component: AuditList,
    root: true,
    path: '/logs/list',
    label: 'audit',
    title: 'audit.header.title',
    permission: ['SYSADMIN', 'ADMIN'],
  },
];

export { menu };
export default components;
