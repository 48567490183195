import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useUserContext } from '@context/UserContext';
import Lgpd from '@containers/Lgpd';
import routes from '@routes/routes';

import GuardedRoute from './components/GuardedRoute';

const Main = ({ match }) => {
  const { user } = useUserContext();
  return (
    <Switch>
      <Route path={`${match.url}lgpd`} component={Lgpd} />
      {routes.map((item, index) => {
        return <GuardedRoute key={index} lgpd={user?.lgpd} {...item} />;
      })}
      <Route render={() => <Redirect to="/goal/list" />} />
    </Switch>
  );
};

export default Main;
