import React, { createContext, useEffect, useReducer } from 'react';

import { get } from 'lodash';

const CONTEXT_NAME = 'UI_CONTEXT';
const CONTEXT_NAME_PERSIST = `${CONTEXT_NAME}:PERSIST`;
const STORED_STATE: IFC_UI = JSON.parse(localStorage.getItem(CONTEXT_NAME_PERSIST)!);

type Action = {
  type: ActionTypes;
  payload?: any;
};

type IFC_UI = {
  SIDEBAR_MENU_EXPANDED: boolean;
  THEME_TYPE: 'dark' | 'light';
};

const STT_UI_CONTEXT: IFC_UI = {
  SIDEBAR_MENU_EXPANDED: get(STORED_STATE, 'SIDEBAR_MENU_EXPANDED', true),
  THEME_TYPE: get(STORED_STATE, 'THEME_TYPE', 'dark'),
};

export enum ActionTypes {
  TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR',
}

const UIStateReducer = (state: IFC_UI, action: Action) => {
  const { type } = action;
  switch (type) {
    case ActionTypes.TOGGLE_SIDEBAR: {
      return {
        ...state,
        SIDEBAR_MENU_EXPANDED: !state.SIDEBAR_MENU_EXPANDED,
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${type}`);
    }
  }
};

export const UIStateContext = createContext<{
  state: IFC_UI;
  dispatch: React.Dispatch<Action>;
}>({
  state: STT_UI_CONTEXT,
  dispatch: () => {},
});

export const UIStateContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [UIState, dispatch] = useReducer(UIStateReducer, STT_UI_CONTEXT);

  useEffect(() => {
    localStorage.setItem(CONTEXT_NAME_PERSIST, JSON.stringify(UIState));
  }, [UIState]);

  return (
    <UIStateContext.Provider value={{ state: UIState, dispatch }}>
      {children}
    </UIStateContext.Provider>
  );
};
