import React, { useEffect, useState } from 'react';

import { Card } from 'antd';

import Input from '@components/Input';
import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { sortText, sortNumber } from '@util/columnSorter';
import UpdateGoalByProfile from '@components/UpdateGoalByProfile';
import { isDealer } from '@util/user.utils';

import './styles.scss';

const ListProductTelesales = ({ run, updateScreen, goalId, goal, dealers, user }) => {
  const [itemId, setItemId] = useState();
  const [parentId, setParentId] = useState();
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [dataset, setDataset] = useState([]);
  const [telesales, setTelesales] = useState([]);

  useEffect(() => {
    const listTelesales = filterDealers(parentId);
    filterProduct(itemId, listTelesales);
  }, [items, telesales]);

  const onSuccessItems = items => {
    setItems(items);
  };

  const onSuccessTelesales = telesales => {
    setTelesales(telesales);
  };

  const groupTelesales = telesales => {
    const grouped = telesales.reduce(
      (acc, { id, goalValue, actualValue, telesales, goalItem, goalId, ranking }) => {
        let entry = acc[telesales.id] || {
          ...telesales,
          telesalesId: telesales.id,
          goalId,
        };
        const object = {
          id,
          goalValue,
          actualValue,
          telesalesId: telesales.id,
          ranking,
        };
        if (!goalItem) {
          entry = { ...entry, ...object };
        } else {
          const goalItems = entry.goalItems || [];
          entry.goalItems = [
            ...goalItems,
            {
              ...object,
              goalItemId: goalItem.id,
              itemName: goalItem.itemName,
            },
          ];
        }
        acc[telesales.id] = entry;
        return acc;
      },
      {},
    );
    return Object.values(grouped);
  };

  const includeItemInTelesales = (products, list = []) => {
    let key = 0;
    const array = [];
    for (const telesales of list) {
      const { goalItems = [] } = telesales;
      for (const { id, itemName } of products) {
        const item = goalItems.find(({ goalItemId }) => goalItemId === id);
        if (!item) {
          array.push({
            ...telesales,
            goalItem: { goalItemId: id, itemName, actualValue: 0 },
            key: key++,
          });
        } else {
          array.push({ ...telesales, goalItem: item, key: key++ });
        }
      }
    }
    setDataset(array);
  };

  const filterProduct = (value, list) => {
    if (value) {
      const itemList = items.filter(item => String(item.id) === String(value));
      includeItemInTelesales(itemList, list);
    } else {
      includeItemInTelesales(items, list);
    }
  };

  const filterDealers = value => {
    if (value) {
      const salesPeopleFilterList = telesales.filter(
        ({ telesales }) => String(telesales?.dealer?.id) === String(value),
      );
      return groupTelesales(salesPeopleFilterList);
    } else {
      return groupTelesales(telesales);
    }
  };

  const onChangeDealers = ({ target: { value } }) => {
    setParentId(value);
    const listTelesales = filterDealers(value);
    filterProduct(itemId, listTelesales);
  };

  const onChangeItem = ({ target: { value } }) => {
    setItemId(value);
    const listTelesales = filterDealers(parentId);
    filterProduct(value, listTelesales);
  };

  const onChangeSearch = value => {
    setSearch(value);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: i18n.t('dashboard.product.list.table.header.telesales'),
    },
    {
      dataIndex: ['dealer', 'name'],
      title: i18n.t('dashboard.product.list.table.header.dealers'),
    },
    {
      dataIndex: ['goalItem', 'itemName'],
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortText(a, b, ['goalItem', 'itemName']),
        multiple: 2,
      },
      title: i18n.t('dashboard.product.list.table.header.product'),
    },
    {
      dataIndex: 'actualValue',
      title: i18n.t('dashboard.product.list.table.header.actualValue'),
      render: (_, { telesalesId, goalItem }) => {
        const object = { ...goalItem, itemId: telesalesId, goalId };
        return (
          <UpdateGoalByProfile
            user={user}
            item={object}
            api="telesales"
            field="actualValue"
            fieldId="telesalesId"
            updateScreen={updateScreen}
            goalStatus={goal.goalStatus}
          />
        );
      },
    },
    {
      dataIndex: 'ranking',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortNumber(a, b, ['goalItem', 'ranking']),
        multiple: 1,
      },
      title: i18n.t('dashboard.product.list.table.header.ranking'),
      render: (_, { goalItem: { ranking } }) => {
        return ranking ? `${ranking}°` : '-';
      },
    },
  ];

  const components = () => {
    return (
      <div className="list-product-filter">
        {!isDealer(user) && (
          <Input
            type="select"
            array={dealers}
            value={parentId}
            onChange={onChangeDealers}
            placeholder={'dashboard.product.list.selectDealer'}
            option={{
              name: 'dealer.name',
              key: 'dealer.id',
            }}
          />
        )}
        <Input
          type="select"
          array={items}
          value={itemId}
          onChange={onChangeItem}
          placeholder={'dashboard.product.list.selectItem'}
          option={{
            name: 'itemName',
            key: 'id',
          }}
        />
      </div>
    );
  };

  console.table(dataset);
  return (
    <Axios
      api="goals"
      run={goalId}
      onSuccess={onSuccessItems}
      params={[`${goalId}/items`, { ignorePagination: true }]}
    >
      <Axios
        api="goals"
        onSuccess={onSuccessTelesales}
        run={[goalId, items?.length ?? 0, run, { search }]}
        params={[
          `${goalId}/telesales`,
          {
            search,
            ignorePagination: true,
            include: 'telesales,goalItem,ranking',
          },
        ]}
      >
        <Card className="ant-card gx-card ant-card-bordered">
          <TableSearch
            ignorePagination
            columns={columns}
            dataSource={dataset}
            component={components}
            getSearch={onChangeSearch}
            title="dashboard.product.list.table.title"
            rowId="key"
          />
        </Card>
      </Axios>
    </Axios>
  );
};

export default ListProductTelesales;
