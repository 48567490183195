import { useEffect, useState } from 'react';

const useBodyClass = (
  className: string,
  timeout: number,
  isLoading: boolean,
  selectedClassName: string,
) => {
  const element = document.getElementById(selectedClassName);

  const [counter, setCounter] = useState(timeout);

  useEffect(() => {
    const timer =
      counter > 0 &&
      isLoading &&
      setInterval(() => {
        if (counter > 0 && isLoading) {
          setCounter(counter - 1000);
        }
        return;
      }, 1000);

    return () => {
      clearInterval(timer as NodeJS.Timeout);
    };
  }, [counter, isLoading]);

  useEffect(() => {
    if (element && element !== null) {
      if (counter <= 0 && timeout > 0) {
        element.classList.add(className);
      }
      if (!isLoading) {
        element.classList.remove(className);
      }
      return () => {
        element.classList.remove(className);
      };
    }
  });
};

export default useBodyClass;
