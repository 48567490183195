import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import axios from 'axios';

import { i18n } from '@components/Translate';
import apis from '@apis';
import ApiUtils from '@util/api.utils';
import { useUserContext } from '@context/UserContext';
import { useMessageContext } from '@context/MessageContext';

import { unauthorized, onUnprocessableEntity } from './errorHandler';

const Axios = ({ onError, onSuccess, ...props }) => {
  const history = useHistory();
  const userContext = useUserContext();

  const [response, setResponse] = useState({});
  const [loading, setLoading] = useState(false);
  const { showMessage } = useMessageContext();

  useEffect(() => {
    const initialize = () => {
      submitAsync();
    };
    if (Array.isArray(props.run) && props.run.every(item => item)) {
      initialize();
    } else if (props.run) {
      initialize();
    }
  }, [JSON.stringify(props.run)]);

  const submitAsync = async () => {
    await submit({});
  };

  const error = error => {
    if (onError) {
      onError(error?.response || error);
    } else {
      throw error;
    }
  };

  const success = ({ data }) => {
    if (onSuccess) {
      onSuccess(data);
    }
    setResponse(data);
  };

  const onUnauthorized = response => {
    const { logout } = userContext;
    unauthorized({ response, logout, history, showMessage });
  };

  const submit = async data => {
    const { body } = data || {};
    if (body) props = { ...props, body };
    try {
      setLoading(true);
      const response =
        (await http({ userContext, onUnauthorized, onUnprocessableEntity, ...props })) || {};
      success(response);
    } catch (err) {
      error(err);
    }
    setLoading(false);
  };

  const { children = null } = props;

  if (typeof children === 'function') {
    return children({
      submit,
      loading,
      response,
    });
  } else {
    return children;
  }
};

const http = async ({
  api,
  body,
  params,
  headers,
  userContext,
  onUnauthorized,
  onUnprocessableEntity,
  method = 'get',
}) => {
  const { token } = userContext;
  const urlBase = process.env.REACT_APP_API_URL;

  let { url } = apis.endpoints[api] || {};
  url = `${urlBase}${ApiUtils.getParams(url, params)}`;

  const header = {
    ...headers,
    Authorization: `Bearer ${token}`,
  };

  try {
    const response = await axios({
      url,
      method,
      data: body,
      headers: header,
    });
    return response;
  } catch (error) {
    handleError({ error, onUnauthorized, onUnprocessableEntity });
    throw error;
  }
};

const handleError = ({ error, onUnauthorized, onUnprocessableEntity }) => {
  const { response = {} } = error;

  if (!response.status) {
    error.data = i18n.t('unexpectedError');
    return error;
  }

  switch (response?.status) {
    case 401:
      onUnauthorized(response);
      break;
    case 422:
      error.unprocessableEntity = onUnprocessableEntity(response);
      break;
    default:
      return error;
  }
};

const getApi = api => {
  const urlBase = process.env.REACT_APP_API_URL;
  const { url } = apis.endpoints[api];
  return `${urlBase}${url}`;
};

const downloadFile = async ({ api, params, token, filename }) => {
  const urlBase = process.env.REACT_APP_API_URL;
  let { external, url } = apis.endpoints[api] || {};
  url = `${!external ? urlBase : ''}${ApiUtils.getParams(url, params)}`;
  const headers = external
    ? {}
    : {
        Authorization: `Bearer ${token}`,
      };
  const response = await axios({ url, headers, method: 'GET' });
  ApiUtils.download(response, filename);
};

export { getApi, http, downloadFile };
export default Axios;
