import React, { useState, useEffect } from 'react';

import * as XLSX from 'xlsx';
import { Card, Button, Row, Col } from 'antd';

import { useUserContext } from '@context/UserContext';
import { goalStatusMap } from '@util/enums';
import Axios from '@components/Axios';
import Translate from '@components/Translate';
import { isDealer } from '@util/user.utils';

import Upload from './components/Upload';
import TableList from './components/TableList';

import './styles.scss';

const UpdateBatch = ({ match, history }) => {
  const [file, setFile] = useState();
  const [data, setData] = useState([]);
  const [goalId, setGoalId] = useState({});
  const [goal, setGoal] = useState({});
  const [loading, setLoading] = useState(false);
  const { user } = useUserContext();

  const isManagerProperty = history.location.search.includes('manager');
  const isTelesalesProperty = history.location.search.includes('telesales');

  useEffect(() => {
    const id = match.params.id;
    setGoalId(id);
  }, [match.params.id]);

  useEffect(() => {
    if (
      isDealer(user) &&
      (goal.goalStatus === goalStatusMap.completed ||
        goal.goalStatus === goalStatusMap.inVerification)
    ) {
      history.replace(`/dashboard/${goal.id}`);
    }
  }, [goal.id]);

  const getData = () => {
    if (file) {
      setLoading(true);
      const reader = new FileReader();
      reader.onload = ({ target }) => {
        const newData = target.result;
        const newFile = XLSX.read(newData, { type: 'binary' });
        let workbook = JSON.stringify(newFile).replace(/\\u0000/g, '');
        workbook = JSON.parse(workbook);
        let result = XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {
          defval: '',
          blankrows: false,
        });
        setData(result);
        setLoading(false);
      };
      reader.readAsBinaryString(file);
    }
  };

  const getFileName = () => {
    if (isManagerProperty) {
      return 'managers';
    } else if (isTelesalesProperty) {
      return 'telesales';
    }
    return 'salespeople';
  };

  const onSuccessExport = successData => {
    const date = new Date();
    const link = document.createElement('a');
    link.target = '_blank';
    link.download = `${getFileName()}_${date.getTime()}.csv`;
    link.href = 'data:text/csv;charset=utf-8,' + encodeURI(successData);
    link.click();
  };

  const getComponent = () => {
    if (data.length) {
      return (
        <Card className="ant-card">
          <TableList
            file={file}
            data={data}
            goalId={goalId}
            isManager={isManagerProperty}
            isTelesales={isTelesalesProperty}
            setData={setData}
            history={history}
          />
        </Card>
      );
    } else {
      return (
        <Card className="ant-card update-batch-card">
          <Row justify="center">
            <Col>
              <Upload loading={loading} setFile={setFile} />
              <Row justify="center">
                <div className="dragger-message">
                  <b>
                    <Translate>updateBatch.message.title</Translate>
                  </b>
                  <p className="upload-text">
                    <Translate>updateBatch.message.paragraph.one</Translate>
                  </p>
                  <p className="upload-text">
                    <Translate>updateBatch.message.paragraph.two</Translate>
                  </p>
                  <p className="upload-text">
                    <Translate>updateBatch.message.paragraph.three</Translate>
                  </p>
                </div>
              </Row>
              <Row justify="space-between">
                <Axios api="goals" run={goalId} onSuccess={setGoal} params={goalId}>
                  <Axios
                    api="goals"
                    onSuccess={onSuccessExport}
                    params={`${goalId}/${getFileName()}`}
                    headers={{ Accept: 'text/csv' }}
                  >
                    {({ submit }) => (
                      <Button onClick={submit} className="update-batch-action">
                        <Translate>updateBatch.action.model</Translate>
                      </Button>
                    )}
                  </Axios>
                </Axios>
                <Button
                  disabled={!file}
                  onClick={getData}
                  className="update-batch-action"
                  type="primary"
                >
                  <Translate>updateBatch.action.send</Translate>
                </Button>
              </Row>
            </Col>
          </Row>
        </Card>
      );
    }
  };

  return <div className="UpdateBatch">{getComponent()}</div>;
};

export default UpdateBatch;
