export default [
  {
    id: '1',
    key: 'AC',
    name: 'Acre',
  },
  {
    id: '2',
    key: 'AL',
    name: 'Alagoas',
  },
  {
    id: '3',
    key: 'AM',
    name: 'Amazonas',
  },
  {
    id: '4',
    key: 'AP',
    name: 'Amapá',
  },
  {
    id: '5',
    key: 'BA',
    name: 'Bahia',
  },
  {
    id: '6',
    key: 'CE',
    name: 'Ceará',
  },
  {
    id: '7',
    key: 'DF',
    name: 'Distrito Federal',
  },
  {
    id: '8',
    key: 'ES',
    name: 'Espírito Santo',
  },
  {
    id: '9',
    key: 'GO',
    name: 'Goiás',
  },
  {
    id: '10',
    key: 'MA',
    name: 'Maranhão',
  },
  {
    id: '11',
    key: 'MG',
    name: 'Minas Gerais',
  },
  {
    id: '12',
    key: 'MS',
    name: 'Mato Grosso do Sul',
  },
  {
    id: '13',
    key: 'MT',
    name: 'Mato Grosso',
  },
  {
    id: '14',
    key: 'PA',
    name: 'Pará',
  },
  {
    id: '15',
    key: 'PB',
    name: 'Paraíba',
  },
  {
    id: '16',
    key: 'PE',
    name: 'Pernambuco',
  },
  {
    id: '17',
    key: 'PI',
    name: 'Piauí',
  },
  {
    id: '18',
    key: 'PR',
    name: 'Paraná',
  },
  {
    id: '19',
    key: 'RJ',
    name: 'Rio de Janeiro',
  },
  {
    id: '20',
    key: 'RN',
    name: 'Rio Grande do Norte',
  },
  {
    id: '21',
    key: 'RO',
    name: 'Rondônia',
  },
  {
    id: '22',
    key: 'RR',
    name: 'Roraima',
  },
  {
    id: '23',
    key: 'RS',
    name: 'Rio Grande do Sul',
  },
  {
    id: '24',
    key: 'SC',
    name: 'Santa Catarina',
  },
  {
    id: '25',
    key: 'SE',
    name: 'Sergipe',
  },
  {
    id: '26',
    key: 'SP',
    name: 'São Paulo',
  },
  {
    id: '27',
    key: 'TO',
    name: 'Tocantins',
  },
];
