// Actions
export const Types = {
  REQUEST: 'resetPassword/REQUEST',
  SUCCESS: 'resetPassword/SUCCESS',
  FAILURE: 'resetPassword/FAILURE',
  RESET: 'resetPassword/RESET',
};

// initial state
const initial = {
  data: null,
  error: null,
  status: '',
  resetPassword: [],
};

// Reducer
export default function reducer(state = initial, action = {}) {
  switch (action.type) {
    case Types.REQUEST: {
      return { ...state, status: 'processing', data: action.payload };
    }
    case Types.SUCCESS: {
      return { ...state, status: 'success', resetPassword: action.payload };
    }
    case Types.FAILURE: {
      return { ...state, status: 'failure' };
    }
    case Types.RESET: {
      return { ...initial };
    }
    default:
      return state;
  }
}

export const Creators = {
  request: payload => ({
    type: Types.REQUEST,
    payload,
  }),
  success: payload => ({
    type: Types.SUCCESS,
    payload,
  }),
  reset: () => ({
    type: Types.RESET,
  }),
};
