import React, { useEffect, useState } from 'react';

import { Button } from 'antd';

import { EditOutlined, CheckOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';
import Input from '@components/Input';
import { formatMoney } from '@util/global';
import DoneGoalPermissionWrapper from '@containers/Dashboard/components/DoneGoalPermissionWrapper';

import './styles.scss';

const UpdateGoalByProfile = ({
  api,
  item,
  field,
  updateScreen,
  enable = true,
  goalStatus,
  fieldId = 'salespersonId',
}) => {
  const { id, itemId, goalId, goalItemId } = item;

  useEffect(() => {
    setValue(item[field]);
  }, [item[field]]);

  const [value, setValue] = useState();
  const [enableEdit, setEnableEdit] = useState(false);

  const onSuccess = data => {
    if (updateScreen) {
      updateScreen(data);
    }
    setEnableEdit();
  };

  const getCustomInput = () => {
    if (enableEdit) {
      return (
        <Input
          prefix="R$"
          type="number"
          value={value}
          onChange={onChange}
          className="custom-input"
        />
      );
    } else {
      return <div>{formatMoney(value)}</div>;
    }
  };

  const getButtonComponent = ({ submit, disabled }) => {
    if (!enableEdit) {
      return (
        <DoneGoalPermissionWrapper goalStatus={goalStatus}>
          <Button type="link" onClick={toggleEdit} icon={<EditOutlined />} disabled={disabled} />
        </DoneGoalPermissionWrapper>
      );
    } else {
      return <Button type="link" onClick={submit} icon={<CheckOutlined />} />;
    }
  };

  const toggleEdit = () => {
    setEnableEdit(!enableEdit);
  };

  const onChange = ({ target: { value } }) => {
    setValue(value);
  };

  const contentItemAxios = ({ submit }) => {
    return (
      <div className="row-item-content">
        {getCustomInput()}
        {enable && <div className="action">{getButtonComponent({ submit })}</div>}
      </div>
    );
  };

  const createItem = () => {
    return (
      <Axios
        api="goals"
        method="POST"
        onSuccess={onSuccess}
        params={`${goalId}/${api}`}
        body={[{ goalId, goalItemId, [fieldId]: itemId, [field]: value }]}
      >
        {contentItemAxios}
      </Axios>
    );
  };

  const updateItem = () => {
    return (
      <Axios
        api="goals"
        method="put"
        onSuccess={onSuccess}
        body={{ [field]: value }}
        params={`${goalId}/${api}/${itemId}/values/${id}`}
      >
        {contentItemAxios}
      </Axios>
    );
  };

  return <div className="UpdateGoalByProfile">{id ? updateItem() : createItem()}</div>;
};

export default UpdateGoalByProfile;
