import React, { memo } from 'react';
import InputMask from 'react-input-mask';

import clsx from 'clsx';
import { Input as TextField } from 'antd';

import { Form } from '@ant-design/compatible';
import { EyeTwoTone, EyeInvisibleOutlined } from '@ant-design/icons';

import { i18n } from '@components/Translate';

import Switch from './components/Switch';
import Select from './components/Select';
import Number from './components/Number';
import Textarea from './components/Textarea';
import DatePicker from './components/DatePicker';

import './styles.scss';

const Input = props => {
  const onChange = event => {
    const { name, index, object } = props;
    if (typeof props.onChange === 'function') {
      const { value, type } = event.target;
      event.target = {
        type,
        name,
        index,
        value,
        object,
        ...event.target,
      };
      props.onChange(event);
    }
  };

  const label = () => {
    const { type, label: text } = props;
    if (text && type !== 'radio' && type !== 'checkbox') {
      return i18n.t(text);
    }
  };

  const inputProps = () => {
    const { placeholder, helperText, label } = props;
    return {
      ...props,
      label: i18n.t(label),
      handleChange: onChange,
      helperText: i18n.t(helperText),
      placeholder: i18n.t(placeholder),
    };
  };

  const switchType = () => {
    const { type, mask, disabled } = props;
    switch (type) {
      case 'date':
        return <DatePicker {...inputProps()} />;
      case 'datetime':
        return (
          <DatePicker
            showTime={{ format: 'HH:mm' }}
            format="DD/MM/YYYY HH:mm"
            returnFormat="YYYY-MM-DDTHH:mm:ssZ"
            {...inputProps()}
          />
        );
      case 'switch':
        return <Switch {...inputProps()} />;
      case 'select':
        return <Select {...inputProps()} />;
      case 'number':
        return <Number {...inputProps()} />;
      case 'textarea':
        return <Textarea {...inputProps()} />;
      case 'password':
        return (
          <TextField.Password
            iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
            {...inputProps()}
          />
        );
      default: {
        if (mask) {
          return (
            <InputMask {...inputProps()} onChange={onChange} formik={null}>
              {inputProps => <TextField disableUnderline {...inputProps} disabled={disabled} />}
            </InputMask>
          );
        } else {
          return <TextField {...inputProps()} onChange={onChange} formik={null} />;
        }
      }
    }
  };

  const { className, error } = props;
  const styles = clsx(['Input', className], { error });

  return (
    <Form.Item
      help={error}
      label={label()}
      className={styles}
      validateStatus={error ? 'error' : 'validating'}
    >
      {switchType()}
    </Form.Item>
  );
};

Input.defaultProps = {
  onChange: () => true,
};

export default memo(Input);
