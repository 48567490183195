import React from 'react';

import { Tooltip } from 'antd';

import { isDealer } from '@util/user.utils';
import { goalStatusMap } from '@util/enums';
import { useUserContext } from '@context/UserContext';
import { i18n } from '@components/Translate';

const DoneGoalPermissionWrapper = ({ goalStatus, children }) => {
  const { user } = useUserContext();

  const disabled =
    isDealer(user) && [goalStatusMap.completed, goalStatusMap.inVerification].includes(goalStatus);

  if (disabled) {
    return React.Children.map(children, child => (
      <Tooltip title={i18n.t('dashboard.forbiddenAction')}>
        <span>{React.cloneElement(child, { disabled })}</span>
      </Tooltip>
    ));
  }

  return children;
};

export default DoneGoalPermissionWrapper;
