import React, { useContext } from 'react';

import { Menu, Layout } from 'antd';
import { UIStateContext, ActionTypes } from 'context/UIContext';

import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';

import { LoadingBar } from '@components/LoadingBar';
import { getDomainData } from '@services/DiscernDomain';

import Logo from './components/Logo';
import Topbar from './components/Topbar';

import './styles.scss';

const { Header } = Layout;

export const HeaderNav = ({ isMobile }) => {
  const { state: UIState, dispatch: UIStateDispatch } = useContext(UIStateContext);
  const { SIDEBAR_MENU_EXPANDED } = UIState;

  const toggleSidebar = () => UIStateDispatch({ type: ActionTypes.TOGGLE_SIDEBAR });

  return (
    <Header className={`app-header`} style={{ backgroundColor: getDomainData('HEADER_BG_COLOR') }}>
      <LoadingBar />
      <div className={`app-header-wrapper`}>
        <Logo expanded={SIDEBAR_MENU_EXPANDED && !isMobile} />
        <div className="nav">
          <div className="nav-left">
            <Menu mode="horizontal">
              <Menu.Item key="0" onClick={toggleSidebar}>
                {SIDEBAR_MENU_EXPANDED ? (
                  <MenuFoldOutlined className="nav-icon" />
                ) : (
                  <MenuUnfoldOutlined className="nav-icon" />
                )}
              </Menu.Item>
            </Menu>
          </div>
          <div className="nav-right">
            <Topbar />
          </div>
        </div>
      </div>
    </Header>
  );
};
