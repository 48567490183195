/**
 * Given an object, returns the needed attribute
 * @param {Object} obj The object to be searched
 * @param {string} attribute The string containaing the attributes, separated by dot.
 * @returns {any} The found attribute or undefined
 */
export const get = (obj, attribute) => {
  return attribute.split('.').reduce((acc, cur) => acc?.[cur], obj);
};

/**
 * Transform an array of tuple into an object
 * @param {[[key: string, value: any]]} array The tuple array
 * @returns {Object} The reduced tuples
 */
export const arrayToObj = array => {
  if (!array?.length) {
    return {};
  }

  return array.reduce((acc, [key, value]) => {
    acc[key] = value;
    return acc;
  }, {});
};
