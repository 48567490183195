enum MOBILE_SIZES {
  PHONE_SIZE = 425,
  TABLET_SIZE = 768,
}

export const getBreakpoint = (size: number) => {
  return (s => {
    switch (true) {
      case s <= MOBILE_SIZES.PHONE_SIZE:
        return 'xs';
      case s > MOBILE_SIZES.PHONE_SIZE && s <= MOBILE_SIZES.TABLET_SIZE:
        return 'sm';
      default: {
        return 'md';
      }
    }
  })(size);
};

export const isPhone = (size: number) => size <= MOBILE_SIZES.PHONE_SIZE;
export const isTablet = (size: number) =>
  size <= MOBILE_SIZES.TABLET_SIZE && size > MOBILE_SIZES.PHONE_SIZE;
