import React from 'react';

import { Row, Col } from 'antd';

import { Form } from '@ant-design/compatible';

import Input from '@components/Input';

const FormComponent = ({ errors, values, handleSubmit, handleChange }) => {
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="name"
            onChange={handleChange}
            error={errors.name}
            value={values.name}
            label="salespeople.create.form.label.name"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="email"
            onChange={handleChange}
            error={errors.email}
            value={values.email}
            label="salespeople.create.form.label.email"
          />
        </Col>
      </Row>

      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="documentNumber"
            onChange={handleChange}
            mask="999.999.999-99"
            error={errors.documentNumber}
            value={values.documentNumber}
            label="salespeople.create.form.label.cpf"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="phone"
            mask="(99) 9 9999-9999"
            onChange={handleChange}
            error={errors.phone}
            value={values.phone}
            label="salespeople.create.form.label.phone"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
