export const unauthorized = ({ response, logout, showMessage }) => {
  logout();
  showMessage({
    type: 'error',
    message: response.data,
  });
};

export const onUnprocessableEntity = response => {
  if (!Array.isArray(response.data)) {
    return Object.entries(response.data).reduce((acc, [field, errors]) => {
      const errorMessage = errors?.map(error => error.message).join('\n');
      acc[field] = errorMessage;
      return acc;
    }, {});
  }
};
