import * as yup from 'yup';

import { getGoalSalespersonValues } from '@services/api';

const goalLookup = {
  lookupName: 'goal',
  api: 'goals',
  label: 'audit.list.filter.identification.goal',
  key: 'id',
  name: 'goalName',
  dependents: ['goalDealerValue', 'goalSalespersonValue'],
  getParams: () => ({ ignorePagination: true }),
};

const dealerLookup = {
  lookupName: 'dealer',
  api: 'dealers',
  label: 'audit.list.filter.identification.dealer',
  key: 'id',
  name: 'name',
  getParams: () => ({ ignorePagination: true }),
};

const salespersonLookup = {
  lookupName: 'salesperson',
  api: 'salespeople',
  label: 'audit.list.filter.identification.salesperson',
  key: 'id',
  name: 'name',
  getParams: () => ({ ignorePagination: true }),
};

const memberLookup = {
  lookupName: 'member',
  api: 'members',
  label: 'audit.list.filter.identification.member',
  key: 'id',
  name: 'memberName',
  getParams: () => ({ ignorePagination: true }),
};

const goalDealerLookup = {
  lookupName: 'goalDealerValue',
  api: 'goals',
  label: 'audit.list.filter.identification.dealer',
  key: 'id',
  name: 'dealer.name',
  dependsOn: ['goal'],
  getParams: ({ goal }) => [`${goal}/dealers`, { ignorePagination: true, include: 'dealer' }],
};

const goalSalespersonLookup = {
  lookupName: 'goalSalespersonValue',
  api: 'goals',
  label: 'audit.list.filter.identification.salesperson',
  key: 'salespersonId',
  name: 'salesperson.name',
  dependsOn: ['goal'],
  dependents: ['goalItem'],
  getParams: ({ goal }) => [
    `${goal}/salespeople`,
    { ignorePagination: true, include: 'salesperson', hasItem: false },
  ],
};

const goalItemLookup = {
  lookupName: 'goalItem',
  api: 'goals',
  label: 'audit.list.filter.identification.item',
  key: 'goalItem.id',
  name: 'goalItem.itemName',
  dependsOn: ['goal', 'goalSalespersonValue'],
  getParams: ({ goal, goalSalespersonValue }) => [
    `${goal}/salespeople/${goalSalespersonValue}/values`,
    { ignorePagination: true, include: 'goalItem', hasItem: true },
  ],
};

export default {
  Member: {
    lookups: [memberLookup],
    getIdentifier: ({ member }) => member,
  },
  Dealer: {
    lookups: [dealerLookup],
  },
  Salesperson: {
    lookups: [salespersonLookup],
    getIdentifier: ({ salesperson }) => salesperson,
  },
  Goal: {
    lookups: [goalLookup],
    getIdentifier: ({ goal }) => goal,
  },
  GoalDealerValue: {
    lookups: [goalLookup, goalDealerLookup],
    validationSchema: yup.object().shape({
      [goalDealerLookup.lookupName]: yup
        .mixed()
        .when([goalLookup.lookupName], (goal, schema) => goal && schema.required()),
    }),
    getIdentifier: ({ goalDealerValue }) => goalDealerValue,
  },
  GoalSalespersonValue: {
    lookups: [goalLookup, goalSalespersonLookup, goalItemLookup],
    validationSchema: yup.object().shape({
      [goalSalespersonLookup.lookupName]: yup
        .mixed()
        .when([goalLookup.lookupName], (goal, schema) => goal && schema.required()),
    }),
    getIdentifier: async ({ goal, goalSalespersonValue, goalItem }) => {
      if (!goal || !goalSalespersonValue) {
        return;
      }

      const params = { ignorePagination: true };
      if (!goalItem) {
        params.hasItem = false;
      } else {
        params.itemId = goalItem;
      }
      const values = await getGoalSalespersonValues(goal, goalSalespersonValue, params);
      return values[0]?.id;
    },
  },
};
