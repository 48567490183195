import React from 'react';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Modal, Spin, Typography } from 'antd';

const Loading = function () {
  const [t] = useTranslation();
  // const show = useSelector(store => store.loading.show);
  return (
    <Modal visible={false} footer={null} header={null} closable={false}>
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Spin />
      </div>
      <div style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
        <Typography.Title level={4}>{t('loading-message')}</Typography.Title>
      </div>
    </Modal>
  );
};

export default Loading;
