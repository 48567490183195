import React, { useState } from 'react';
import { Table, Tooltip, Input, Typography, Row, Button } from 'antd';
import { WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';

import Translate, { i18n } from '@components/Translate';

import Axios from '@components/Axios';

import Alert from './components/Alert';
import Footer from './components/Footer';

import './styles.scss';
import { useEffect } from 'react';

const { Title } = Typography;

const TableList = ({ file, data, goalId, setData, history, isManager, isTelesales }) => {
  const [dataFilter, setDataFilter] = useState([]);

  useEffect(() => {
    setDataFilter(data);
  }, [data.length]);

  const header = {
    title: 'updateBatch.tablelist.header.title',
    description: 'updateBatch.tablelist.header.description',
  };

  const [alert, setAlert] = useState();
  const [errors, setErrors] = useState([]);
  const [message, setMessage] = useState(header);

  const fields = {
    ID: 'id',
    'NOME DO VENDEDOR': 'name',
    CPF: 'cpf',
    'E-MAIL': 'email',
    META: 'goalValue',
    REALIZADO: 'actualValue',
  };

  const getItemId = key => {
    return String(key.split('-')[0].trim());
  };

  const verifyError = ({ item, key }) => {
    if (errors.length) {
      return errors.find(({ salespersonId, field, goalItemId, headerError }) => {
        const verifyID = String(salespersonId) === String(item?.ID) && !headerError;
        if (fields[key] && !goalItemId) {
          return (verifyID || (!item && headerError)) && fields[key] === field;
        } else {
          return (verifyID || (!item && headerError)) && String(goalItemId) === getItemId(key);
        }
      })?.message;
    }
  };

  const getClassTooltip = value => {
    const className = 'batch-table-tooltip';
    if (value) {
      return `${className}-error`;
    } else {
      return className;
    }
  };

  const getColumns = () => {
    const headers = data[0];
    return Object.keys(headers).map(key => {
      const messageHeader = verifyError({ key });
      return {
        dataIndex: key,
        className: 'batch-row-cell-table',
        onHeaderCell: () => {
          const message = messageHeader;
          return {
            className: message ? 'batch-header-table' : '',
          };
        },
        render(content, item) {
          const message = verifyError({ item, key });
          return {
            props: {
              className: message ? 'batch-cell-table' : '',
            },
            children: (
              <Tooltip className={getClassTooltip(message)} title={message}>
                {content}
              </Tooltip>
            ),
          };
        },
        title: (
          <Tooltip className={getClassTooltip(messageHeader)} title={messageHeader}>
            {key}
          </Tooltip>
        ),
      };
    });
  };

  const onSubmit = ({ submit }) => {
    const formData = new FormData();
    formData.append('file', file);
    submit({
      body: formData,
      headers: { 'Content-type': 'multipart/form-data;' },
    });
  };

  const onSearch = ({ target: { value } }) => {
    if (value) {
      const array = [];
      const columns = getColumns();
      for (const item of data) {
        for (const { dataIndex } of columns) {
          if (dataIndex) {
            const column = item[dataIndex];
            const result = String(column).toLowerCase().includes(String(value).toLowerCase());
            if (column && result) {
              array.push(item);
              break;
            }
          }
        }
      }
      setDataFilter(array);
    } else {
      setDataFilter(data);
    }
  };

  const onSuccess = () => {
    setAlertByType('success');
  };

  const onError = ({ data, status }) => {
    if (status === 422) {
      setErrors(data);
      setAlertByType('warning');
    } else {
      setAlertByType('error');
    }
    setMessage({
      title: 'updateBatch.tablelist.header.error.title',
      description: 'updateBatch.tablelist.header.error.description',
    });
  };

  const getAlertError = () => {
    return {
      icon: () => <WarningOutlined />,
      title: 'updateBatch.alert.error.title',
      subtitle: 'updateBatch.alert.error.subtitle',
      action: {
        text: 'updateBatch.alert.error.action',
        onClick: () => true,
      },
    };
  };

  const getAlertWarning = () => {
    return {
      icon: () => <WarningOutlined />,
      title: 'updateBatch.alert.warning.title',
      action: {
        text: 'updateBatch.alert.warning.action',
        onClick: () => setAlert(),
      },
    };
  };

  const getAlertSuccess = () => {
    return {
      icon: () => <CheckCircleOutlined />,
      title: 'updateBatch.alert.success.title',
      action: {
        text: 'updateBatch.alert.success.action',
        onClick: () => history.push('/goal/list'),
      },
    };
  };

  const setAlertByType = type => {
    switch (type) {
      case 'success':
        setAlert(getAlertSuccess());
        break;
      case 'warning':
        setAlert(getAlertWarning());
        break;
      case 'error':
        setAlert(getAlertError());
        break;
      default:
        setAlert();
    }
  };

  return (
    <div className="TableList">
      <Title level={3}>
        <Translate>{message.title}</Translate>
      </Title>
      <Title level={4}>
        <Translate>{message.description}</Translate>
      </Title>
      <div className="table-list-body">
        <Input.Search
          enterButton
          onSearch={onSearch}
          onChange={onSearch}
          placeholder={i18n.t('search')}
          className="default-header-search"
        />
        <Table columns={getColumns()} dataSource={dataFilter} scroll={{ x: true }} />
      </div>
      <Footer />
      <div className="table-list-footer">
        <Row justify="end">
          <Button onClick={() => setData([])}>
            <Translate>updateBatch.tablelist.action.cancel</Translate>
          </Button>
          <Axios
            api="goals"
            method="post"
            onError={onError}
            onSuccess={onSuccess}
            params={`${goalId}/${
              isManager ? 'managers' : isTelesales ? 'telesales' : 'salespeople'
            }/batch`}
          >
            {({ submit }) => (
              <Button onClick={() => onSubmit({ submit })} type="primary">
                <Translate>updateBatch.tablelist.action.confirm</Translate>
              </Button>
            )}
          </Axios>
        </Row>
      </div>
      <Alert visible={!!alert} onCancel={() => setAlert()} {...alert} />
    </div>
  );
};

export default TableList;
