class ApiUtils {
  _setParams = (others, value = '') => {
    if (typeof others === 'object') {
      const params = Object.entries(others)
        .filter(([, value]) => value || typeof value === 'boolean')
        .map(([key, value]) => `${key}=${value}`)
        .join('&');
      return `${value}?${params}`;
    } else {
      return `/${others}`;
    }
  };

  getParams(url, others) {
    let params = '';
    if (others) {
      if (Array.isArray(others)) {
        const [route, query] = others;
        params = this._setParams(query, `/${route}`);
      } else {
        params = this._setParams(others);
      }
    }
    return `${url}${params}`;
  }

  download = (response, filename = 'file') => {
    const { data, headers } = response;
    const link = document.createElement('a');
    if (headers['content-type'].includes('text/csv')) {
      link.href = 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
    } else {
      link.href = window.URL.createObjectURL(new Blob([data]));
    }
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  getCsvDownloadRef = data => 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURI(data);
}

export default new ApiUtils();
