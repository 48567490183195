import React from 'react';
import { useTranslation } from 'react-i18next';

import { Avatar, Dropdown, Menu } from 'antd';

import { LogoutOutlined, EditOutlined } from '@ant-design/icons';

import { history } from '@redux/store';
import { useUserContext } from '@context/UserContext';

import './styles.scss';

const UserInfo = () => {
  const { user, logout } = useUserContext();

  const [t] = useTranslation();
  const userFirstLetter = user?.name[0];

  const logoutUser = () => {
    logout();
  };

  const editProfile = () => {
    history.replace('/profile/edit');
    window.location.href = '/profile/edit';
  };

  const userMenuOptions = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar>
            <span>{userFirstLetter}</span>
          </Avatar>
          <div className="pl-3 user-info">
            <h4 className="mb-0">{user?.name}</h4>
            <span className="text-muted">{user?.login}</span>
          </div>
        </div>
      </div>
      <div className="nav-body">
        <Menu>
          {user?.profile !== 'SYSADMIN' && (
            <Menu.Item>
              <div onClick={editProfile} aria-hidden="true">
                <EditOutlined />
                &nbsp; {t('editProfile')}
              </div>
            </Menu.Item>
          )}
          <Menu.Item>
            <div onClick={logoutUser} aria-hidden="true">
              <LogoutOutlined />
              &nbsp; {t('logout')}
            </div>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <Dropdown placement="bottomRight" overlay={userMenuOptions} trigger={['click']}>
      <div className="nav-item-icon">
        <Avatar className="gx-avatar gx-pointer" alt="">
          {userFirstLetter}
        </Avatar>
        <span className="username-text">{user?.name}</span>
      </div>
    </Dropdown>
  );
};

export default UserInfo;
