import React from 'react';

import { Skeleton, Button } from 'antd';

import Translate from '@components/Translate';

import './styles.scss';

const ButtonComponent = ({ className, icon, type, onClick, children, disabled }) => {
  const getContentText = () => {
    if (typeof children !== 'string') {
      return children;
    } else {
      return <Translate>{children}</Translate>;
    }
  };

  const getComponent = () => {
    if (!children) {
      return <Skeleton.Button active size="small" shape="default" />;
    } else {
      return (
        <Button className={className} icon={icon} type={type} onClick={onClick} disabled={disabled}>
          {getContentText()}
        </Button>
      );
    }
  };

  return <div className="Button">{getComponent()}</div>;
};
export default ButtonComponent;
