import React, { useEffect, useState } from 'react';

import { Table, Empty, Card, Row, Button } from 'antd';
import get from 'lodash/get';

import { DeleteOutlined } from '@ant-design/icons';

import { sortText } from '@util/columnSorter';
import Axios from '@components/Axios';
import Input from '@components/Input';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';
import AddTelesalesGoal from '@components/AddTelesalesGoal';

import ExpandedRow from './components/ExpandedRow';

import './styles.scss';

const ItemTelesales = ({
  goalId,
  telesales,
  dealers,
  touched,
  component,
  setTouched,
  selectDealer,
  updateScreen,
  productsSnapshot,
}) => {
  const [list, setList] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [parentId, setParentId] = useState();
  const [products, setProducts] = useState([]);
  const [deletingId, setDeletingId] = useState();
  const [addTelesalesVisible, setAddTelesalesVisible] = useState(false);
  const [filterDataSetTelesales, setFilterDataSetTelesales] = useState([]);

  const columns = [
    {
      dataIndex: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => sortText(a, b, 'name'),
      title: i18n.t('goalTelesales.telesalesName'),
    },
    {
      title: i18n.t('goalTelesales.goal'),
      render: (_, { id, telesalesId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, telesalesId, goalValue, actualValue };
        const value = getInputValue(`${key}.goalValue`, goalValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="goalValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('goalTelesales.done'),
      render: (_, { id, telesalesId, goalValue, actualValue }) => {
        const key = `s-${id}`;
        const object = { key, id, telesalesId, goalValue, actualValue };
        const value = getInputValue(`${key}.actualValue`, actualValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="actualValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('tableActions'),
      render: (_, { telesalesId: id }) => (
        <Popconfirm
          title="confirmDelete"
          onSuccess={onSuccessDelete}
          visible={deletingId === id}
          onClick={() => setDeletingId(id)}
          onCancel={() => setDeletingId()}
          axios={{
            api: 'goals',
            method: 'delete',
            params: `${goalId}/telesales/${id}/values`,
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  useEffect(() => {
    const data = groupTelesales(telesales);
    setList(data);
  }, [telesales]);

  useEffect(() => {
    includeItemInTelesales();
  }, [list, products]);

  const groupTelesales = telesales => {
    if (!Array.isArray(telesales)) {
      return [];
    }

    const grouped = telesales.reduce((acc, { id, goalValue, actualValue, telesales, goalItem }) => {
      let entry = acc[telesales.id] || { ...telesales, telesalesId: telesales.id };
      const object = {
        id,
        goalValue,
        actualValue,
        telesalesId: telesales.id,
      };
      if (!goalItem) {
        entry = { ...entry, ...object };
      } else {
        const goalItems = entry.goalItems || [];
        entry.goalItems = [
          ...goalItems,
          {
            ...object,
            goalItemId: goalItem.id,
            itemName: goalItem.itemName,
          },
        ];
      }
      acc[telesales.id] = entry;
      return acc;
    }, {});
    return Object.values(grouped);
  };

  const includeItemInTelesales = () => {
    const dataset = list.map(telesales => {
      const { goalItems = [] } = telesales;
      const missingProducts =
        products
          ?.filter(({ id }) => !goalItems.find(({ goalItemId }) => goalItemId === id))
          ?.map(({ id, itemName }) => ({ goalItemId: id, itemName, actualValue: 0 })) ?? [];
      return {
        ...telesales,
        goalItems: [...goalItems, ...missingProducts],
      };
    });
    setDataset(dataset);
    setFilterDataSetTelesales(dataset);
  };

  const onChangeValue = ({ target: { name, value, object = {} } }) => {
    if (!touched[object.key]) {
      object[name] = value;
      touched[object.key] = { ...object, goalId };
    } else {
      touched[object.key][name] = value;
    }
    setTouched({ ...touched });
  };

  const toggleAddTelesales = () => {
    setAddTelesalesVisible(!addTelesalesVisible);
  };

  const onSuccessItem = items => {
    setProducts(items);
  };

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddTelesales = () => {
    setAddTelesalesVisible(!addTelesalesVisible);
    updateScreen();
  };

  const getInputValue = (key, oldValue) => {
    const newValue = get(touched, key);
    if (newValue !== undefined) {
      return newValue;
    } else {
      return oldValue || 0;
    }
  };

  const onAddTelesales = ({ body, submit }) => {
    const data = body
      .filter(item => item && !telesales.find(mnger => item?.id === mnger.telesalesId))
      .map(item => {
        return {
          goalId,
          goalValue: 0,
          actualValue: 0,
          telesalesId: item.id,
        };
      });
    submit({ body: data });
  };

  const onChangeSelect = ({ target: { value } }) => {
    if (value) {
      const list = dataset.filter(({ dealerId }) => dealerId === +value);
      setFilterDataSetTelesales(list);
    } else {
      setFilterDataSetTelesales(dataset);
    }
    setParentId(value);
  };

  const selectDealerComponent = () => {
    return (
      <Input
        type="select"
        array={dealers}
        value={parentId}
        style={{ width: 200 }}
        onChange={onChangeSelect}
        placeholder={'goalTelesales.selectDealer'}
        option={{
          key: 'id',
          name: 'name',
        }}
      />
    );
  };

  const getAction = () => {
    return <Button onClick={toggleAddTelesales}>{i18n.t('goalTelesales.addTelesales')}</Button>;
  };

  const getComponent = () => {
    if (!dataset.length) {
      return <Empty description={i18n.t('goalTelesales.noTelesalesYet')}>{getAction()}</Empty>;
    } else {
      return (
        <Card>
          <Row className="header" justify="space-between">
            <div>{selectDealer && selectDealerComponent()}</div>
            <div className="header-container">
              {component && component()}
              <div className="header-action-add">{getAction()}</div>
            </div>
          </Row>
          <Table
            columns={columns}
            pagination={false}
            rowKey={record => record.id}
            dataSource={filterDataSetTelesales}
            expandedRowRender={items => (
              <ExpandedRow {...items} getInputValue={getInputValue} onChangeValue={onChangeValue} />
            )}
          />
        </Card>
      );
    }
  };

  return (
    <div className="ItemTelesales">
      <Axios
        api="goals"
        onSuccess={onSuccessItem}
        run={[list, productsSnapshot]}
        params={[`${goalId}/items`, { ignorePagination: true }]}
      >
        {getComponent()}

        <Axios
          api="goals"
          method="post"
          params={`${goalId}/telesales`}
          onSuccess={onSuccessAddTelesales}
        >
          {({ submit }) => (
            <AddTelesalesGoal
              goalId={goalId}
              dealers={dealers}
              listTelesales={telesales}
              onCancel={toggleAddTelesales}
              isVisible={addTelesalesVisible}
              onAdd={body => onAddTelesales({ body, submit })}
            />
          )}
        </Axios>
      </Axios>
    </div>
  );
};

export default ItemTelesales;
