import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { Row, Col, Button, Typography, message } from 'antd';
import * as yup from 'yup';

import { Form } from '@ant-design/compatible';

import * as icons from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { validatePassword } from '@util/passwordValidation';
import { api, request } from '@util/handlerRequestUtil';
import { Creators as resetPasswordActions } from '@redux/ducks/auth/resetPassword';
import FormSubmit from '@components/FormSubmit';
import Input from '@components/Input';
import { getHostId } from '@services/DiscernDomain';

const { Title } = Typography;

const PasswordRecovery = function () {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(history.location.search);
  const token = params.get('token') || null;
  const [step, setStep] = useState(
    token === null || token === undefined || token === '' ? 'email' : 'formVerification',
  );

  const resetPassword = useSelector(state => state.resetPassword);
  const passwordRecovery = useSelector(state => state.passwordRecovery);

  useEffect(() => {
    return () => {
      setStep('email');
    };
  }, []);

  useEffect(() => {
    passwordRecovery.status === 'success' && setStep('formVerification');
  }, [passwordRecovery.status]);

  useEffect(() => {
    resetPassword.status === 'success' &&
      history.push('/login') &&
      dispatch(resetPasswordActions.reset());
  }, [resetPassword.status]);

  const formEmail = {
    initialValues: {},
    validationSchema: yup.object().shape({
      email: yup.string().email().required(),
    }),
    onSubmit: async data => {
      try {
        const { values } = data;
        await request(
          api.passwordRecovery({
            emailAddress: values.email,
            emailType: 'userPasswordRecovery',
            domain: getHostId(),
          }),
          false,
          true,
        );
        setStep('sentVerificationCode');
      } catch (error) {
        message.error(t(error.data), 5);
      }
    },
  };

  const formVerification = {
    initialValues: {},
    validationSchema: yup.object().shape({
      typeNewPassword: yup
        .string()
        .min(6)
        .required()
        .test({
          name: 'password',
          message: t('login.warning.invalidPassword'),
          test: value => validatePassword(value),
        }),
    }),
    onSubmit: async data => {
      try {
        const { values } = data;
        await request(
          api.resetPassword({ token, userPassword: values.typeNewPassword }),
          false,
          true,
        );
        setStep('passwordModified');
      } catch (error) {
        message.error(t(error.data), 5);
      }
    },
  };

  const EmailForm = () => (
    <FormSubmit
      {...formEmail}
      onSubmit={(values, event) => formEmail.onSubmit({ ...event, values })}
    >
      {({ handleSubmit, handleChange, errors, values }) => (
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={20} type="flex">
            <Col xs={24} md={24}>
              <Input
                name="email"
                onChange={handleChange}
                error={errors.email}
                value={values.email}
                label="email"
              />
            </Col>
          </Row>
          <Button
            onClick={handleSubmit}
            className="ant-btn ant-btn-primary ant-btn-block login-btn"
          >
            {t('forgotPass.sendRequest')}
          </Button>
          <Col sm={24} xs={24} style={{ marginTop: '20px' }}>
            <Button
              className="anchor"
              onClick={() => {
                console.tron.log('history', history);
                history.push('/login');
              }}
            >
              {t('forgotPass.backToLogin')}
            </Button>
          </Col>
        </Form>
      )}
    </FormSubmit>
  );

  const FormVerification = () => (
    <FormSubmit
      {...formVerification}
      onSubmit={(values, event) => formVerification.onSubmit({ ...event, values })}
    >
      {({ handleSubmit, handleChange, errors, values }) => (
        <Form layout="vertical" onSubmit={handleSubmit}>
          <Row gutter={20} type="flex">
            <Col xs={24} md={24}>
              <Input
                title={t('password-pattern')}
                icon="info-circle"
                type="password"
                prefix={
                  <FontAwesomeIcon icon={icons.faKey} size="sm" style={{ marginRight: 10 }} />
                }
                onChange={handleChange}
                name="typeNewPassword"
                error={errors.typeNewPassword}
                value={values.typeNewPassword}
                label={t('forgotPass.newPassword')}
              />
            </Col>
          </Row>
          <div className="containerFormActions">
            <Button
              type="primary"
              onClick={handleSubmit}
              className="ant-btn ant-btn-primary ant-btn-block"
            >
              {t('register')}
            </Button>
          </div>
        </Form>
      )}
    </FormSubmit>
  );

  const SentVerificationCode = () => (
    <>
      <p>{t('common.Change.Password.Instructions')}</p>
      <Button
        className="ant-btn ant-btn-primary ant-btn-block"
        type="primary"
        onClick={() => history.push('/login')}
      >
        {t('login.Authentication.backToLogin')}
      </Button>
    </>
  );

  const PasswordModified = () => (
    <>
      <p>{t('login.Authentication.RecoveryPassword.NewPassword.Success.Info')}</p>
      <Button
        className="ant-btn ant-btn-primary ant-btn-block"
        type="primary"
        onClick={() => history.push('/login')}
      >
        {t('login.Authentication.backToLogin')}
      </Button>
    </>
  );

  return (
    <Col span={24}>
      <Title level={4}>
        <label style={{ textAlign: 'left', color: '#3e82f7', letterSpacing: '-0.6px' }}>
          {t('forgotPass.forgotPassword')}
        </label>
      </Title>
      {step === 'email' && <EmailForm />}
      {step === 'formVerification' && <FormVerification />}
      {step === 'sentVerificationCode' && <SentVerificationCode />}
      {step === 'passwordModified' && <PasswordModified />}
    </Col>
  );
};

export default PasswordRecovery;
