// Actions
export const Types = {
  CHANGE: 'changeLanguage',
};

// initial state
const initial = {
  lng: window.navigator.userLanguage || window.navigator.language,
  i18n: null,
  isBrowserChoice: null,
};

// Reducer
export default function reducer(state = initial, action = {}) {
  switch (action.type) {
    case Types.CHANGE: {
      action.payload.i18n.changeLanguage(action.payload.lng);
      return { ...state, lng: action.payload.lng };
    }
    default:
      return state;
  }
}

export const Creators = {
  changeLanguage: payload => ({
    type: Types.CHANGE,
    payload,
  }),
};
