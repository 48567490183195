export function debounce(fn, extractArgs, time = 500) {
  let timeout;
  return function executeDebounce(args) {
    const params = extractArgs ? extractArgs(args) : args;
    const later = () => {
      clearInterval(timeout);
      fn(params);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, time);
  };
}
