import { TSR, SALES, ADMIN, DEALER, MANAGER, SYSADMIN, TELESALES } from '@constants/profiles';

const isAdmin = ({ profile }) => {
  return [ADMIN, SYSADMIN].includes(profile);
};

const isTSR = ({ profile }) => {
  return TSR === profile;
};

const isSales = ({ profile }) => {
  return SALES === profile;
};

const isDealer = ({ profile }) => {
  return DEALER === profile;
};

const isManager = ({ profile }) => {
  return MANAGER === profile;
};

const isTelesales = ({ profile }) => {
  return TELESALES === profile;
};

export { isAdmin, isTSR, isSales, isManager, isTelesales, isDealer };
