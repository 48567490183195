/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';

import { Card } from 'antd';

import { LoadingOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';
import Translate from '@components/Translate';
import download from '@assets/images/banners/download.jpg';
import { getFormatDate } from '@util/global';

import './styles.scss';

const CardGoal = ({ item, goTo, termsByRule }) => {
  const [logo, setLogo] = useState({});

  const { id, goalLogoId, goalName, startDate, endDate } = item;

  const onSuccessLogo = data => {
    setLogo(data);
  };

  const getImageComponent = () => {
    if (goalLogoId) {
      if (logo?.signedUrl) {
        return <img alt="logo" src={logo?.signedUrl} />;
      } else {
        return <LoadingOutlined size="large" />;
      }
    } else {
      return <img alt="logo" src={download} />;
    }
  };

  return (
    <div className="CardGoal" onClick={termsByRule(id, () => goTo(`/dashboard/${id}`))}>
      <Axios
        run={goalLogoId}
        api="attachments"
        onSuccess={onSuccessLogo}
        params={`/${goalLogoId}/signed`}
      >
        <Card hoverable cover={<div className="card-image">{getImageComponent()}</div>}>
          <div className="card-title">{goalName}</div>
          <div className="card-main">
            <p>
              <Translate>listgoal.cardgoal.startDate</Translate>:
              <span>{getFormatDate(startDate)}</span>
            </p>
            <p>
              <Translate>listgoal.cardgoal.endDate</Translate>:<span>{getFormatDate(endDate)}</span>
            </p>
          </div>
        </Card>
      </Axios>
    </div>
  );
};

export default CardGoal;
