import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Upload, Card, Button, Modal, message } from 'antd';

import { EyeOutlined, DeleteOutlined } from '@ant-design/icons';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import PopConfirm from '@components/Popconfirm';

import './styles.scss';

export const UploadImage = ({
  accept = ['image/jpeg', 'image/png'],
  maxImageSizeInKb = 2048,
  imageUrl,
  imageId,
  action,
  onDelete,
}) => {
  const [t] = useTranslation();
  const [loading, setLoading] = useState(false);
  const [showPreview, setShowPreview] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const beforeUpload = file => {
    const isOversized = file.size / 1024 > maxImageSizeInKb;
    if (isOversized) {
      message.error(t('uploadImage.maxLength', { size: maxImageSizeInKb, unit: 'Kb' }));
    }

    return !isOversized;
  };

  const handleChange = info => {
    const { status } = info.file;
    if (status === 'uploading') {
      setLoading(true);
    } else {
      setLoading(false);
    }
  };

  const uploadButton = (
    <div>
      <LegacyIcon type={loading ? 'loading' : 'plus'} />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  if (imageUrl) {
    return (
      <>
        <Card
          className="display-card"
          cover={<img src={imageUrl} className="display-card-cover" alt="Display card" />}
          actions={[
            <Button type="link" icon={<EyeOutlined />} onClick={() => setShowPreview(true)} />,
            <PopConfirm
              visible={showDelete}
              title="confirmDelete"
              axios={{
                api: 'attachments',
                method: 'delete',
                params: imageId,
              }}
              onClick={() => setShowDelete(true)}
              onCancel={() => setShowDelete(false)}
              onSuccess={() => {
                setShowDelete(false);
                setLoading(false);
                onDelete && onDelete();
              }}
            >
              <DeleteOutlined />
            </PopConfirm>,
          ]}
        />
        <Modal visible={showPreview} footer={null} onCancel={() => setShowPreview(false)}>
          <img src={imageUrl} style={{ width: '100%' }} alt="Img" />
        </Modal>
      </>
    );
  } else {
    return (
      <Upload
        accept={accept.join(', ')}
        listType="picture-card"
        showUploadList={false}
        customRequest={action}
        beforeUpload={beforeUpload}
        onChange={handleChange}
      >
        {uploadButton}
      </Upload>
    );
  }
};
