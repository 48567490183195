import React from 'react';
import ReactDOM from 'react-dom';
import { AppContainer } from 'react-hot-loader';

import { init as initDiscernDomain } from '@services/DiscernDomain';

import App from './App';

import './i18n';

// Wrap the rendering in a function:
const render = () => {
  ReactDOM.render(
    // Wrap App inside AppContainer
    <AppContainer>
      <App />
    </AppContainer>,
    document.getElementById('root'),
  );
};

// Update Window Title before render.
initDiscernDomain();

// Render once
render();
