import PasswordValidator from 'password-validator';

const schema = new PasswordValidator();

schema
  .is()
  .min(6)
  .has()
  .digits()
  .has()
  .uppercase()
  .has()
  .lowercase()
  .has()
  .symbols()
  .has()
  .not()
  .spaces()
  .is()
  .not()
  .oneOf(['Passw0rd', 'Password123']);

export const validatePassword = password => {
  return schema.validate(password);
};
