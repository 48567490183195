import React from 'react';

import { getDomainData } from '@services/DiscernDomain';
import useLazyAsset from '@hooks/useLazyAsset';

const APP_LOGO_ALT_TEXT = 'Header logo';

const Logo = ({ expanded }) => {
  const LOGO_SMALL = useLazyAsset({ asset: getDomainData('LOGO_SMALL') });
  const LOGO_BIG = useLazyAsset({ asset: getDomainData('LOGO_WHITE_BIG') });
  const minimizedClass = expanded ? '' : 'minimized';

  const HEADER_LOGO = expanded ? LOGO_BIG : LOGO_SMALL;

  return (
    <div className={`app-header-logo ${minimizedClass}`}>
      <img
        width={expanded ? 180 : 40}
        className={`header-img`}
        src={HEADER_LOGO}
        alt={APP_LOGO_ALT_TEXT}
      />
    </div>
  );
};

export default Logo;
