import React, { useState } from 'react';

import { Switch, Row } from 'antd';

import CsvBatchImport from '@components/CsvBatchImport';
import Translate from '@components/Translate';

const columns = {
  dealerId: 'Distribuidor - CNPJ',
  name: 'NOME',
  email: 'E-MAIL',
  documentNumber: 'CPF',
  phone: 'TELEFONE',
  canAccessPortal: 'ACESSO AO PORTAL?',
};

const BatchImport = ({ history }) => {
  const [update, setUpdate] = useState(false);

  const axiosConfig = {
    api: 'telesales',
    method: 'post',
    params: ['/batch', { update }],
  };

  const successMessageConfig = {
    title: 'telesales.batchImport.successMessage.title',
    subtitle: 'telesales.batchImport.successMessage.subtitle',
    action: {
      onClick: () => history.push('/telesales/list'),
    },
  };

  return (
    <CsvBatchImport
      columns={columns}
      axiosConfig={axiosConfig}
      infoMessages={['telesales.batchImport.extraMessages.accessCampaign']}
      fileName="modelo_televendas"
      title="telesales.batchImport.header.sent.title"
      subtitle="telesales.batchImport.header.sent.subtitle"
      successMessageConfig={successMessageConfig}
    >
      <Row style={{ marginBottom: '10px' }}>
        <Switch checked={update} onChange={setUpdate} style={{ marginRight: '10px' }} />
        <Translate>telesales.batchImport.input.replaceExistingData</Translate>
      </Row>
    </CsvBatchImport>
  );
};

export default BatchImport;
