import React, { useContext } from 'react';

import { Drawer, Layout } from 'antd';

import { isPhone } from '@util/isMobileScreen';
import CustomScrollbars from '@util/CustomScrollbars';

import useWindowSize from '@hooks/useWindowResize';
import { UIStateContext, ActionTypes } from '@context/UIContext';
import { CONST_UI_SIDEBAR_WIDTH, CONST_UI_SIDEBAR_MIN_WIDTH } from '@constants/UI';

import SidebarContent from './components/SidebarContent';

import './styles.scss';

const { Sider } = Layout;

const SidebarComponent = () => {
  const { state: UIState, dispatch: UIStateDispatch } = useContext(UIStateContext);
  const { SIDEBAR_MENU_EXPANDED, THEME_TYPE } = UIState;
  const { width } = useWindowSize();
  const SCREEN_IS_PHONE = isPhone(width);

  const toggleSidebar = () => UIStateDispatch({ type: ActionTypes.TOGGLE_SIDEBAR });

  return (
    <Sider
      className={`side-nav ${THEME_TYPE}`}
      width={CONST_UI_SIDEBAR_WIDTH}
      collapsedWidth={SCREEN_IS_PHONE ? 0 : CONST_UI_SIDEBAR_MIN_WIDTH}
      collapsed={!SIDEBAR_MENU_EXPANDED}
      theme={THEME_TYPE}
    >
      {SCREEN_IS_PHONE ? (
        <Drawer
          placement="left"
          closable={false}
          onClose={toggleSidebar}
          visible={SIDEBAR_MENU_EXPANDED}
          className={`app-drawer-sidebar ${THEME_TYPE}`}
        >
          <SidebarContent drawerClick={toggleSidebar} />
        </Drawer>
      ) : (
        <CustomScrollbars>
          <SidebarContent />
        </CustomScrollbars>
      )}
    </Sider>
  );
};

export default SidebarComponent;
