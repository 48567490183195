import * as yup from 'yup';

const commonValidation = (minDate, maxDate) => {
  return {
    startDate: yup.date().required().min(minDate).max(maxDate),
    endDate: yup
      .date()
      .required()
      .min(minDate)
      .max(maxDate)
      .when(['startDate'], (startDate, schema) => startDate && schema.min(startDate)),
  };
};

export const createNewItemValidation = (minDate, maxDate) =>
  yup.object().shape({
    itemName: yup.string().required().max(100),
    ...commonValidation(minDate, maxDate),
  });

export const createItemConfigValidation = (minDate, maxDate) =>
  yup.object().shape({
    ...commonValidation(minDate, maxDate),
  });
