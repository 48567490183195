const getFieldsValue = (a, b, field) => {
  let aValue;
  let bValue;
  if (Array.isArray(field)) {
    const fields = [...field];
    aValue = { ...a };
    bValue = { ...b };
    while (fields.length) {
      const [index] = fields.splice(0, 1);
      aValue = aValue[index];
      bValue = bValue[index];
    }
  } else {
    aValue = a?.[field];
    bValue = b?.[field];
  }
  return { aValue, bValue };
};

export const sortText = (a, b, field) => {
  const { aValue = '', bValue = '' } = getFieldsValue(a, b, field);
  return aValue.toLowerCase().localeCompare(bValue.toLowerCase());
};

export const sortNumber = (a, b, field) => {
  const { aValue = 0, bValue = 0 } = getFieldsValue(a, b, field);
  return aValue - bValue;
};
