import React from 'react';

import { Card } from 'antd';

import Tag from '@components/Tag';
import TableSearch from '@components/TableSearch';
import { i18n } from '@components/Translate';

import './styles.scss';

const ListUser = () => {
  const tableColumns = [
    {
      title: i18n.t('members.list.table.header.status'),
      dataIndex: 'status',
      render: (_, { active }) => {
        const type = active ? 'checkedChildren' : 'unCheckedChildren';
        return (
          <Tag color={active ? 'success' : 'error'}>
            {`members.list.table.header.status.${type}`}
          </Tag>
        );
      },
    },
    {
      title: i18n.t('members.list.table.header.name'),
      dataIndex: 'name',
      render: (_, { telesales, salesperson, manager, member, dealer }) => {
        return (
          <div>
            {telesales?.name
              ? telesales?.name
              : salesperson?.name
              ? salesperson?.name
              : manager?.name
              ? manager?.name
              : member?.memberName
              ? member?.memberName
              : dealer?.name}
          </div>
        );
      },
    },
    {
      title: i18n.t('members.list.table.header.email'),
      dataIndex: 'login',
    },
    {
      title: i18n.t('members.list.table.header.function'),
      dataIndex: 'relatedWith',
      render: relatedWith => i18n.t(`users.create.form.userTypes.${relatedWith}`),
    },
  ];

  const axios = {
    run: true,
    api: 'users',
    params: {
      select: 'id,login,active,relatedWith',
      include: 'telesales,salesperson,manager,member,dealer',
    },
  };

  return (
    <div className="ListMember">
      <Card className="ant-card gx-card ant-card-bordered">
        <TableSearch axios={axios} columns={tableColumns} />
      </Card>
    </div>
  );
};

export default ListUser;
