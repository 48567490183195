import React from 'react';
import { Choose } from 'react-extras';
import { connect } from 'react-redux';

import { Breadcrumb } from 'antd';

import { HomeFilled } from '@ant-design/icons';

import { i18n } from '@components/Translate';

import './style.scss';

const CustomBreadcrumb = ({ paths, history }) => {
  const renderPath = () => {
    return paths.map((item, index) => {
      const condition = item.pathname === window.location.pathname;
      return (
        <Breadcrumb.Item key={index} onClick={() => history.push(item.pathname)}>
          <Choose>
            <Choose.When condition={condition}>{i18n.t(`breadcrumb.${item.label}`)}</Choose.When>
            <Choose.Otherwise>
              <span>{i18n.t(`breadcrumb.${item.label}`)}</span>
            </Choose.Otherwise>
          </Choose>
        </Breadcrumb.Item>
      );
    });
  };

  return (
    <Breadcrumb>
      <Breadcrumb.Item onClick={() => history.push('/goal/list')}>
        <HomeFilled />
      </Breadcrumb.Item>
      {renderPath()}
    </Breadcrumb>
  );
};

const mapStateToProps = lang => {
  const { lng } = lang;
  return {
    lng,
  };
};

export default connect(mapStateToProps)(CustomBreadcrumb);
