import React, { useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { useUserContext } from '@context/UserContext';
import Login from '@containers/Login';
import AccessControl from '@containers/AccessControl';
import Layout from '@components/Layout';

const Routes = () => {
  const { token } = useUserContext();
  const location = useLocation();
  const [isGTMInitialized, setIsGTMInitialized] = useState(false);
  const gtmId = 'GTM-P6RP4DK6';

  useEffect(() => {
    if (!isGTMInitialized) {
      TagManager.initialize({
        gtmId,
      });
      setIsGTMInitialized(true);
    }

    TagManager.dataLayer({
      dataLayer: {
        event: 'pageview',
        page: {
          path: location.pathname,
          title: document.title,
          authenticated: !!token,
        },
      },
    });
  }, [location.pathname, token, isGTMInitialized, gtmId]);

  return (
    <Switch>
      <Route path="/access-control/:token" component={AccessControl} />
      {!token && <Route path="/" component={Login} />}
      {token && <Route path="/" component={Layout} />}
    </Switch>
  );
};

export default Routes;
