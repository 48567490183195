export default {
  endpoints: {
    login: {
      url: '/auth/login',
    },
    attachments: {
      url: '/attachments',
    },
    audit: {
      url: '/audit',
    },
    auth: {
      url: '/auth',
    },
    cities: {
      url: '/cities',
    },
    'customer-types': {
      url: '/customer-types',
    },
    dealers: {
      url: '/dealers',
    },
    salespeople: {
      url: '/salespeople',
    },
    managers: {
      url: '/managers',
    },
    telesales: {
      url: '/telesales',
    },
    goals: {
      url: '/goals',
    },
    'goal-items': {
      url: '/goal-items',
    },
    members: {
      url: '/members',
    },
    'member-types': {
      url: '/member-types',
    },
    notifications: {
      url: '/notifications',
    },
    parameters: {
      url: '/parameters',
    },
    portalAccessControl: {
      url: '/portal-access-control',
    },
    profiles: {
      url: '/profiles',
    },
    users: {
      url: '/users',
    },
  },
  routes: {
    publics: ['/login', '/login/'],
  },
};
