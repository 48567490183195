import React, { useState } from 'react';

import { Card, Statistic, Modal, Table, Row, Col } from 'antd';
import moment from 'moment';

import Axios from '@components/Axios';
import { i18n } from '@components/Translate';

import OperationTag from './OperationTag';
import { auditModels, detailFields } from './auditEnums';

const AuditDetailModal = ({ id, onClose }) => {
  const [detail, setDetail] = useState({});

  const setDetails = audit => {
    const {
      operation,
      model,
      oldValue,
      newValue,
      performedAt,
      lookup,
      user: { login },
    } = audit;

    const modelName = auditModels.find(({ id }) => id === model)?.value;
    const dataset = getDataset(model, oldValue, newValue, lookup);
    setDetail({ operation, modelName, dataset, performedAt, login });
  };

  const getDataset = (model, oldValues, newValues, lookup) => {
    const fields = detailFields[model];
    return fields.map(({ name, transformation }) => {
      const field = i18n.t(`audit.detail.fields.${model}.${name}`);
      const oldValue = getValue(name, oldValues, lookup, transformation);
      const newValue = getValue(name, newValues, lookup, transformation);
      return { field, oldValue, newValue };
    });
  };

  const getValue = (fieldName, values, lookup, transformation) => {
    const fieldValue = values?.[fieldName];
    const lookupValue = lookup?.[fieldName]?.[fieldValue] ?? fieldValue;
    return transformation?.(lookupValue) ?? lookupValue;
  };

  const columns = [
    {
      title: i18n.t('audit.detail.table.field'),
      dataIndex: 'field',
    },
    {
      title: i18n.t('audit.detail.table.before'),
      dataIndex: 'oldValue',
    },
    {
      title: i18n.t('audit.detail.table.after'),
      dataIndex: 'newValue',
      render: (_, { oldValue, newValue }) => (
        <span
          style={{ color: detail.operation === 'U' && oldValue !== newValue ? 'red' : 'black' }}
        >
          {newValue}
        </span>
      ),
    },
  ];

  return (
    <Axios api="audit" params={[id, { include: 'user' }]} run={id} onSuccess={setDetails}>
      <Modal width="80%" title="Detalhes" visible={id} onCancel={onClose} footer={null}>
        <Card>
          <Row gutter={20} type="flex">
            <Col md={3}>
              <Statistic
                title={i18n.t('audit.detail.header.operation')}
                value=" "
                prefix={<OperationTag operation={detail.operation} />}
              />
            </Col>
            <Col md={7}>
              <Statistic
                title={i18n.t('audit.detail.header.model')}
                value={i18n.t(detail.modelName)}
              />
            </Col>
            <Col md={7}>
              <Statistic title={i18n.t('audit.detail.header.user')} value={detail.login} />
            </Col>
            <Col md={7}>
              <Statistic
                title={i18n.t('audit.detail.header.performedAt')}
                value={moment(detail.performedAt).format('DD/MM/YYYY HH:mm')}
              />
            </Col>
          </Row>
        </Card>
        <Table dataSource={detail.dataset} columns={columns} pagination={false} />
      </Modal>
    </Axios>
  );
};

export default AuditDetailModal;
