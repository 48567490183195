import domainData from './domainData';
import { APP_DOMAIN_VENDAS, APP_DOMAIN_PROFISSIONAL } from './config';

const STORAGE_KEY = 'HOST:ID';
const ALLOWED_HOST_NAMES = [APP_DOMAIN_VENDAS, APP_DOMAIN_PROFISSIONAL];
const FALLBACK_DOMAIN = APP_DOMAIN_VENDAS;

const storeKey = key => {
  localStorage.setItem(STORAGE_KEY, key);
};

const getSearchArgs = () => {
  return window.location.search
    .slice(1) // removes the question mark
    .split('&')
    .map(search => search.split('='))
    .reduce((acc, cur) => ({ ...acc, [cur[0]]: cur[1] }), {});
};

const getRawHostId = () => {
  const hostname = window.location.hostname.split('.')[0];
  return ALLOWED_HOST_NAMES.includes(hostname) ? hostname : FALLBACK_DOMAIN;
};

const updateWindowTitle = title => {
  document.title = title;
};

export const getHostId = () => {
  return localStorage.getItem(STORAGE_KEY) ?? FALLBACK_DOMAIN;
};

export const getDomainData = data => {
  return domainData[data][getHostId()];
};

export const init = () => {
  storeKey(getRawHostId());
  updateWindowTitle(getDomainData('WINDOW_TITLE'));
};
