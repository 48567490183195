import React from 'react';
import { Choose } from 'react-extras';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br';
import ptBR from 'antd/es/locale/pt_BR';
import MaskedInput from 'antd-mask-input';
import '@ant-design/compatible/assets/index.css';
import { Col, ConfigProvider, DatePicker, Input, InputNumber, TimePicker } from 'antd';

import { Form } from '@ant-design/compatible';

const { RangePicker } = DatePicker;
moment.updateLocale(moment.locale('pt-br'), { invalidDate: '-' });

function changeInput(setFieldValue, field, event, onChangeWithField, customFormId, type) {
  const eventValue = type === 'number' ? event : event.target.value;
  setFieldValue(field, eventValue, customFormId);
  if (onChangeWithField) {
    onChangeWithField(field, eventValue, customFormId);
  }
}

function renderFormItem(label, errors, field, children) {
  return (
    <Form.Item
      label={label}
      validateStatus={errors[field] ? 'error' : 'validating'}
      help={errors[field]}
    >
      {children}
    </Form.Item>
  );
}

function validValidDateTime(value) {
  if (value != null && moment(value).isValid()) {
    return value;
  }
  return null;
}

function renderDatePicker(label, props, type) {
  const { dateFormat = 'DD/MM/YYYY' } = props;
  const { name = '' } = props;
  const renderFieldName = name ? name : props.field;
  const textDatePicker = (
    <ConfigProvider locale={ptBR}>
      <Choose>
        <Choose.When condition={type === 'dateRange'}>
          <RangePicker
            disabled={props.disabled || false}
            ranges={
              (validValidDateTime(props.value[renderFieldName] && props.value[renderFieldName][0]),
              validValidDateTime(props.value[renderFieldName] && props.value[renderFieldName][1]))
            }
            format={dateFormat}
            suffixIcon={null}
            onChange={(date, dateString) => {
              console.tron.log('onChange', date, dateString);
              props.setFieldValue(renderFieldName, date);
            }}
          />
        </Choose.When>
        <Choose.Otherwise>
          <DatePicker
            disabled={props.disabled || false}
            value={validValidDateTime(props.value[renderFieldName])}
            format={dateFormat}
            suffixIcon={null}
            onChange={(date, dateString) => {
              console.tron.log('onChange', date, dateString);
              props.setFieldValue(renderFieldName, date);
            }}
          />
        </Choose.Otherwise>
      </Choose>
    </ConfigProvider>
  );
  return renderFormItem(label, props.errors, renderFieldName, textDatePicker);
}

function renderTimePicker(label, props) {
  const { name = '' } = props;
  const renderFieldName = name ? name : props.field;

  const textTimePicker = (
    <ConfigProvider locale={ptBR}>
      <TimePicker
        format="HH:mm"
        disabled={props.disabled || false}
        value={validValidDateTime(props.value[renderFieldName])}
        suffixIcon={null}
        onChange={(date, dateString) => {
          console.tron.log('onChange', date, dateString);
          props.setFieldValue(renderFieldName, date);
        }}
      />
    </ConfigProvider>
  );
  return renderFormItem(label, props.errors, renderFieldName, textTimePicker);
}

function renderTextArea(label, props) {
  const { name = '', customFormId = '' } = props;
  const renderFieldName = name ? name : props.field;

  const textArea = (
    <Input.TextArea
      rows={5}
      prefix={props.prefix}
      placeholder={props.placeholder}
      type={props.type}
      name={props.value[renderFieldName]}
      value={props.value[renderFieldName]}
      onBlur={props.handleBlur}
      disabled={props.disabled || false}
      onChange={event =>
        changeInput(
          props.setFieldValue,
          renderFieldName,
          event,
          props.onChangeWithField,
          customFormId,
          props.type,
        )
      }
    />
  );
  return renderFormItem(label, props.errors, renderFieldName, textArea);
}

function renderInputNumber(label, props) {
  const { name = '', customFormId = '' } = props;
  const renderFieldName = name ? name : props.field;

  const inputNumber = (
    <InputNumber
      value={props.value[renderFieldName]}
      size="middle"
      name={props.value[renderFieldName]}
      step={props.step}
      defaultValue={props.defaultValue}
      onBlur={props.handleBlur}
      disabled={props.disabled || false}
      onChange={event =>
        changeInput(
          props.setFieldValue,
          renderFieldName,
          event,
          props.onChangeWithField,
          customFormId,
          props.type,
        )
      }
    />
  );
  return renderFormItem(label, props.errors, renderFieldName, inputNumber);
}

/**
 * @description The function is designed to return a specified
 * Component based on a set of conditions based on the input props.
 * By default it'll return a spread of the props indiscriminately,
 * but that can be modified to easier filter or remove attributes.
 */
const getInputCompProps = props =>
  (() => {
    function ret(InputComponent, default_props) {
      // Determines what generic props are allowed into the Input components.
      // Those can still be rewritten by the default_props parameter.
      const { type, disabled, prefix, placeholder, max, min, mask, visibilitytoggle } = props;
      return {
        InputComponent,
        inputProps: {
          ...default_props,
          type,
          disabled,
          prefix,
          placeholder,
          max,
          min,
          mask,
          visibilitytoggle,
        },
      };
    }

    switch (true) {
      // MASKED INPUT
      case props.mask != null && props.mask !== '':
        return new ret(MaskedInput);
      // PASSWORD INPUT
      case props.type === 'password':
        return new ret(Input.Password);
      // REGULAR INPUT
      default:
        return new ret(Input);
    }
  })();

function renderInputText(label, props) {
  const { name = '', customFormId = '', className = '' } = props;
  const renderFieldName = name ? name : props.field;

  const { InputComponent, inputProps } = getInputCompProps(props);

  const textInput = (
    <InputComponent
      {...inputProps}
      name={props.value[renderFieldName]}
      value={props.value[renderFieldName]}
      onBlur={props.handleBlur}
      className={className}
      onChange={
        props.handleChange ||
        (event =>
          changeInput(
            props.setFieldValue,
            renderFieldName,
            event,
            props.onChangeWithField,
            customFormId,
            props.type,
          ))
      }
    />
  );

  return renderFormItem(label, props.errors, renderFieldName, textInput);
}

const CustomInput = props => {
  const { t, i18n } = useTranslation();
  const { rowSpan, type, textArea } = props;

  const getLabelName = ({ label }) => t(label);

  const label = getLabelName({ label: props.label || props.field });

  return (
    <Col xs={24} sm={24} md={rowSpan} lg={rowSpan} xl={rowSpan}>
      <Choose>
        <Choose.When condition={type === 'textarea' && textArea != null}>
          {renderTextArea(label, props)}
        </Choose.When>
        <Choose.When condition={type === 'number'}>{renderInputNumber(label, props)}</Choose.When>
        <Choose.Otherwise>
          <Choose>
            <Choose.When condition={type === 'date' || type === 'dateRange'}>
              {renderDatePicker(label, props, type, i18n)}
            </Choose.When>
            <Choose.When condition={type === 'hour'}>
              {renderTimePicker(label, props, i18n)}
            </Choose.When>
            <Choose.Otherwise>{renderInputText(label, props)}</Choose.Otherwise>
          </Choose>
        </Choose.Otherwise>
      </Choose>
    </Col>
  );
};
const mapStateToProps = lang => {
  const { lng } = lang;
  return {
    lng,
  };
};

export default connect(mapStateToProps)(CustomInput);
