import React from 'react';

import { Tag } from 'antd';

import Translate from '@components/Translate';

import { auditOperations } from './auditEnums';

const OperationTag = ({ operation }) => {
  const auditOperation = auditOperations.find(({ id }) => id === operation);
  if (!auditOperation) {
    return null;
  }

  return (
    <Tag color={auditOperation.color}>
      <Translate>{auditOperation.value}</Translate>
    </Tag>
  );
};

export default OperationTag;
