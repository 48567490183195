import { showLoading, hideLoading } from 'react-redux-loading-bar';

import _ from 'lodash';

import { Creators as LoadingActions } from '@redux/ducks/loading';
import { Creators as AlertActions } from '@redux/ducks/alert';
import store, { history } from '@redux/store/index';
import * as api from '../services/api';

export function handlerErrorRequest(error) {
  //validando token expirado, para deslogar usuario
  let errorCode = _.get(error, 'data.code');
  if (String(errorCode) === '32556') {
    window.localStorage.clear();
    history.replace('/login');
    window.location.href = '/login';
    return;
  }

  if (_.get(error, 'data.message')) {
    let description = error.data.description ? `: ${error.data.description}` : '';
    let errorDescription = `${error.data.message}${description}`;
    return {
      message: errorDescription,
      status: undefined,
    };
  }
  const response = {
    message: 'Ocorreu um erro desconhecido',
    status: undefined,
  };
  return response;
}
export function request(functionRequest, skipLoading = false, skipError = false) {
  return new Promise((resolve, reject) => {
    if (!skipLoading || skipLoading !== true) {
      store.dispatch(LoadingActions.openLoading());
      store.dispatch(showLoading());
    }
    functionRequest
      .then(request => {
        store.dispatch(LoadingActions.closeLoading());

        store.dispatch(hideLoading());
        resolve(request);
      })
      .catch(error => {
        store.dispatch(LoadingActions.closeLoading());
        store.dispatch(hideLoading());

        if (!skipError) {
          store.dispatch(AlertActions.openAlert(handlerErrorRequest(error.response)));
        }
        reject(error.response);
      });
  });
}
export { api };
