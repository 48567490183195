import React, { useEffect, useState } from 'react';

import { Card } from 'antd';

import Input from '@components/Input';
import Axios from '@components/Axios';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { sortText, sortNumber } from '@util/columnSorter';
import UpdateGoalByProfile from '@components/UpdateGoalByProfile';
import { isDealer } from '@util/user.utils';

import './styles.scss';

const ListProductSalespeople = ({ run, updateScreen, goalId, goal, dealers, user }) => {
  const [itemId, setItemId] = useState();
  const [parentId, setParentId] = useState();
  const [items, setItems] = useState([]);
  const [search, setSearch] = useState('');
  const [dataset, setDataset] = useState([]);
  const [salespeople, setSalespeople] = useState([]);

  console.table(dealers);
  useEffect(() => {
    const listSalesperson = filterDealers(parentId);
    filterProduct(itemId, listSalesperson);
  }, [items, salespeople]);

  const onSuccessItems = items => {
    setItems(items);
  };

  const onSuccessSalespeople = salespeople => {
    setSalespeople(salespeople);
  };

  const groupSalespeople = salespeople => {
    const grouped = salespeople.reduce(
      (acc, { id, goalValue, actualValue, salesperson, goalItem, goalId, ranking }) => {
        let entry = acc[salesperson.id] || {
          ...salesperson,
          salespersonId: salesperson.id,
          goalId,
        };
        const object = {
          id,
          goalValue,
          actualValue,
          salespersonId: salesperson.id,
          ranking,
        };
        if (!goalItem) {
          entry = { ...entry, ...object };
        } else {
          const goalItems = entry.goalItems || [];
          entry.goalItems = [
            ...goalItems,
            {
              ...object,
              goalItemId: goalItem.id,
              itemName: goalItem.itemName,
            },
          ];
        }
        acc[salesperson.id] = entry;
        return acc;
      },
      {},
    );
    return Object.values(grouped);
  };

  const includeItemInSalespeople = (products, list = []) => {
    let key = 0;
    const array = [];
    for (const salesperson of list) {
      const { goalItems = [] } = salesperson;
      for (const { id, itemName } of products) {
        const item = goalItems.find(({ goalItemId }) => goalItemId === id);
        if (!item) {
          array.push({
            ...salesperson,
            goalItem: { goalItemId: id, itemName, actualValue: 0 },
            key: key++,
          });
        } else {
          array.push({ ...salesperson, goalItem: item, key: key++ });
        }
      }
    }
    setDataset(array);
  };

  const filterProduct = (value, list) => {
    if (value) {
      const itemList = items.filter(item => String(item.id) === String(value));
      includeItemInSalespeople(itemList, list);
    } else {
      includeItemInSalespeople(items, list);
    }
  };

  const filterDealers = value => {
    if (value) {
      const salesPeopleFilterList = salespeople.filter(
        ({ salesperson }) => String(salesperson?.dealer?.id) === String(value),
      );
      return groupSalespeople(salesPeopleFilterList);
    } else {
      return groupSalespeople(salespeople);
    }
  };

  const onChangeDealers = ({ target: { value } }) => {
    setParentId(value);
    const listSalesperson = filterDealers(value);
    filterProduct(itemId, listSalesperson);
  };

  const onChangeItem = ({ target: { value } }) => {
    setItemId(value);
    const listSalesperson = filterDealers(parentId);
    filterProduct(value, listSalesperson);
  };

  const onChangeSearch = value => {
    setSearch(value);
  };

  const columns = [
    {
      dataIndex: 'name',
      title: i18n.t('dashboard.product.list.table.header.salespeople'),
    },
    {
      dataIndex: ['dealer', 'name'],
      title: i18n.t('dashboard.product.list.table.header.dealers'),
    },
    {
      dataIndex: ['goalItem', 'itemName'],
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortText(a, b, ['goalItem', 'itemName']),
        multiple: 2,
      },
      title: i18n.t('dashboard.product.list.table.header.product'),
    },
    {
      dataIndex: 'actualValue',
      title: i18n.t('dashboard.product.list.table.header.actualValue'),
      render: (_, { salespersonId, goalItem }) => {
        const object = { ...goalItem, itemId: salespersonId, goalId };
        return (
          <UpdateGoalByProfile
            user={user}
            item={object}
            api="salespeople"
            field="actualValue"
            updateScreen={updateScreen}
            goalStatus={goal.goalStatus}
          />
        );
      },
    },
    {
      dataIndex: 'ranking',
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => sortNumber(a, b, ['goalItem', 'ranking']),
        multiple: 1,
      },
      title: i18n.t('dashboard.product.list.table.header.ranking'),
      render: (_, { goalItem: { ranking } }) => {
        return ranking ? `${ranking}°` : '-';
      },
    },
  ];

  const components = () => {
    return (
      <div className="list-product-filter">
        {!isDealer(user) && (
          <Input
            type="select"
            array={dealers}
            value={parentId}
            onChange={onChangeDealers}
            placeholder={'dashboard.product.list.selectDealer'}
            option={{
              name: 'dealer.name',
              key: 'dealer.id',
            }}
          />
        )}
        <Input
          type="select"
          array={items}
          value={itemId}
          onChange={onChangeItem}
          placeholder={'dashboard.product.list.selectItem'}
          option={{
            name: 'itemName',
            key: 'id',
          }}
        />
      </div>
    );
  };

  console.table(dataset);
  return (
    <div className="ListProduct">
      <Axios
        api="goals"
        run={goalId}
        onSuccess={onSuccessItems}
        params={[`${goalId}/items`, { ignorePagination: true }]}
      >
        <Axios
          api="goals"
          onSuccess={onSuccessSalespeople}
          run={[goalId, items?.length ?? 0, run, { search }]}
          params={[
            `${goalId}/salespeople`,
            {
              search,
              ignorePagination: true,
              include: 'salesperson,goalItem,ranking',
            },
          ]}
        >
          <Card className="ant-card gx-card ant-card-bordered">
            <TableSearch
              ignorePagination
              columns={columns}
              dataSource={dataset}
              component={components}
              getSearch={onChangeSearch}
              title="dashboard.product.list.table.title"
              rowId="key"
            />
          </Card>
        </Axios>
      </Axios>
    </div>
  );
};

export default ListProductSalespeople;
