import React from 'react';
import { Link } from 'react-router-dom';
import { Icon as LegacyIcon } from '@ant-design/compatible';

import { Menu } from 'antd';
import Translate from '@components/Translate';
import { useUserContext } from '@context/UserContext';

import { menu } from '@routes/routes';

const SideBarContent = ({ drawerClick = () => {} }) => {
  const { user } = useUserContext();

  const renderMenuItem = ({ path, icon, name }) => {
    return (
      <Menu.Item key={path} onClick={drawerClick}>
        <Link to={path}>
          <span className="menu-item-span">
            {icon && <LegacyIcon className="menu-item-icon" type={icon} />}
            <span className="menu-item-icon-text">
              <Translate>{name}</Translate>
            </span>
          </span>
        </Link>
      </Menu.Item>
    );
  };

  const array = !user ? [] : menu.filter(({ permission }) => permission.includes(user.profile));
  return (
    <Menu mode="inline">
      {array.map(item => {
        return renderMenuItem(item);
      })}
    </Menu>
  );
};

export default SideBarContent;
