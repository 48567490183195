import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';

import * as yup from 'yup';
import { Card, Row, Col, Button, message } from 'antd';

import { WhatsAppOutlined } from '@ant-design/icons';

import Axios from '@components/Axios';
import Translate from '@components/Translate';
import { useUserContext } from '@context/UserContext';
import useLazyAsset from '@hooks/useLazyAsset';
import { getDomainData, getHostId } from '@services/DiscernDomain';
import FormSubmit from '@components/FormSubmit';
import PasswordRecovery from '@containers/PasswordRecovery';
import { parameters } from '@util/enums';
import { getParameter } from '@services/api';
import background from '@assets/images/background.png';

import FormComponent from './components/Form';

import './styles.scss';

const Login = () => {
  const history = useHistory();
  const [whatsAppHelp, setWhatsappHelp] = useState();
  const { login, user, token } = useUserContext();
  const LOGIN_BG_IMAGE = useLazyAsset({ asset: getDomainData('LOGIN_BACKGROUND') });
  const LOGIN_LOGO_IMAGE = useLazyAsset({ asset: getDomainData('LOGO_BIG') });

  const submitFormRef = useRef(null);
  const UI_ACTIVE_REQUEST = useRef(false);

  const LoginAbortController = new AbortController();
  const LoginAbortSignal = LoginAbortController.signal;

  useEffect(() => {
    async function fetchWhatsAppData() {
      const whatsAppHelp = await getParameter(parameters.whatsapp);
      setWhatsappHelp(whatsAppHelp);
    }
    fetchWhatsAppData();
  }, []);

  useEffect(() => {
    token && history.push('/goal/list');
  }, [user]);

  const getBackgroundStyle = {
    background: `url("${LOGIN_BG_IMAGE}") 7% center no-repeat, url("${background}") 0% 0% / cover`,
  };

  const formikProps = {
    initialValues: {},
    validationSchema: yup.object().shape({
      userLogin: yup.string().email().required(),
      userPassword: yup.string().required(),
    }),
  };

  const onSuccess = response => {
    if (response) {
      const { pathname } = history.location;
      const nextPath = pathname !== '/login' ? pathname : '/goal/list';
      history.push(nextPath);
      login(response);
    }
    UI_ACTIVE_REQUEST.current = false;
  };

  const onError = error => {
    UI_ACTIVE_REQUEST.current = false;
    message.error(error.data);
  };

  const submitForm = item => {
    UI_ACTIVE_REQUEST.current = true;
    const { submit, values } = item;
    const domain = getHostId();
    const body = { ...values, domain };
    submit({ body });
  };

  const actions = ({ handleSubmit }) => {
    return (
      <Button
        loading={UI_ACTIVE_REQUEST.current}
        className="ant-btn ant-btn-primary ant-btn-block login-btn"
        onClick={handleSubmit}
      >
        <Translate>login.form.action.signIn</Translate>
      </Button>
    );
  };

  const addEventSubmitOnEnter = () =>
    document.addEventListener(
      'keydown',
      ({ key }) => key === 'Enter' && !UI_ACTIVE_REQUEST.current && submitFormRef.current(),
      {
        signal: LoginAbortSignal,
      },
    );

  useEffect(() => {
    if (submitFormRef.current) {
      addEventSubmitOnEnter();
    }
    return () => {
      LoginAbortController.abort();
      submitFormRef.current = () => {};
    };
  }, [history.location.pathname]);

  const sendWhatsApp = () => {
    const { api, number, message } = whatsAppHelp;
    const apiWhatsApp = api.replace('{phone}', number).replace('{message}', message);
    window.open(apiWhatsApp, '_blank');
  };

  const passwordRecovery = () => {
    history.push('/password-recovery');
  };

  const getComponentForm = ({ submit }) => {
    return (
      <FormSubmit
        {...formikProps}
        onSubmit={(values, event) => submitForm({ ...event, values, submit })}
      >
        {({ handleSubmit, submitForm, ...formikValues }) => (
          <>
            {(submitFormRef.current = submitForm)}
            <FormComponent {...formikValues} />
            <Row className="other-buttons forgot">
              <Col sm={24} xs={24}>
                <Button className="anchor" onClick={() => passwordRecovery(formikValues)}>
                  <Translate>app.userAuth.forgotPassword</Translate>
                </Button>

                {whatsAppHelp ? (
                  <Button className="whatsapp" onClick={() => sendWhatsApp()}>
                    <WhatsAppOutlined /> &nbsp;
                    <Translate>common.WhatsApp.Help</Translate>
                  </Button>
                ) : null}
              </Col>
            </Row>
            {actions({ handleSubmit })}
          </>
        )}
      </FormSubmit>
    );
  };

  const LoginInnerCard = () => (
    <Axios api="login" method="post" onSuccess={onSuccess} onError={onError}>
      {getComponentForm}
    </Axios>
  );

  return (
    <div className="login-container" style={getBackgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100 align-items-right">
        <Row align={'stretch'} justify={'end'}>
          <Col pull={4}>
            <Card>
              <div className="my-4">
                <div className="text-center">
                  <img width="250" className="img-fluid" src={LOGIN_LOGO_IMAGE} alt="" />
                </div>
                <hr />
                <Switch>
                  <Route path="/password-recovery" component={PasswordRecovery} />
                  <Route path="/" component={LoginInnerCard} />
                </Switch>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Login;
