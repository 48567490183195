import React, { useState } from 'react';

import Axios from '@components/Axios';
import ItemSalesPeople from '@components/ItemSalesPeople';

const SalespeopleTab = ({ goalId, bindFormProps, dealersSnapshot, productsSnapshot }) => {
  const [run, setRun] = useState(1);
  const [dealers, setDealers] = useState([]);
  const [salespeople, setSalespeople] = useState([]);

  const [touched, setTouched] = useState({});

  const updateScreen = () => {
    setRun(run => run + 1);
  };

  const onSuccessAddTouched = () => {
    updateScreen();
    setTouched({});
  };

  const onSuccessDealer = response => {
    const dealers = response.map(({ dealer }) => ({ ...dealer }));
    setDealers(dealers);
  };

  const onSuccessSalespeople = response => {
    setSalespeople(response);
  };

  const submitForm = async submit => {
    await submit({ body: Object.values(touched) });
    return true;
  };

  const getComponentTable = ({ submit }) => {
    bindFormProps({ dirty: !!Object.keys(touched).length, submitForm: () => submitForm(submit) });
    return (
      <ItemSalesPeople
        selectDealer
        goalId={goalId}
        dealers={dealers}
        touched={touched}
        setTouched={setTouched}
        salespeople={salespeople}
        updateScreen={updateScreen}
        productsSnapshot={productsSnapshot}
      />
    );
  };

  return (
    <Axios
      api="goals"
      run={{ run, dealersSnapshot }}
      onSuccess={onSuccessDealer}
      params={[`${goalId}/dealers`, { ignorePagination: true, include: 'dealer' }]}
    >
      <Axios
        api="goals"
        run={{ run, productsSnapshot, dealersSnapshot }}
        onSuccess={onSuccessSalespeople}
        params={[
          `${goalId}/salespeople`,
          { ignorePagination: true, include: 'salesperson,goalItem' },
        ]}
      >
        <Axios
          api="goals"
          method="post"
          params={`${goalId}/salespeople`}
          onSuccess={onSuccessAddTouched}
        >
          {getComponentTable}
        </Axios>
      </Axios>
    </Axios>
  );
};

export default SalespeopleTab;
