import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Empty, Card, Row, Table, Button, Popconfirm, Tooltip, Space, message } from 'antd';
import moment from 'moment';
import { listGoalItems, removeGoalItem } from 'services/api';

import { DeleteOutlined, EditOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import { getFormatDate } from '@util/global';

import { AddProductModal } from './components/AddProductModal';

const ProductTab = ({ goal, bindFormProps }) => {
  const [t] = useTranslation();
  const [products, setProducts] = useState([]);
  const [deletingId, setDeletingId] = useState();
  const [editingId, setEditingId] = useState();
  const [isAddProductModalVisible, setIsAddProductModalVisible] = useState(false);
  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const products = await listGoalItems(goal.id, { ignorePagination: true });
      setProducts(products);
    }
    fetchData();
  }, []);

  const submitForm = () => {
    setIsDirty(false);
    return true;
  };

  const deleteItem = async itemId => {
    try {
      await removeGoalItem(goal.id, itemId);
      message.info(t('goalItems.add.removedMessage'));
      setProducts(products.filter(({ id }) => id !== itemId));
      setDeletingId();
      setIsDirty(true);
    } catch (e) {
      message.error(e.message);
    }
  };

  const editItem = id => {
    setEditingId(id);
    switchAddProductModalVisibility();
  };

  const isDateOutOfRange = (startDate, endDate) => {
    return (
      moment(startDate).isBefore(moment(goal.startDate)) ||
      moment(endDate).isAfter(moment(goal.endDate))
    );
  };

  const columns = [
    {
      title: t('goalItems.name'),
      dataIndex: 'itemName',
      render: (itemName, { goalItemConfig: { startDate, endDate } }) => ({
        children: !isDateOutOfRange(startDate, endDate) ? (
          itemName
        ) : (
          <Space>
            <Tooltip title={t('goalItems.itemOutOfDateRange')}>
              <ExclamationCircleOutlined />
            </Tooltip>
            {itemName}
          </Space>
        ),
      }),
    },
    {
      title: t('goalItems.startDate'),
      dataIndex: ['goalItemConfig', 'startDate'],
      render: startDate => getFormatDate(startDate),
    },
    {
      title: t('goalItems.endDate'),
      dataIndex: ['goalItemConfig', 'endDate'],
      render: endDate => getFormatDate(endDate),
    },
    {
      title: t('tableActions'),
      align: 'right',
      render: (_, { id }) => (
        <>
          <Button type="link" onClick={() => editItem(id)}>
            <EditOutlined />
          </Button>
          <Popconfirm
            title={t('confirmDelete')}
            visible={deletingId === id}
            okText={t('yes')}
            cancelText={t('not')}
            placement="left"
            onConfirm={() => deleteItem(id)}
            onCancel={() => setDeletingId()}
          >
            <Button type="link" onClick={() => setDeletingId(id)}>
              <DeleteOutlined />
            </Button>
          </Popconfirm>
        </>
      ),
    },
  ];

  const switchAddProductModalVisibility = () =>
    setIsAddProductModalVisible(!isAddProductModalVisible);

  const onCancel = () => {
    setEditingId();
    switchAddProductModalVisibility();
  };

  const onAddProduct = product => {
    setProducts([...products.filter(({ id }) => id !== product.id), product]);
    setIsDirty(true);
  };

  const AddProductButton = () => (
    <Button onClick={switchAddProductModalVisibility}>{t('goalItems.addItems')}</Button>
  );

  const getRowClassName = ({ goalItemConfig: { startDate, endDate } }) => {
    return isDateOutOfRange(startDate, endDate) ? 'dateOutOfRange' : '';
  };

  const renderForm = () => {
    bindFormProps({ dirty: isDirty, submitForm });
    return !products?.length ? (
      <Empty description={t('goalItems.noItemsYet')}>
        <AddProductButton />
      </Empty>
    ) : (
      <Card>
        <Row justify="end">
          <AddProductButton />
        </Row>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={products}
          pagination={false}
          rowClassName={record => getRowClassName(record)}
        />
      </Card>
    );
  };

  return (
    <>
      {renderForm()}
      <AddProductModal
        id={editingId}
        goalId={goal.id}
        addedProducts={products}
        isVisible={isAddProductModalVisible}
        startDate={goal.startDate}
        endDate={goal.endDate}
        onAdd={onAddProduct}
        onCancel={onCancel}
      />
    </>
  );
};

export default ProductTab;
