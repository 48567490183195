import React from 'react';

import { Card, Button, Tooltip } from 'antd';

import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import Tag from '@components/Tag';
import { i18n } from '@components/Translate';
import TableSearch from '@components/TableSearch';
import { formatCnpj } from '@util/global';
import ResetPassword from '@components/ResetPassword';

import './styles.scss';

const ListDealers = ({ user, history }) => {
  const tableColumns = [
    {
      title: i18n.t('dealers.list.table.header.status'),
      dataIndex: 'active',
      render: (_, { active }) => {
        const [type, color] = active
          ? ['checkedChildren', 'success']
          : ['unCheckedChildren', 'error'];
        return <Tag color={color}>{`dealers.list.table.header.status.${type}`}</Tag>;
      },
    },
    {
      title: i18n.t('dealers.list.table.header.name'),
      dataIndex: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (_, { id, name }) => {
        return (
          <Tooltip title={i18n.t('dealers.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/dealers/${id}`)}>
              {name}
            </Button>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t('dealers.list.table.header.documentNumber'),
      dataIndex: 'documentNumber',
      render: (_, { documentNumber }) => {
        return formatCnpj(documentNumber);
      },
    },
    {
      title: i18n.t('dealers.list.table.header.city'),
      dataIndex: 'city',
      render: (_, { cityState }) => {
        if (cityState) {
          const { cityName, stateAbbreviation } = cityState || {};
          return `${cityName}/${stateAbbreviation}`;
        } else {
          return '-';
        }
      },
    },
    {
      title: i18n.t('tableActions'),
      dataIndex: 'actions',
      align: 'right',
      render: (_, { name, email, id, portalAccessControl, userId }) => (
        <>
          <Tooltip title={i18n.t('dealers.list.table.actions.tooltip.edit')}>
            <Button type="link" onClick={() => history.push(`/dealers/${id}`)}>
              <EditOutlined />
            </Button>
          </Tooltip>

          <ResetPassword
            portalAccessControl={portalAccessControl}
            userId={userId}
            userName={name}
            userEmail={email}
            loggedUser={user}
          />
        </>
      ),
    },
  ];

  const axios = {
    run: true,
    api: 'dealers',
    params: { include: 'cityState,portalAccessControl' },
  };

  const actionProps = [
    {
      icon: <PlusOutlined />,
      title: 'dealers.list.card.new',
      onClick: () => history.push('/dealers/create'),
    },
    {
      icon: <PlusOutlined />,
      title: 'dealers.list.card.batch',
      onClick: () => history.push('/dealers/batch'),
    },
  ];

  return (
    <div className="ListDealers">
      <Card className="ant-card gx-card ant-card-bordered">
        <TableSearch
          axios={axios}
          columns={tableColumns}
          actionProps={actionProps}
          title="dealers.list.table.title"
        />
      </Card>
    </div>
  );
};

export default ListDealers;
