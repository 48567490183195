import React from 'react';
import { Row, Col } from 'antd';
import { Form } from '@ant-design/compatible';

import Input from '@components/Input';
import { memberTypes } from '@util/enums';
import { i18n } from '@components/Translate';

const memberTypeDataset = memberTypes.map(type => ({
  id: type,
  typeName: i18n.t(`members.create.form.memberTypes.${type}`),
}));

const FormComponent = ({ errors, values, handleSubmit, handleChange }) => {
  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="memberName"
            onChange={handleChange}
            error={errors.memberName}
            value={values.memberName}
            label="members.create.form.label.name"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="emailAddress"
            onChange={handleChange}
            error={errors.emailAddress}
            value={values.emailAddress}
            label="members.create.form.label.email"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="select"
            name="memberType"
            onChange={handleChange}
            array={memberTypeDataset}
            error={errors.memberType}
            value={values.memberType}
            option={{ key: 'id', name: 'typeName' }}
            label="members.create.form.label.memberType"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="switch"
            name="active"
            error={errors.active}
            value={values.active}
            onChange={handleChange}
            label="members.create.form.label.status"
            checkedChildren="members.create.form.label.status.checkedChildren"
            unCheckedChildren="members.create.form.label.status.unCheckedChildren"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
