import moment from 'moment';

const formatCnpj = value => {
  return value?.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
};

const formatCpf = value => {
  return value?.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
};

const formatMoney = (value = 0) => {
  return Number(value).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' });
};

const getNumber = value => {
  if (value) {
    return Number(value);
  }
};

const getPercent = (value, dividend) => {
  let result = 0;
  if (getNumber(dividend) && value) {
    result = (getNumber(value) / getNumber(dividend)) * 100;
  }
  return result.toFixed(2);
};

const getFormatDate = (value, format = 'DD/MM/YYYY') => {
  if (value) {
    const date = String(value).split(/(\d{4}-\d{2}-\d{2})/)[1];
    return moment(date || value).format(format);
  }
};

const formatMoneyProp = {
  parser: value => {
    return value.replace(/\$\s?|(,*)/g, '');
  },
  formatter: value => {
    return String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },
};

const download = (filename, url) => {
  const link = document.createElement('a');
  link.target = '_blank';
  link.download = filename;
  link.href = url;
  link.click();
};

export {
  formatCnpj,
  formatCpf,
  formatMoney,
  formatMoneyProp,
  getPercent,
  getNumber,
  download,
  getFormatDate,
};
