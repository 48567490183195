import React, { useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Table, Tag, Empty, Card, Row, Button } from 'antd';

import get from 'lodash/get';

import Axios from '@components/Axios';
import Input from '@components/Input';
import { i18n } from '@components/Translate';
import Popconfirm from '@components/Popconfirm';

import AddDealerGoal from '@components/AddDealerGoal';

import { formatCnpj } from '@util/global';

import './styles.scss';

const ItemDealers = ({ goalId, touched, dealers = [], setTouched, component, updateScreen }) => {
  const [deletingId, setDeletingId] = useState();
  const [addDealerModalVisible, setAddDealerModalVisible] = useState(false);

  const columns = [
    {
      title: i18n.t('status'),
      dataIndex: ['dealer', 'active'],
      render: active => {
        const [tagColor, tagLabel] = active ? ['green', 'active'] : ['volcano', 'inactive'];
        return <Tag color={tagColor}>{i18n.t(tagLabel)}</Tag>;
      },
    },
    {
      title: i18n.t('goalDealers.name'),
      dataIndex: ['dealer', 'name'],
    },
    {
      title: i18n.t('documentNumber'),
      dataIndex: ['dealer', 'documentNumber'],
      render: (_, { dealer }) => {
        return formatCnpj(dealer?.documentNumber);
      },
    },
    {
      title: i18n.t('city'),
      dataIndex: ['dealer', 'cityState'],
      render: cityState => {
        const { cityName, stateAbbreviation } = cityState || {};
        const city = cityName ? `${cityName}/${stateAbbreviation}` : '-';
        return city;
      },
    },
    {
      title: i18n.t('goalDealers.goal'),
      render: (_, object) => {
        const {
          dealer: { id },
          goalValue,
        } = object;
        const value = getInputValue(`${id}.goalValue`, goalValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            name="goalValue"
            object={object}
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('goalDealers.fulfilled'),
      render: (_, object) => {
        const {
          dealer: { id },
          actualValue,
        } = object;
        const value = getInputValue(`${id}.actualValue`, actualValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="actualValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
    {
      title: i18n.t('tableActions'),
      render: (_, { dealer: { id } }) => (
        <Popconfirm
          title="confirmDelete"
          onSuccess={onSuccessDelete}
          visible={deletingId === id}
          onCancel={() => setDeletingId()}
          onClick={() => setDeletingId(id)}
          axios={{
            api: 'goals',
            method: 'delete',
            params: `${goalId}/dealers/${id}/values`,
          }}
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  const onSuccessDelete = () => {
    updateScreen();
    setDeletingId();
  };

  const onSuccessAddDealers = () => {
    updateScreen();
    toggleAddDealer();
  };

  const toggleAddDealer = () => {
    setAddDealerModalVisible(!addDealerModalVisible);
  };

  const onChangeValue = ({ target: { name, value, object = {} } }) => {
    const {
      dealer: { id },
    } = object;
    object = { dealerId: id, goalId, ...object };
    if (!touched[id]) {
      object[name] = value;
      touched[id] = object;
    } else {
      touched[id][name] = value;
    }
    setTouched({ ...touched });
  };

  const getInputValue = (key, oldValue) => {
    const newValue = get(touched, key);
    if (newValue !== undefined) {
      return newValue;
    } else {
      return oldValue || 0;
    }
  };

  const onAddDealers = ({ body, submit }) => {
    const data = body
      .filter(item => item && !dealers.find(({ dealer }) => item?.id === dealer.id))
      .map(item => {
        return {
          goalId,
          dealer: item,
          goalValue: 0,
          actualValue: 0,
          dealerId: item.id,
        };
      });
    submit({ body: data });
  };

  const getAddDealerButton = () => {
    return <Button onClick={toggleAddDealer}>{i18n.t('goalDealers.addDealers')}</Button>;
  };

  const getComponent = () => {
    if (!dealers.length) {
      return (
        <Empty description={i18n.t('goalSalespeople.noSalespeopleYet')}>
          {getAddDealerButton()}
        </Empty>
      );
    } else {
      return (
        <Card>
          <Row className="header" justify="end">
            {component && component()}
            <div className="header-action-add">{getAddDealerButton()}</div>
          </Row>
          <Table columns={columns} pagination={false} rowKey="customerId" dataSource={dealers} />
        </Card>
      );
    }
  };

  return (
    <div className="ItemDealers">
      {getComponent()}
      <Axios api="goals" method="post" params={`${goalId}/dealers`} onSuccess={onSuccessAddDealers}>
        {({ submit }) => (
          <AddDealerGoal
            dealers={dealers}
            onCancel={toggleAddDealer}
            isVisible={addDealerModalVisible}
            onAdd={body => onAddDealers({ body, submit })}
          />
        )}
      </Axios>
    </div>
  );
};

export default ItemDealers;
