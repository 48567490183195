import React from 'react';
import LoadingBarContainer, { LoadingBarContainerProps } from 'react-redux-loading-bar';
import { useSelector, RootStateOrAny } from 'react-redux';

import useBodyClass from '@hooks/useBodyClass';

import './styles.scss';

interface Props extends LoadingBarContainerProps {}

const DARKEN_TIMEOUT = 500;

export function LoadingBar({ ...rest }: Props) {
  const isLoading: boolean = useSelector((store: RootStateOrAny) => store.loading.show);

  useBodyClass('darken-screen', DARKEN_TIMEOUT, isLoading, 'App');

  //@ts-ignore
  return <LoadingBarContainer {...rest} updateTime={50} className="app-loading-bar" />;
}
