import React from 'react';

import { Row, Col } from 'antd';

import { Form } from '@ant-design/compatible';

import Axios from '@components/Axios';
import Input from '@components/Input';
import states from '@containers/Dealers/CreateDealer/components/Form/states';

const FormComponent = ({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
  const state = values.state;

  const onStateChange = ({ target: { name, value } }) => {
    setFieldValue(name, value);
    setFieldValue('cityStateId', undefined);
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="name"
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            label="dealers.create.form.label.name"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="documentNumber"
            onChange={handleChange}
            mask="99.999.999/9999-99"
            error={errors.documentNumber}
            value={values.documentNumber}
            label="dealers.create.form.label.cnpj"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="state"
            type="select"
            value={state}
            array={states}
            error={errors.state}
            onChange={onStateChange}
            label="dealers.create.form.label.state"
            option={{
              key: 'key',
              name: 'name',
            }}
          />
        </Col>
        <Col xs={24} md={6}>
          <Axios
            api="cities"
            run={state}
            params={{ stateAbbreviation: state, ignorePagination: true }}
          >
            {({ loading, response: { items = [] } }) => (
              <Input
                type="select"
                array={items}
                loading={loading}
                name="cityStateId"
                disabled={!state}
                onChange={handleChange}
                error={errors.cityStateId}
                value={values.cityStateId}
                label="dealers.create.form.label.city"
                option={{
                  key: 'id',
                  name: 'cityName',
                }}
              />
            )}
          </Axios>
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
