import React from 'react';
import { Trans } from 'react-i18next';

import i18n from '@i18n';

const Translate = props => {
  const { i18nKey = '', children = '' } = props;
  if (children) {
    return <Trans i18nKey={children} />;
  } else if (i18nKey) {
    return <Trans i18nKey={i18nKey} />;
  } else {
    return '';
  }
};

export { i18n };
export default Translate;
