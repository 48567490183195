import React, { useState } from 'react';

import { Tooltip, Button, Popconfirm, message } from 'antd';

import { KeyOutlined } from '@ant-design/icons';

import { i18n } from '@components/Translate';
import { isAdmin } from '@util/user.utils';
import { passwordRecovery } from '@services/api';

const emailType = 'passwordRecovery';

const ResetPassword = ({ portalAccessControl, userId, userName, userEmail, loggedUser }) => {
  const [showConfirm, setShowConfirm] = useState(false);

  const sendMail = async () => {
    try {
      setShowConfirm(false);
      const res = await passwordRecovery({
        emailAddress: userEmail,
        emailType,
        portalAccessControl,
      });
      message.success(res.result);
    } catch (error) {
      message.error(error.data);
    }
  };

  // Change in caRecoveryPass
  const canRecoveryPass = portalAccessControl?.active;
  return isAdmin(loggedUser) ? (
    <Tooltip
      title={
        canRecoveryPass
          ? i18n.t('component.resetPassword.tooltip.redefine')
          : i18n.t('component.resetPassword.tooltip.noAccess', { name: userName })
      }
    >
      <Popconfirm
        visible={showConfirm}
        title={i18n.t('component.resetPassword.tooltip.confirmResetPassword', { name: userName })}
        cancelText={i18n.t('not')}
        okText={i18n.t('yes')}
        placement="left"
        onConfirm={sendMail}
        onCancel={() => setShowConfirm(false)}
      >
        <Button type="link" disabled={!canRecoveryPass} onClick={() => setShowConfirm(true)}>
          <KeyOutlined />
        </Button>
      </Popconfirm>
    </Tooltip>
  ) : null;
};

export default ResetPassword;
