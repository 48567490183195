import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Row, Col } from 'antd';

import * as yup from 'yup';

import Axios from '@components/Axios';
import Translate from '@components/Translate';

import FormInfo from './components/Form';
import FormSubmit from '@components/FormSubmit';

import { useMessageContext } from '@context/index';

import './styles.scss';

const CreateMembers = ({ match, history }) => {
  const { showMessage } = useMessageContext();

  const { id } = useParams();
  const [member, setMember] = useState({});
  const [axios, setAxios] = useState({ method: 'post' });

  const api = 'members';

  const getString = value => {
    if (value) {
      return String(value);
    }
  };

  const getMember = data => {
    const { memberTypeId } = data;
    data.memberTypeId = getString(memberTypeId);
    setMember(data);
    setAxios({ method: 'put', params: data.id });
  };

  const onSuccess = () => {
    history.push('/members/list');
  };

  const submitForm = async ({ values, submit, setErrors }) => {
    try {
      await submit({ body: values });
    } catch (error) {
      if (error?.unprocessableEntity) {
        setErrors(error.unprocessableEntity);
      } else if (error?.response?.data) {
        showMessage({
          type: 'error',
          message: error.response.data,
        });
      }
    }
  };

  const formikProps = {
    initialValues: { active: true, ...member },
    validationSchema: yup.object().shape({
      memberName: yup.string().max(60).required(),
      emailAddress: yup.string().email().required(),
      memberType: yup.string().required(),
    }),
  };

  const action = ({ handleSubmit }) => {
    return (
      <Row justify="end">
        <Col className="create-actions">
          <Button onClick={onSuccess}>
            <Translate>members.create.header.exit</Translate>
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            <Translate>members.create.header.register</Translate>
          </Button>
        </Col>
      </Row>
    );
  };

  const getComponentForm = ({ submit }) => {
    return (
      <FormSubmit
        {...formikProps}
        onSubmit={(values, event) => submitForm({ ...event, values, submit })}
      >
        {({ handleSubmit, isValid, ...formikValues }) => (
          <>
            {action({ handleSubmit, isValid })}
            <Card>
              <FormInfo {...formikValues} />
            </Card>
          </>
        )}
      </FormSubmit>
    );
  };

  return (
    <div className="CreateMembers">
      <Axios run={id} api={api} params={[id, { include: 'memberType' }]} onSuccess={getMember}>
        <Axios {...axios} api={api} onSuccess={onSuccess}>
          {getComponentForm}
        </Axios>
      </Axios>
    </div>
  );
};

export default CreateMembers;
