import React, { useState, useEffect } from 'react';

import Axios from '@components/Axios';
import { isManager, isTelesales, isSales } from '@util/user.utils';

import Banner from './components/Banner';
import Header from './components/Header';
import Salespeople from './components/Salespeople';
import GeneralPanel from './components/GeneralPanel';
import Manager from './components/Manager';
import Telesales from './components/Telesales';

const Dashboard = ({ user, match, history }) => {
  const [data, setData] = useState({});
  const [goal, setGoal] = useState({});
  const [goalId, setGoalId] = useState();

  useEffect(() => {
    const id = match.params.id;
    setGoalId(id);
  }, [match.params.id]);

  const onSuccessInfo = dataSuccessInfo => {
    setData(dataSuccessInfo);
  };

  const onSuccessGoal = dataSuccessGoal => {
    setGoal(dataSuccessGoal);
  };

  const getComponent = props => {
    if (goalId) {
      if (isSales(user)) {
        return <Salespeople {...props} />;
      } else if (isManager(user)) {
        return <Manager {...props} />;
      } else if (isTelesales(user)) {
        return <Telesales {...props} />;
      } else {
        return <GeneralPanel {...props} />;
      }
    }
  };

  const { goalColor = '#2980b9' } = goal;

  return (
    <div className="Dashboard">
      <Axios api="goals" run={goalId} onSuccess={onSuccessGoal} params={goalId}>
        <Axios api="goals" run={goalId} onSuccess={onSuccessInfo} params={`${goalId}/info`}>
          <Header goal={goal} data={data} user={user} history={history} />
          <Banner goal={goal} goalColor={goalColor} />
          {getComponent({ user, goalId, goal, goalColor, history })}
        </Axios>
      </Axios>
    </div>
  );
};

export default Dashboard;
