import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Row } from 'antd';
import {
  getGoalInfo,
  createGoalInfo,
  updateGoalInfo,
  retrieveSignedAttachment,
} from 'services/api';

import { Form } from '@ant-design/compatible';

import Input from '@components/Input';
import FormSubmit from '@components/FormSubmit';

import { GuideUpload } from './GuideUpload';

const InfoTab = ({ goalId, bindFormProps }) => {
  const [t] = useTranslation();
  const [initialValues, setInitialValues] = useState({});
  const [guideAttachment, setGuideAttachment] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        const { id, guideAttachmentId, contactNumber, defaultMessage, legalTerms } =
          await getGoalInfo(goalId);
        setInitialValues({ id, guideAttachmentId, contactNumber, defaultMessage, legalTerms });
        if (guideAttachmentId) {
          setGuideAttachment(await retrieveSignedAttachment(guideAttachmentId));
        }
      } catch (error) {
        if (error.response.status === 404) {
          setInitialValues({});
        }
      }
    }
    if (goalId) {
      fetchData();
    }
  }, [goalId]);

  const onSubmit = async values => {
    const saveFn = initialValues.id ? updateGoalInfo : createGoalInfo;
    const { id, guideAttachmentId, contactNumber, defaultMessage, legalTerms } = await saveFn(
      goalId,
      values,
    );
    setInitialValues({ id, guideAttachmentId, contactNumber, defaultMessage, legalTerms });
    return true;
  };

  const renderForm = ({
    values,
    errors,
    handleSubmit,
    setFieldValue,
    submitForm,
    dirty,
    handleChange,
  }) => {
    bindFormProps({ dirty, submitForm });
    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <Row type="flex">
          <GuideUpload
            fieldName="guideAttachmentId"
            label={t('goalInfo.guideAttachment')}
            attachment={guideAttachment}
            setAttachment={setGuideAttachment}
            setFieldValue={setFieldValue}
            rowSpan={24}
          />
        </Row>
        <Row type="flex">
          <Input
            name="contactNumber"
            mask="(99) 9 9999-9999"
            onChange={handleChange}
            value={values.contactNumber}
            errors={errors.contactNumber}
            label="goalInfo.contactNumber"
          />
        </Row>
        <Row type="flex">
          <Input
            textArea
            rows={5}
            type="textarea"
            name="defaultMessage"
            onChange={handleChange}
            value={values.defaultMessage}
            errors={errors.defaultMessage}
            label="goalInfo.defaultMessage"
          />
        </Row>
        <Row type="flex">
          <Input
            textArea
            rows={5}
            type="textarea"
            name="legalTerms"
            onChange={handleChange}
            value={values.legalTerms}
            errors={errors.legalTerms}
            label="goalInfo.legalTerms"
          />
        </Row>
      </Form>
    );
  };

  return (
    <FormSubmit initialValues={initialValues} onSubmit={onSubmit}>
      {renderForm}
    </FormSubmit>
  );
};

export default InfoTab;
