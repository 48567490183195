import React from 'react';
import { Table } from 'antd';

import { sortText } from 'util/columnSorter';

import Input from '@components/Input';
import { i18n } from '@components/Translate';

import './styles.scss';

const ExpandedRow = ({ telesalesId, goalItems, getInputValue, onChangeValue }) => {
  const detailColumns = [
    {
      title: i18n.t('goalTelesales.itemName'),
      dataIndex: 'itemName',
      sorter: (a, b) => sortText(a, b, 'itemName'),
      defaultSortOrder: 'ascend',
    },
    {
      title: i18n.t('goalTelesales.done'),
      render: (_, { id, goalItemId, goalValue, actualValue }) => {
        const key = `${telesalesId}-${goalItemId}`;
        const object = { key, id, goalItemId, telesalesId, goalValue, actualValue };
        const value = getInputValue(`${key}.actualValue`, actualValue);
        return (
          <Input
            prefix="R$"
            type="number"
            value={value}
            object={object}
            name="actualValue"
            className="input-number"
            style={{ width: '100%' }}
            onChange={onChangeValue}
          />
        );
      },
    },
  ];

  return (
    <div className="ExpandedRow">
      <Table
        pagination={false}
        dataSource={goalItems}
        columns={detailColumns}
        rowKey={(_, index) => `${telesalesId}#${index}`}
      />
    </div>
  );
};

export default ExpandedRow;
