import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Modal, Button, AutoComplete, Tag, message } from 'antd';
import moment from 'moment';
import { getItemsName } from 'services/api';

import { Form } from '@ant-design/compatible';
import { EditOutlined } from '@ant-design/icons';

import { debounce } from '@util/debounce';
import CustomInput from '@components/CustomInput/customInput';
import FormSubmit from '@components/FormSubmit';
import { useMessageContext } from '@context/index';
import Axios from '@components/Axios';

import { createItemConfigValidation, createNewItemValidation } from './ProductFormValidation';

import '@ant-design/compatible/assets/index.css';
import './styles.scss';

export const AddProductModal = ({
  id,
  goalId,
  isVisible,
  addedProducts,
  startDate,
  endDate,
  onCancel,
  onAdd,
}) => {
  const [t] = useTranslation();
  const [productsName, setProductsName] = useState([]);
  const [selectedValue, setSelectedValue] = useState();
  const [initialValues, setInitialValues] = useState({ startDate, endDate });
  const { showMessage } = useMessageContext();
  let _submitForm;

  useEffect(() => {
    async function fetchProductsName() {
      const { items: itemsName } = await getItemsName();
      const productsName = itemsName
        .filter(({ id }) => !addedProducts?.find(added => added.id === id))
        .map(({ id, itemName }) => ({ label: itemName, value: id }));
      setProductsName(productsName);
    }
    if (isVisible) {
      fetchProductsName();
    }
  }, [isVisible]);

  const onGoalItemSearchSuccess = ({ id, itemName, goalItemConfig: { startDate, endDate } }) => {
    setInitialValues({ startDate, endDate });
    setSelectedValue({ label: itemName, value: id });
  };

  const setDateFieldValue = (value, setFieldValue, field) => {
    setFieldValue(field, value?.format('YYYY-MM-DD'));
  };

  const getDateFieldValue = (values, fieldName) => {
    const value = values[fieldName];
    const date = value ? moment(value).utcOffset(3) : undefined;
    return { [fieldName]: date };
  };

  const onTagClose = (field, setFieldValue) => {
    setFieldValue(field, selectedValue.label);
    setSelectedValue(null);
  };

  const InputAutoCompleteTag = ({ values, errors, field, label, setFieldValue }) => {
    const content = selectedValue ? (
      <Tag
        className="product-name"
        closeIcon={<EditOutlined />}
        closable={!id}
        onClose={() => onTagClose(field, setFieldValue)}
      >
        {selectedValue.label}
      </Tag>
    ) : (
      <AutoComplete
        //eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        value={values[field]}
        onChange={value => setFieldValue(field, value)}
        onSelect={(_, option) => setSelectedValue(option)}
        filterOption={(inputValue, option) =>
          option.label.toLowerCase().includes(inputValue.toString().toLowerCase())
        }
        options={productsName}
        onSearch={value => debounce(() => value)}
      />
    );
    return (
      <Form.Item
        label={t(label)}
        validateStatus={errors[field] ? 'error' : 'validating'}
        help={errors[field]}
      >
        {content}
      </Form.Item>
    );
  };

  const closeModal = () => {
    setSelectedValue();
    onCancel();
  };

  const getSubmitBody = (itemName, startDate, endDate) => {
    const body = {};
    if (axios.method === 'post') {
      body.goalItemConfig = { startDate, endDate };
      if (selectedValue) {
        body.goalItemId = selectedValue.value;
      } else {
        body.itemName = itemName;
      }
    } else {
      body.startDate = startDate;
      body.endDate = endDate;
    }
    return body;
  };

  const onSubmit = async ({ values: { itemName, startDate, endDate }, submit, setErrors }) => {
    try {
      const body = getSubmitBody(itemName, startDate, endDate);
      await submit({ body });
    } catch (error) {
      if (error?.unprocessableEntity) {
        setErrors(error.unprocessableEntity);
      } else if (error?.response?.data) {
        showMessage({
          type: 'error',
          message: error.response.data,
        });
      }
    }
  };

  const onSubmitSuccess = data => {
    const messageContent = `goalItems.add.${selectedValue ? 'addedMessage' : 'savedMessage'}`;
    message.info(t(messageContent));
    onAdd(data);
    closeModal();
  };

  const renderForm = ({ values, errors, handleSubmit, setFieldValue, submitForm }) => {
    _submitForm = submitForm;
    return (
      <Form layout="vertical" onSubmit={handleSubmit}>
        <InputAutoCompleteTag
          field="itemName"
          label="goalItems.add.name"
          values={values}
          errors={errors}
          setFieldValue={setFieldValue}
        />
        <CustomInput
          type="date"
          errors={errors}
          field="startDate"
          label="goalItems.add.startDate"
          value={getDateFieldValue(values, 'startDate')}
          setFieldValue={(field, value) => setDateFieldValue(value, setFieldValue, field)}
        />
        <CustomInput
          type="date"
          field="endDate"
          label="goalItems.add.endDate"
          value={getDateFieldValue(values, 'endDate')}
          errors={errors}
          setFieldValue={(field, value) => setDateFieldValue(value, setFieldValue, field)}
        />
      </Form>
    );
  };

  const Footer = () => {
    const buttonLabel = `goalItems.add.${selectedValue ? 'add' : 'saveAndAdd'}`;
    return (
      <Button type="primary" onClick={_submitForm}>
        {t(buttonLabel)}
      </Button>
    );
  };

  const validationParamsTransform = {
    startDate: params => ({
      ...params,
      min: new Date(params.min).toLocaleDateString(),
      max: new Date(params.max).toLocaleDateString(),
    }),
    endDate: params => ({
      ...params,
      min: new Date(params.min).toLocaleDateString(),
      max: new Date(params.max).toLocaleDateString(),
    }),
  };

  const axios = id
    ? { method: 'put', params: `${goalId}/items/${id}` }
    : { method: 'post', params: `${goalId}/items` };

  return (
    <Modal
      destroyOnClose
      title={t('goalItems.add.title')}
      visible={isVisible}
      onCancel={closeModal}
      footer={<Footer />}
    >
      <Axios
        run={id}
        api="goals"
        onSuccess={onGoalItemSearchSuccess}
        params={`${goalId}/items/${id}`}
      >
        <Axios {...axios} api="goals" onSuccess={onSubmitSuccess}>
          {({ submit }) => (
            <FormSubmit
              validationSchema={
                selectedValue
                  ? createItemConfigValidation(startDate, endDate)
                  : createNewItemValidation(startDate, endDate)
              }
              validationParamsTransform={validationParamsTransform}
              initialValues={initialValues}
              onSubmit={(values, event) => onSubmit({ ...event, values, submit })}
            >
              {renderForm}
            </FormSubmit>
          )}
        </Axios>
      </Axios>
    </Modal>
  );
};
