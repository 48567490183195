import { setLocale } from 'yup';

setLocale({
  mixed: {
    default: 'yup.mixed.default',
    required: 'yup.mixed.required',
    oneOf: 'yup.mixed.oneOf',
    notOneOf: 'yup.mixed.notOneOf',
    notType: 'yup.mixed.notType',
    defined: 'yup.mixed.defined',
  },
  string: {
    length: 'yup.string.length',
    min: 'yup.string.min',
    max: 'yup.string.max',
    matches: 'yup.string.matches',
    email: 'yup.string.email',
    url: 'yup.string.url',
    uuid: 'yup.string.uuid',
    trim: 'yup.string.trim',
    lowercase: 'yup.string.lowercase',
    uppercase: 'yup.string.uppercase',
  },
  number: {
    min: 'yup.number.min',
    max: 'yup.number.max',
    lessThan: 'yup.number.lessThan',
    moreThan: 'yup.number.moreThan',
    positive: 'yup.number.positive',
    negative: 'yup.number.negative',
    integer: 'yup.number.integer',
  },
  date: {
    min: 'yup.date.min',
    max: 'yup.date.max',
  },
  object: {
    noUnknown: 'yup.object.noUnknown',
  },
  array: {
    length: 'yup.array.length',
    min: 'yup.array.min',
    max: 'yup.array.max',
  },
  boolean: {
    isValue: 'yup.boolean.isValue',
  },
});
