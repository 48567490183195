import React from 'react';
import { Card } from 'antd';

import Translate from '@components/Translate';

import './styles.scss';

const BoxItem = ({ title, icon, children }) => {
  return (
    <Card className="BoxItem">
      <div className="header">
        <div className="header-icon">{icon && icon()}</div>
        <div className="header-title">
          <Translate>{title}</Translate>
        </div>
      </div>
      <div className="card-body">{children}</div>
      <hr />
    </Card>
  );
};

export default BoxItem;
