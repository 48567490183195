import React from 'react';
import { Row, Col } from 'antd';
import { Form } from '@ant-design/compatible';

import Axios from '@components/Axios';
import Input from '@components/Input';

import states from './states';

const FormComponent = ({ errors, values, handleSubmit, handleChange, setFieldValue }) => {
  const state = values.state;

  const onStateChange = ({ target: { name, value } }) => {
    setFieldValue(name, value);
    setFieldValue('cityStateId', undefined);
  };

  return (
    <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="name"
            error={errors.name}
            value={values.name}
            onChange={handleChange}
            label="dealers.create.form.label.name"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="documentNumber"
            onChange={handleChange}
            mask="99.999.999/9999-99"
            error={errors.documentNumber}
            value={values.documentNumber}
            label="dealers.create.form.label.cnpj"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="email"
            error={errors.email}
            value={values.email}
            onChange={handleChange}
            label="dealers.create.form.label.email"
          />
        </Col>
        <Col xs={24} md={6}>
          <Input
            name="phone"
            mask="(99) 9 9999-9999"
            onChange={handleChange}
            error={errors.phone}
            value={values.phone}
            label="dealers.create.form.label.phone"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Input
            name="state"
            type="select"
            value={state}
            array={states}
            error={errors.state}
            onChange={onStateChange}
            label="dealers.create.form.label.state"
            option={{
              key: 'key',
              name: 'name',
            }}
          />
        </Col>
        <Col xs={24} md={6}>
          <Axios
            api="cities"
            run={state}
            params={{ stateAbbreviation: state, ignorePagination: true }}
          >
            {({ loading, response: { items = [] } }) => (
              <Input
                type="select"
                array={items}
                loading={loading}
                name="cityStateId"
                disabled={!state}
                onChange={handleChange}
                error={errors.cityStateId}
                value={values.cityStateId}
                label="dealers.create.form.label.city"
                option={{
                  key: 'id',
                  name: 'cityName',
                }}
              />
            )}
          </Axios>
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={6}>
          <Axios run api="members" params={{ ignorePagination: true, memberType: 'TSR' }}>
            {({ loading, response }) => (
              <Input
                type="select"
                array={response}
                loading={loading}
                name="parentMemberId"
                onChange={handleChange}
                error={errors.parentMemberId}
                value={values.parentMemberId}
                label="dealers.create.form.label.rtv"
                option={{
                  key: 'id',
                  name: 'memberName',
                }}
              />
            )}
          </Axios>
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="switch"
            name="active"
            error={errors.active}
            value={values.active}
            onChange={handleChange}
            label="dealers.create.form.label.status"
            checkedChildren="dealers.create.form.label.status.checkedChildren"
            unCheckedChildren="dealers.create.form.label.status.unCheckedChildren"
          />
        </Col>
      </Row>
      <Row gutter={20} type="flex">
        <Col xs={24} md={12}>
          <Input
            type="switch"
            name="canAccessPortal"
            onChange={handleChange}
            error={errors.canAccessPortal}
            value={values.canAccessPortal}
            label="dealers.create.form.label.portal"
            checkedChildren="dealers.create.form.label.portal.checkedChildren"
            unCheckedChildren="dealers.create.form.label.portal.unCheckedChildren"
          />
        </Col>
      </Row>
    </Form>
  );
};

export default FormComponent;
