import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Button, Row, Col } from 'antd';
import { cnpj } from 'cpf-cnpj-validator';

import * as yup from 'yup';

import Axios from '@components/Axios';
import Translate from '@components/Translate';
import FormSubmit from '@components/FormSubmit';
import { useMessageContext } from '@context/index';

import FormInfo from './components/Form';

import './styles.scss';

const CreateDealer = ({ match, history }) => {
  const { showMessage } = useMessageContext();
  const { id } = useParams();
  const [dealer, setDealer] = useState({});
  const [axios, setAxios] = useState({ method: 'post' });

  const api = 'dealers';

  const getString = value => {
    if (value) {
      return String(value);
    }
  };

  const getDealer = data => {
    const { cityStateId, parentMemberId, cityState, portalAccessControl } = data;
    data.state = cityState?.stateAbbreviation;
    data.cityStateId = getString(cityStateId);
    data.parentMemberId = getString(parentMemberId);
    data.canAccessPortal = portalAccessControl?.active;
    delete data.portalAccessControl;
    setDealer(data);
    setAxios({ method: 'put', params: data.id });
  };

  const onSuccess = () => {
    history.push('/dealers/list');
  };

  const submitForm = async ({ values, submit, setErrors }) => {
    try {
      await submit({ body: values });
    } catch (error) {
      if (error?.unprocessableEntity) {
        setErrors(error.unprocessableEntity);
      } else if (error?.response?.data) {
        showMessage({
          type: 'error',
          message: error.response.data,
        });
      }
    }
  };

  const formikProps = {
    initialValues: { active: true, ...dealer },
    validationSchema: yup.object().shape({
      state: yup.string().nullable(),
      cityStateId: yup.number().nullable(),
      parentMemberId: yup.number().nullable(),
      name: yup.string().max(150).required(),
      email: yup.string().email().max(150).required(),
      phone: yup.string().nullable().max(20),
      documentNumber: yup
        .string()
        .max(20)
        .required()
        .test({
          name: 'testDocument',
          message: 'validation.invalid.document',
          params: {
            document: 'CNPJ',
          },
          test: value => !value || cnpj.isValid(value, true),
        }),
    }),
  };

  const action = ({ handleSubmit }) => {
    return (
      <Row justify="end">
        <Col className="create-actions">
          <Button onClick={onSuccess}>
            <Translate>dealers.create.header.exit</Translate>
          </Button>
          <Button type="primary" onClick={handleSubmit}>
            <Translate>dealers.create.header.register</Translate>
          </Button>
        </Col>
      </Row>
    );
  };

  const getComponentForm = ({ submit }) => {
    return (
      <FormSubmit
        {...formikProps}
        onSubmit={(values, event) => submitForm({ ...event, values, submit })}
      >
        {({ handleSubmit, isValid, ...formikValues }) => (
          <>
            {action({ handleSubmit, isValid })}
            <Card>
              <FormInfo {...formikValues} />
            </Card>
          </>
        )}
      </FormSubmit>
    );
  };

  return (
    <div className="CreateDealer">
      <Axios
        run={id}
        api={api}
        onSuccess={getDealer}
        params={[id, { include: 'cityState,portalAccessControl' }]}
      >
        <Axios {...axios} api={api} params={id} onSuccess={onSuccess}>
          {getComponentForm}
        </Axios>
      </Axios>
    </div>
  );
};

export default CreateDealer;
